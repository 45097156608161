import { useEffect } from 'react';
import { getSettings, selectSettings, selectSettingsError, selectSettingsIsLoading } from '../feature';
import { useAppDispatch, useAppState } from '@/store';

export const useGetSettings = (channel?: string) => {
  const settings = useAppState(selectSettings);
  const isLoading = useAppState(selectSettingsIsLoading);
  const error = useAppState(selectSettingsError);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!channel || !!settings || isLoading || !!error) return;
    dispatch(getSettings({ channel }));
  }, [settings, channel, isLoading, error, dispatch]);

  return {
    settings,
    isLoading,
    error,
  };
};
