import { IApiError } from '@/models/apiError.model';
import {
  ICreateRedirectDTO,
  ICreateRedirectResponse,
  IDeleteRedirectDTO,
  IDeleteRedirectResponse,
  IGetRedirectResponse,
  IGetRedirectsDTO,
  IRedirectsImportDTO,
  IRedirectsImportResponse,
  IUpdateRedirectDTO,
  IUpdateRedirectResponse,
} from '@/models/redirect.model';
import { formatApiError } from '@/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import RedirectsAPI from 'src/api/RedirectsAPI';
import { SLICE_NAME } from './models';

export const getRedirects = createAsyncThunk<
  IGetRedirectResponse,
  IGetRedirectsDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/getRedirects`,
  async (params) => {
    const response = await RedirectsAPI.getRedirects(params);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateRedirect = createAsyncThunk<
  IUpdateRedirectResponse,
  IUpdateRedirectDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/updateRedirect`,
  async (data) => {
    const response = await RedirectsAPI.updateRedirect(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const deleteRedirect = createAsyncThunk<
  IDeleteRedirectResponse,
  IDeleteRedirectDTO,
  { serializedErrorType: IApiError; fulfilledMeta: { id: string } }
>(
  `${SLICE_NAME}/deleteMetatags`,
  async (data, thunkAPI) => {
    const response = await RedirectsAPI.deleteRedirect(data);
    return thunkAPI.fulfillWithValue(response.data, { id: data.id });
  },
  { serializeError: formatApiError },
);

export const createRedirect = createAsyncThunk<
  ICreateRedirectResponse,
  ICreateRedirectDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/createRedirect`,
  async (data) => {
    const response = await RedirectsAPI.createRedirect(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const importRedirects = createAsyncThunk<
  IRedirectsImportResponse,
  IRedirectsImportDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/importRedirects`,
  async (data) => {
    const response = await RedirectsAPI.importRedirects(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
