export enum PageType {
  HOME = 'home',
  ABOUT_US = 'about-us',
  PRICE_PROTECTION = 'price-protection',
  SHIPPING_AND_RETURNS = 'shipping-and-returns',
  AFFILIATES = 'affiliates',
  INVESTOR_INFO = 'investor-info',
  CAREERS = 'careers',
  GUARANTEED_PRIVACY_AND_SECURITY = 'guaranteed-privacy-security',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  PROP65 = 'prop-65',
  PAGE_404 = '404',
}

export enum PageStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum ContentBlockType {
  DEFAULT = 'default',
  LINK = 'link',
  ADVANTAGE = 'advantage',
}

export type PageSEO = {
  title: string;
  description: string;
  keywords: string[];
};

export type PageContent = {
  title?: string;
  text?: string;
  blocks?: ContentBlock[];
  gallery?: GalleryBlock[];
  banner?: PageBanner;
  image?: string;
  imageName?: string;
};

export type PageBanner = {
  id?: string;
  title?: string;
  description?: string;
  blocks?: ContentBlock[];
};

// ContentBlock
export type ContentBlock = {
  id?: string;
  order?: number;
  type?: ContentBlockType;
  link?: string;
  title?: string;
  description?: string;
};

export type GalleryBlock = {
  id?: string;
  order?: number;
  description?: string;
};
