import { IGetReviewsResponse, IReview, ReviewStatus } from '@/models/review.model';
import { showApiErrors } from '@/utils';
import { PayloadAction, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { approveReview, createReview, deleteReview, getReviews, updateReview } from './actionCreators';
import { ReviewsState, SLICE_NAME, initialState } from './models';

export const reviewsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setStatusFilter(state, action: PayloadAction<ReviewStatus>) {
      state.statusFilter = action.payload;
      state.page = 1;
    },
    setSelectedReviewId(state, action: PayloadAction<string | null>) {
      state.selectedReviewId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReviews.fulfilled, (state: ReviewsState, action: PayloadAction<IGetReviewsResponse>) => {
        const { payload } = action;
        state.isLoading = false;
        state.reviews = payload.reviews;
        state.total = payload.total;
        state.page = payload.page;
      })
      .addCase(approveReview.fulfilled, (state: ReviewsState, action: PayloadAction<IReview>) => {
        const { payload } = action;
        state.isLoading = false;
        state.reviews = state.reviews.map((review) =>
          review.id === payload.id
            ? {
                ...review,
                status: payload.status,
              }
            : review,
        );
      })
      .addCase(deleteReview.fulfilled, (state: ReviewsState, action: PayloadAction<string>) => {
        const { payload: reviewId } = action;
        state.isLoading = false;
        state.reviews = state.reviews.filter((review) => review.id !== reviewId);
      })
      .addCase(updateReview.fulfilled, (state: ReviewsState, action: PayloadAction<IReview>) => {
        const { payload } = action;
        state.isLoading = false;
        state.reviews = state.reviews.map((review) =>
          review.id === payload.id
            ? {
                ...review,
                ...payload,
              }
            : review,
        );
      })
      .addCase(createReview.fulfilled, (state: ReviewsState, action: PayloadAction<IReview>) => {
        const { payload } = action;
        state.isLoading = false;
        state.reviews = [payload, ...state.reviews];
      })
      .addMatcher(
        isPending(createReview, updateReview, deleteReview, approveReview, getReviews),
        (state: ReviewsState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(updateReview, deleteReview, approveReview, createReview, getReviews),
        (state: ReviewsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          showApiErrors(error);
        },
      );
  },
});

export const { setPage, setStatusFilter, setSelectedReviewId } = reviewsSlice.actions;
