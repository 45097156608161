import { IUpdateMetatagsDTO } from '@/models/metatags.model';
import { MetatagsFormFields, TUpdateMetatagsTemplateFormValues } from '../helpers';

class UpdateMetatagDTO implements IUpdateMetatagsDTO {
  title: string;

  description: string;

  keywords?: string[];

  id: string;

  constructor(values: TUpdateMetatagsTemplateFormValues & { id: string }) {
    this.title = values[MetatagsFormFields.TITLE];
    this.description = values[MetatagsFormFields.DESCRIPTION];
    this.keywords = values[MetatagsFormFields.KEYWORDS];
    this.id = values.id;
  }
}

export default UpdateMetatagDTO;
