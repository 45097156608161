// Globals
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Store
import { useAppDispatch, useAppState } from '@/store';
import { createChannel, selectChannels, selectChannelsIsLoading, updateChannel } from '../../feature';

// Models
import { ChannelStatus, IChannel } from '@/models/channel.model';

// Modules
import { Header } from '@/modules/Layout';

// Components
import { Modal, ModalSize } from '@/components';
import { NotFoundIcon } from '@/icons';
import { Button, Table } from '@/ui';
import { ChannelForm } from '../../components';
import { StyledButtons, StyledContainer, StyledMessage } from './ChannelsContainerStyles';

// Helpers
import { channelColumns, CreateChannelFormFields, ModalViews, TCreateChannelFormValues } from '../../helpers';
import { ChannelTypeFilterValues } from '../../helpers/types';

const ChannelsContainer = () => {
  const { t } = useTranslation();
  const channels = useAppState(selectChannels);
  const isLoading = useAppState(selectChannelsIsLoading);
  const dispatch = useAppDispatch();

  const [query, setSearchText] = useState('');
  const [selectedChannel, setSelectedChannel] = useState<IChannel | null>(null);
  const [modalView, setModalView] = useState<ModalViews | null>(null);
  const [typeFilter, setTypeFilter] = useState<string>(ChannelTypeFilterValues[0].value);

  const dataSource = useMemo(
    () =>
      (channels ?? []).filter((channel) => {
        const includesQuery = channel.name?.toLowerCase().includes(query.toLowerCase());
        if (!typeFilter) return includesQuery;
        return includesQuery && channel.type === typeFilter;
      }),
    [channels, query, typeFilter],
  );

  const handleTypeFilterChange = (value: string) => {
    setTypeFilter(value);
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => setSearchText(event.target.value ?? '');

  const handleCloseModal = () => {
    setSelectedChannel(null);
    setModalView(null);
  };
  const handleConfirmChanges = useCallback(async () => {
    if (!selectedChannel) return;
    const newStatus = selectedChannel.status === ChannelStatus.ACTIVE ? ChannelStatus.INACTIVE : ChannelStatus.ACTIVE;
    await dispatch(
      updateChannel({
        id: selectedChannel.id,
        status: newStatus,
      }),
    );
    setSelectedChannel(null);
    setModalView(null);
  }, [dispatch, selectedChannel]);

  const handleUpdateStatus = (record: IChannel) => {
    setSelectedChannel(record);
    setModalView(record.status === ChannelStatus.ACTIVE ? ModalViews.DEACTIVATE : ModalViews.ACTIVATE);
  };

  const handleSubmitEdit = useCallback(
    async (values: TCreateChannelFormValues) => {
      if (!selectedChannel) return;
      await dispatch(
        updateChannel({
          id: selectedChannel.id,
          ...values,
        }),
      );
      setSelectedChannel(null);
      setModalView(null);
    },
    [dispatch, selectedChannel],
  );

  const handleCreateChannel = useCallback(
    async (values: TCreateChannelFormValues) => {
      await dispatch(createChannel(values));
      setSelectedChannel(null);
      setModalView(null);
    },
    [dispatch],
  );

  const handleEdit = (record: IChannel) => {
    setSelectedChannel(record);
    setModalView(ModalViews.EDIT);
  };

  const handleCreate = () => {
    setSelectedChannel(null);
    setModalView(ModalViews.CREATE);
  };

  const getModalContent = useCallback(
    (view: ModalViews) => {
      switch (view) {
        case ModalViews.EDIT:
          return (
            <ChannelForm
              onSubmit={handleSubmitEdit}
              initialValues={{
                [CreateChannelFormFields.SITE_URL]: selectedChannel?.siteUrl,
              }}
            >
              <StyledButtons>
                <Button htmlType="submit" type="primary" block size="small">
                  {t(`update_btn`)}
                </Button>
                <Button size="small" block onClick={handleCloseModal}>
                  {t(`cancel_btn`)}
                </Button>
              </StyledButtons>
            </ChannelForm>
          );
        case ModalViews.DEACTIVATE:
          return (
            <div>
              <StyledMessage>{t(`active_confirm`)}</StyledMessage>
              <StyledButtons>
                <Button type="primary" block size="small" onClick={handleConfirmChanges}>
                  {t(`deactivate`)}
                </Button>
                <Button size="small" block onClick={handleCloseModal}>
                  {t(`cancel_btn`)}
                </Button>
              </StyledButtons>
            </div>
          );
        case ModalViews.ACTIVATE:
          return (
            <div>
              <StyledMessage>{t(`inactive_confirm`)}</StyledMessage>
              <StyledButtons>
                <Button size="small" block type="primary" onClick={handleConfirmChanges}>
                  {t(`activate`)}
                </Button>
                <Button size="small" block onClick={handleCloseModal}>
                  {t(`cancel_btn`)}
                </Button>
              </StyledButtons>
            </div>
          );
        case ModalViews.CREATE:
          return (
            <ChannelForm onSubmit={handleCreateChannel} channels={channels} isCreating>
              <StyledButtons>
                <Button htmlType="submit" type="primary" block size="small">
                  {t(`create_btn`)}
                </Button>
                <Button size="small" block onClick={handleCloseModal}>
                  {t(`cancel_btn`)}
                </Button>
              </StyledButtons>
            </ChannelForm>
          );
        default:
          return null;
      }
    },
    [channels, handleConfirmChanges, handleCreateChannel, handleSubmitEdit, selectedChannel?.siteUrl, t],
  );

  return (
    <>
      <Header title={t('menu_channels')}>
        <Button icon={<NotFoundIcon />} type="primary" onClick={handleCreate}>
          {t('create_channel')}
        </Button>
      </Header>
      <StyledContainer>
        <Table
          columns={channelColumns(
            t,
            query,
            typeFilter,
            handleSearch,
            handleUpdateStatus,
            handleEdit,
            handleTypeFilterChange,
          )}
          rowKey={'id'}
          dataSource={dataSource}
          loading={isLoading}
          pagination={false}
          size="middle"
          virtual
          rowHoverable
        />
      </StyledContainer>
      <Modal
        title={t('confirm')}
        size={ModalSize.LARGE}
        visible={!!modalView}
        onCancel={handleCloseModal}
        destroyOnClose
      >
        {!!modalView && getModalContent(modalView)}
      </Modal>
    </>
  );
};

export default ChannelsContainer;
