// Global
import { Button, Form } from 'antd';
import { FormListProps } from 'antd/es/form';
import { FC } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

// Store
import { ContentBlockType } from '@/modules/Pages/typedefs';
import { reorderList } from '../../../utils/reorderList';

// Hooks
import { useFormInstance } from '@/components';

// Components
import { PlusIcon } from '@/icons';
import { DraggableItem } from 'src/shared/ui/DraggableItem';
import { StrictModeDroppable } from 'src/shared/ui/DraggableItem/StrictModeDroppable';
import { VariableType } from 'src/shared/ui/VariablesDetector/VariableDetector.typedefs';
import { UpdateContentAdvantageBlock } from '../../formBlocks/UpdateContentAdvantageBlock';

interface UpdateAdvantageBlocksFormListProps {
  fieldName: FormListProps['name'];
  registerInputRef: (fieldName: string, ref: any, type: VariableType) => void;
  handleInputChange: (fieldName: string, value: string) => void;
}

export const UpdateAdvantageBlocksFormList: FC<UpdateAdvantageBlocksFormListProps> = ({
  fieldName,
  handleInputChange,
  registerInputRef,
}) => {
  const form = useFormInstance();

  const onDragEnd = (result: any) => {
    const galleryItems = form.getFieldValue(fieldName);
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const reorderedItems = reorderList(galleryItems, source.index, destination.index);
    form.setFieldValue(fieldName, reorderedItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId="droppable">
        {(provided) => (
          <Form.List name={fieldName}>
            {(fields, { add, remove }) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {fields.map((field, index) => (
                  <DraggableItem key={field.key} fieldKey={field.key.toString()} removeItem={remove} index={index}>
                    <UpdateContentAdvantageBlock
                      fieldName={field.name}
                      handleInputChange={handleInputChange}
                      registerInputRef={registerInputRef}
                    />
                  </DraggableItem>
                ))}

                {provided.placeholder}

                {fields.length < 6 && (
                  <Button
                    onClick={() => add({ order: fields.length + 1, id: uuidv4(), type: ContentBlockType.ADVANTAGE })}
                    style={{ width: '100%', marginBottom: '8px' }}
                    icon={<PlusIcon />}
                  >
                    Add advantage block
                  </Button>
                )}
              </div>
            )}
          </Form.List>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};
