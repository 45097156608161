import { useAppDispatch, useAppState } from '@/store';
import { useEffect } from 'react';

import { selectChannel } from '@/modules/Channels';
import { GetVariablesDTO } from '../dtos/GetVariables.dto';
import {
  getVariables,
  selectVariables,
  selectVariablesError,
  selectVariablesIsLoading,
  selectVariablesPagination,
} from '../feature';
import { useSearchParams } from 'react-router-dom';

export const useVariables = () => {
  const currentChannel = useAppState(selectChannel);
  const isLoading = useAppState(selectVariablesIsLoading);
  const variables = useAppState(selectVariables);
  const pagination = useAppState(selectVariablesPagination);
  const error = useAppState(selectVariablesError);
  const [searchQuery] = useSearchParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoading || error || !currentChannel?.id || variables) return;
    const dto = new GetVariablesDTO({
      page: searchQuery.get('page') || 1,
      channelId: currentChannel.id,
      query: searchQuery.get('search') || undefined,
    });
    dispatch(getVariables(dto)).unwrap();
  }, [dispatch, error, isLoading, variables, searchQuery, currentChannel?.id]);

  return {
    isLoading,
    variables,
    error,
    pagination,
    currentChannel,
  };
};
