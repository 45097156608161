import { COLORS, DEVICES } from '@/theme';
import { Pagination } from 'antd';
import styled, { css } from 'styled-components';

export const StyledPagination = styled(Pagination)`
  &.ant-pagination {
    .ant-pagination-prev,
    .ant-pagination-next,
    .ant-pagination-jump-next,
    .ant-pagination-jump-prev {
      border: none;
      background-color: transparent;
      color: transparent;
      padding: 0;
      margin: 0;
      border: 0;
      border-radius: 0;
      min-width: auto;
      height: auto;
      line-height: 1;
    }

    .ant-pagination-item {
      display: inline-flex;
      border-radius: 0;
      align-items: center;
      justify-content: center;
      background-color: ${COLORS.WHITE};
      border: 1px solid ${COLORS.COLOR_BORDER_SECONDARY} !important;
      min-width: 2.3rem;
      margin-inline-end: 0;
      height: 3.5rem;

      @media screen and (${DEVICES.TABLET}) {
        height: 4.625rem;
        padding: 1.25rem;
      }
    }

    .ant-pagination-item-active {
      background-color: ${({ theme }) => theme.token.colorPrimary} !important;
    }

    & .ant-pagination-jump-next,
    & .ant-pagination-jump-prev {
      height: 3.5rem;
      background-color: ${COLORS.WHITE};
      min-width: 2.2rem;
      border-block: 1px solid ${COLORS.COLOR_BORDER_SECONDARY};
      @media screen and (${DEVICES.TABLET}) {
        height: 4.625rem;
        padding: 1.25rem;
      }
    }
    & .ant-pagination-item-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .ant-pagination-item-ellipsis {
        font-size: 0.6rem;
        top: 25%;
        width: 100%;
        bottom: auto;
        bottom: auto;
        text-indent: 1em;
        transform: translate(-15%, 100%);

        @media screen and (${DEVICES.TABLET}) {
          text-indent: 0;
          top: 35%;
          transform: none;
        }
      }
    }

    & .ant-pagination-item-active {
      background-color: ${({ theme }) => theme.token?.colorPrimary} !important;
      color: ${({ theme }) => theme.token?.colorPrimaryText};
      border: none;

      a {
        color: inherit !important;
      }

      &:hover {
        border-color: ${COLORS.COLOR_BORDER_SECONDARY};
      }
    }
  }

  & .ant-pagination-item a {
    padding: 0;
  }
`;

export const StyledItem = styled.button<{
  $isPrev?: boolean;
}>`
  display: inline-flex;
  align-items: center;
  padding: 0.5rem;
  min-width: 2.5rem;
  height: 3.5rem;
  color: ${({ theme }) => theme.token?.colorPrimary} !important;
  border: 1px solid ${COLORS.COLOR_BORDER_SECONDARY};
  background-color: ${COLORS.WHITE};
  ${({ $isPrev }) =>
    $isPrev
      ? css`
          border-right: 0;
          border-radius: 0.5rem 0 0 0.5rem;
        `
      : css`
          border-left: 0;
          border-radius: 0 0.5rem 0.5rem 0;
        `}

  @media screen and (${DEVICES.TABLET}) {
    height: 4.625rem;
    padding: 1.25rem;
  }
`;
