import { InputNumberProps } from 'antd';
import { StyledInputNumber } from './InputStyles';

export type IInputNumberProps = InputNumberProps;

const InputNumber = ({ prefix, value, min = 0, ...props }: IInputNumberProps) => {
  return <StyledInputNumber {...props} inputMode="numeric" min={min} prefix={prefix} value={value} />;
};

export default InputNumber;
