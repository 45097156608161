// Globals
import { useTranslation } from 'react-i18next';

// Components
import { Form, FormItem } from '@/components';
import { CheckIcon } from '@/icons';
import { Button, Input, Select } from '@/ui';
import { StyledButtons } from './RedirectsForm.styles';

// Helpers

import {
  RedirectFormType,
  RedirectsFormFields,
  TCreateRedirectFormValues,
  TUpdateRedirectFormValues,
} from '../../helpers/types';
import useRedirectFormRules from '../../hooks/useRedirectFormRules';
import { RedirectType } from '../../typedefs';

type TRedirectsFormFormProps = {
  onSubmit: (values: TCreateRedirectFormValues) => void;
  initialValues?: Partial<TUpdateRedirectFormValues>;
  formType: RedirectFormType;
  isLoading?: boolean;
};

const RedirectsForm = ({ onSubmit, initialValues, isLoading, formType }: TRedirectsFormFormProps) => {
  const rules = useRedirectFormRules();
  const { t } = useTranslation(['common']);

  const codeOptions = [
    { value: RedirectType.Permanent, label: String(RedirectType.Permanent) },
    { value: RedirectType.Temporary, label: String(RedirectType.Temporary) },
  ];

  return (
    <Form onFinish={onSubmit} initialValues={initialValues}>
      <FormItem name={RedirectsFormFields.CODE} label={t('label_code')} rules={rules[RedirectsFormFields.CODE]}>
        <Select options={codeOptions} placeholder={t('placeholder_redirect_code')} />
      </FormItem>
      <FormItem name={RedirectsFormFields.SOURCE} label={t('label_source')} rules={rules[RedirectsFormFields.SOURCE]}>
        <Input addonBefore={<strong>/</strong>} placeholder={t('placeholder_source')} />
      </FormItem>
      <FormItem name={RedirectsFormFields.TARGET} label={t('label_target')} rules={rules[RedirectsFormFields.TARGET]}>
        <Input addonBefore={<strong>/</strong>} placeholder={t('placeholder_target')} />
      </FormItem>
      <StyledButtons>
        <Button
          type="primary"
          htmlType="submit"
          icon={formType === RedirectFormType.UPDATE && <CheckIcon />}
          loading={isLoading}
        >
          {t(formType === RedirectFormType.CREATE ? 'btn_create_redirect' : 'btn_save')}
        </Button>
      </StyledButtons>
    </Form>
  );
};

export default RedirectsForm;
