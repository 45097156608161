import { ICreateReviewDTO, ReviewStatus } from '@/models/review.model';
import { Dayjs } from 'dayjs';
import { CreateReviewFormFields, TCreateReviewFormValues } from '../typedefs';

export class CreateReviewDTO implements ICreateReviewDTO {
  reviewText: string;

  createdAt: Dayjs;

  userName: string;

  durabilityRating: number;

  easeOfUseRating: number;

  valueForMoneyRating: number;

  productId: string;

  status: ReviewStatus;

  channelId: number;

  constructor(values: TCreateReviewFormValues) {
    this.reviewText = values[CreateReviewFormFields.REVIEW_TEXT];
    this.createdAt = values[CreateReviewFormFields.CREATED_AT];
    this.userName = values[CreateReviewFormFields.USER_NAME];
    this.durabilityRating = values[CreateReviewFormFields.DURABILITY_RATING];
    this.easeOfUseRating = values[CreateReviewFormFields.EASE_OF_USE_RATING];
    this.valueForMoneyRating = values[CreateReviewFormFields.VALUE_FOR_MONEY_RATING];
    this.productId = values[CreateReviewFormFields.PRODUCT_ID];
    this.status = values[CreateReviewFormFields.STATUS];
    this.channelId = values[CreateReviewFormFields.CHANNEL_ID];
  }
}
