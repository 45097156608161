// Global
import { FC } from 'react';

// Styles
import { WideFormItem } from '../../../UpdatePage.styles';

// Hooks
import { useTranslation } from 'react-i18next';
import { usePageValidations } from '../../../hooks/usePageValidations';

// Components
import { ImageUpload } from '@/modules/Settings/components';
import { Input, TextArea } from '@/ui';
import { VariableType } from 'src/shared/ui/VariablesDetector/VariableDetector.typedefs';

interface UpdateGalleryFieldsBlockProps {
  fieldName: string | number;
  registerInputRef: (fieldName: string, ref: any, type: VariableType) => void;
  handleInputChange: (fieldName: string, value: string) => void;
}

export const UpdateGalleryFieldsBlock: FC<UpdateGalleryFieldsBlockProps> = ({
  fieldName,
  handleInputChange,
  registerInputRef,
}) => {
  const { getRulesFor, getAcceptTypesFor } = usePageValidations();
  const { t } = useTranslation(['common']);

  return (
    <>
      <WideFormItem
        label={t('update_page_form_content_image')}
        name={[fieldName, 'image']}
        rules={getRulesFor(['content', 'gallery', 'image'])}
      >
        <ImageUpload accept={getAcceptTypesFor(['content', 'gallery', 'image'])} />
      </WideFormItem>

      <WideFormItem
        label={t('update_page_form_content_image_name')}
        name={[fieldName, 'imageName']}
        rules={getRulesFor(['content', 'gallery', 'imageName'])}
      >
        <Input
          ref={(ref) =>
            registerInputRef(`${t('update_page_form_content_image_name')} [${+fieldName + 1}]`, ref, VariableType.INPUT)
          }
          onChange={(e) =>
            handleInputChange(`${t('update_page_form_content_image_name')} [${+fieldName + 1}]`, e.target.value)
          }
          placeholder={t('update_page_form_content_image_name_placeholder')}
        />
      </WideFormItem>

      <WideFormItem
        label={t('update_page_form_content_description')}
        name={[fieldName, 'description']}
        rules={getRulesFor(['content', 'gallery', 'description'])}
      >
        <TextArea
          ref={(ref) =>
            registerInputRef(
              `${t('update_page_form_content_description')} [${+fieldName + 1}]`,
              ref,
              VariableType.TEXT_AREA,
            )
          }
          onChange={(e) =>
            handleInputChange(`${t('update_page_form_content_description')} [${+fieldName + 1}]`, e.target.value)
          }
          placeholder={t('update_page_form_content_description_placeholder')}
          rows={3}
        />
      </WideFormItem>
    </>
  );
};
