import { Dayjs } from 'dayjs';
import { IChannel } from './channel.model';

export enum ReviewsEndpoints {
  ROOT = '/reviews',
}

export interface IReview {
  reviewText: string;
  userName: string;
  durabilityRating: number;
  easeOfUseRating: number;
  valueForMoneyRating: number;
  overallRating: number;
  createdAt: string;
  id: string;
  productId: string;
  status: ReviewStatus;
  updatedAt: string;
  channel: IChannel;
}

export enum ReviewStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  ALL = 'ALL',
}

// ============== DTO ==============

export type IGetReviewsDTO = {
  status?: ReviewStatus;
  search?: string;
  showDeleted?: boolean;
  page: number;
  pageSize: number;
};

export type IUpdateReviewDTO = {
  reviewId: string;
  reviewText: string;
  createdAt: Dayjs;
  userName?: string;
  durabilityRating: number;
  easeOfUseRating: number;
  valueForMoneyRating: number;
};

export type ICreateReviewDTO = {
  productId: string;
  reviewText: string;
  createdAt: Dayjs;
  userName: string;
  durabilityRating: number;
  easeOfUseRating: number;
  valueForMoneyRating: number;
  status: ReviewStatus;
  channelId: number;
};

// ============== Response ==============

export type IGetReviewsResponse = {
  reviews: IReview[];
  total: number;
  page: number;
  pageSize: number;
};
