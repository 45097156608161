// Global
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

// Components
import { Button, Form } from 'antd';
import { DragDropContext } from 'react-beautiful-dnd';

// Hooks
import { useFormInstance } from '@/components';

// Store
import { ContentBlockType } from '@/modules/Pages/typedefs';

// Types
import { FormListProps } from 'antd/es/form';

// Styles
import { PlusIcon } from '@/icons';
import { DraggableItem } from 'src/shared/ui/DraggableItem';
import { StrictModeDroppable } from 'src/shared/ui/DraggableItem/StrictModeDroppable';

// Local
import { VariableType } from 'src/shared/ui/VariablesDetector/VariableDetector.typedefs';
import { reorderList } from '../../../utils/reorderList';
import { UpdateContentLinkBlock } from '../../formBlocks/UpdateContentLinkBlock';

interface UpdateLinkBlocksFormListProps {
  fieldName: FormListProps['name'];
  registerInputRef: (fieldName: string, ref: any, type: VariableType) => void;
  handleInputChange: (fieldName: string, value: string) => void;
}

export const UpdateLinkBlocksFormList: FC<UpdateLinkBlocksFormListProps> = ({
  fieldName,
  registerInputRef,
  handleInputChange,
}) => {
  const form = useFormInstance();

  const onDragEnd = (result: any) => {
    const galleryItems = form.getFieldValue(fieldName);
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const reorderedItems = reorderList(galleryItems, source.index, destination.index);
    form.setFieldValue(fieldName, reorderedItems);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId="droppable">
        {(provided) => (
          <Form.List name={fieldName}>
            {(fields, { add, remove }) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {fields.map((field, index) => (
                  <DraggableItem key={field.key} fieldKey={field.key.toString()} removeItem={remove} index={index}>
                    <UpdateContentLinkBlock
                      fieldName={field.name}
                      registerInputRef={registerInputRef}
                      handleInputChange={handleInputChange}
                    />
                  </DraggableItem>
                ))}

                {provided.placeholder}

                <Button
                  onClick={() => add({ order: fields.length + 1, id: uuidv4(), type: ContentBlockType.LINK })}
                  style={{ width: '100%', marginBottom: '8px' }}
                  icon={<PlusIcon />}
                >
                  Add link block
                </Button>
              </div>
            )}
          </Form.List>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};
