import { IApiError } from '@/models/apiError.model';
import {
  IBasePage,
  IGetPageDTO,
  IGetPageResponse,
  IGetPagesDTO,
  IGetPagesResponse,
  IUpdatePageDto,
  IUpdatePageResponse,
} from '@/models/page.model';
import { formatApiError } from '@/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import PagesAPI from 'src/api/PagesAPI';
import { PageStatus } from '../typedefs';
import { SLICE_NAME } from './models';

export const getPages = createAsyncThunk<IGetPagesResponse, IGetPagesDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/getPages`,
  async (data) => {
    const response = await PagesAPI.getPages(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getPage = createAsyncThunk<IGetPageResponse, IGetPageDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/getPage`,
  async (data) => {
    const response = await PagesAPI.getPage(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updatePageStatus = createAsyncThunk<
  IBasePage,
  { pageId: string; status: PageStatus },
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/updateStatus`,
  async ({ pageId, status }) => {
    const response = await PagesAPI.updatePageStatus(pageId, status);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updatePage = createAsyncThunk<
  IUpdatePageResponse,
  { pageId: string; data: IUpdatePageDto },
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/updatePage`,
  async ({ pageId, data }) => {
    const response = await PagesAPI.updatePage(pageId, data);
    return response.data;
  },
  { serializeError: formatApiError },
);
