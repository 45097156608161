export interface IDomainConfig {
  domain: string;
  phone: string;
  title: string;
}

export enum DOMAINS {
  AMAZON = 'https://www.amazon.com/',
  CONSTRUCTION_COMPLETE = 'https://www.constructioncomplete.com/',
  NASA = 'https://www.nasa.gov/',
}
