import { ReactNode } from 'react';
import { StyledBlock, StyledHeader, StyledSection, StyledSuptitle, StyledTitle } from './HeaderStyles';

type IHeaderProps = {
  children?: ReactNode;
  title: string;
  suptitle?: string;
};

const Header = ({ children, title, suptitle }: IHeaderProps) => {
  return (
    <StyledHeader>
      <StyledSection>
        <StyledBlock>
          {!!suptitle && <StyledSuptitle>{suptitle}</StyledSuptitle>}
          <StyledTitle>{title}</StyledTitle>
        </StyledBlock>
        {children}
      </StyledSection>
    </StyledHeader>
  );
};

export default Header;
