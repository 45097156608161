import { IReview } from '@/models/review.model';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Rate from 'src/shared/ui/Rate/Rate';
import { renderDate } from 'src/shared/utils/dataRender';
import { ReviewDetailsBlock, SubRateContainer, SubRateOuter } from '../ReviewDetailsBlock';
import { StyledComment } from './ReviewDetailsStyles';

interface ReviewDetailsProps {
  review: IReview | null;
}

export const ReviewDetails: FC<ReviewDetailsProps> = ({ review }) => {
  const { t } = useTranslation(['common']);
  if (!review) {
    return null;
  }

  return (
    <>
      <ReviewDetailsBlock title={t('title_user_name')}>
        <p>{review.userName}</p>
      </ReviewDetailsBlock>
      <ReviewDetailsBlock title={t('title_date_and_time')}>
        <p>{renderDate(review.createdAt, 'D MMM, hh:mm a')}</p>
      </ReviewDetailsBlock>
      <ReviewDetailsBlock title={t('title_product_link')}>
        <a
          href={`https://dev.construction-complete.myapp.com.ua/product/${review.productId}`}
          target="_blank"
          rel="noreferrer"
        >
          https://dev.construction-complete.myapp.com.ua/product/{review.productId}
        </a>
      </ReviewDetailsBlock>
      <ReviewDetailsBlock title={t('title_product_id')}>
        <p>{review.productId}</p>
      </ReviewDetailsBlock>
      <ReviewDetailsBlock title={t('title_comment')}>
        <StyledComment>{review.reviewText}</StyledComment>
      </ReviewDetailsBlock>
      <ReviewDetailsBlock title={t('title_overall_rating')}>
        <Rate disabled value={review.overallRating || 0} />
      </ReviewDetailsBlock>
      <ReviewDetailsBlock title={t('title_rating_by_parameter')}>
        <SubRateOuter>
          <SubRateContainer>
            <p>{t('text_easy_to_use')}:</p>
            <Rate disabled value={review.easeOfUseRating || 0} />
          </SubRateContainer>
          <SubRateContainer>
            <p>{t('text_durability')}:</p>
            <Rate disabled value={review.durabilityRating || 0} />
          </SubRateContainer>
          <SubRateContainer>
            <p>{t('text_value_for_money')}:</p>
            <Rate disabled value={review.valueForMoneyRating || 0} />
          </SubRateContainer>
        </SubRateOuter>
      </ReviewDetailsBlock>
    </>
  );
};
