import { Color } from 'antd/es/color-picker';

export enum SettingsForms {
  GENERAL = 'general',
  UI = 'ui',
  BULK_GENERAL = 'bulkGeneral',
}

export enum GeneralSettingsBulkFormFields {
  GOOGLE_TAG_MANAGER = 'checkboxGoogleTagManagement',
  COPYRIGHT = 'checkboxCopyright',
  CHANNEL = 'checkboxChannel',
  SHIPPING = 'checkboxShipping',
  SMTP_HOST = 'checkboxSmtpHost',
  SMTP_PORT = 'checkboxSmtpPort',
  SMTP_AUTH_USER = 'checkboxSmtpAuthUser',
  SMTP_AUTH_PASS = 'checkboxSmtpAuthPass',
  PHONES_FOOTER = 'checkboxPhonesFooter',
  PHONES_CONTACT_US = 'checkboxPhonesContactUs',
  ADMIN_EMAILS = 'checkboxAdminEmails',
  POPULAR_PRODUCTS = 'checkboxPopularProducts',
  CONTACT_INFO_EMAIL = 'checkboxContactInfoEmail',
  CONTACT_INFO_WORKING_HOURS = 'checkboxContactInfoWorkingHours',
  CONTACT_INFO_ADDRESS = 'checkboxContactInfoAddress',
}

export enum GeneralSettingsFormFields {
  GOOGLE_TAG_MANAGER = 'googleTagManagement',
  COPYRIGHT = 'copyright',
  CHANNEL = 'channel',
  SHIPPING = 'shipping',
  SMTP_HOST = 'smtpHost',
  SMTP_PORT = 'smtpPort',
  SMTP_AUTH_USER = 'smtpAuthUser',
  SMTP_AUTH_PASS = 'smtpAuthPass',
  PHONES_FOOTER = 'phonesFooter',
  PHONES_CONTACT_US = 'phonesContactUs',
  ADMIN_EMAILS = 'adminEmails',
  POPULAR_PRODUCTS = 'popularProducts',
  CONTACT_INFO_EMAIL = 'contactInfoEmail',
  CONTACT_INFO_WORKING_HOURS = 'contactInfoWorkingHours',
  CONTACT_INFO_ADDRESS = 'contactInfoAddress',
}

export enum UISettingsFormFields {
  HEADER_LOGO = 'headerLogo',
  FOOTER_LOGO = 'footerLogo',
  EMPTY_STATE_LOGO = 'emptyStateLogo',
  MOBILE_HEADER_LOGO = 'mobileHeaderLogo',
  COMPANY_LOGO = 'companyLogo',
  FAVICON48 = 'favicon48',
  FAVICON96 = 'favicon96',
  FAVICON144 = 'favicon144',
  PRIMARY_COLOR = 'primaryColor',
  PRIMARY_HOVER_COLOR = 'primaryHoverColor',
  DISABLED_COLOR = 'disabledColor',
  COLOR_ON_PRIMARY = 'colorOnPrimary',
  SECONDARY_COLOR = 'secondaryColor',
  COLOR_ON_SECONDARY = 'colorOnSecondary',
}

export type TUISettingsFormValues = {
  [UISettingsFormFields.HEADER_LOGO]: File | string;
  [UISettingsFormFields.FOOTER_LOGO]: File | string;
  [UISettingsFormFields.EMPTY_STATE_LOGO]: File | string;
  [UISettingsFormFields.MOBILE_HEADER_LOGO]: File | string;
  [UISettingsFormFields.COMPANY_LOGO]: File | string;
  [UISettingsFormFields.FAVICON48]: File | string;
  [UISettingsFormFields.FAVICON96]: File | string;
  [UISettingsFormFields.FAVICON144]: File | string;
  [UISettingsFormFields.PRIMARY_COLOR]: Color | string;
  [UISettingsFormFields.PRIMARY_HOVER_COLOR]: Color | string;
  [UISettingsFormFields.DISABLED_COLOR]: Color | string;
  [UISettingsFormFields.COLOR_ON_PRIMARY]: Color | string;
  [UISettingsFormFields.SECONDARY_COLOR]: Color | string;
  [UISettingsFormFields.COLOR_ON_SECONDARY]: Color | string;
};

export type TGeneralSettingsFormValues = {
  [GeneralSettingsFormFields.GOOGLE_TAG_MANAGER]: string;
  [GeneralSettingsFormFields.ADMIN_EMAILS]: string[];
  [GeneralSettingsFormFields.COPYRIGHT]: string;
  [GeneralSettingsFormFields.CHANNEL]: string;
  [GeneralSettingsFormFields.SMTP_AUTH_PASS]: string;
  [GeneralSettingsFormFields.SMTP_AUTH_USER]: string;
  [GeneralSettingsFormFields.SMTP_HOST]: string;
  [GeneralSettingsFormFields.SMTP_PORT]: number;
  [GeneralSettingsFormFields.PHONES_FOOTER]: string;
  [GeneralSettingsFormFields.PHONES_CONTACT_US]: string;
  [GeneralSettingsFormFields.POPULAR_PRODUCTS]: string[];
  [GeneralSettingsFormFields.CONTACT_INFO_EMAIL]: string;
  [GeneralSettingsFormFields.CONTACT_INFO_WORKING_HOURS]: string;
  [GeneralSettingsFormFields.CONTACT_INFO_ADDRESS]: string;
};

export type TGeneralSettingsBulkFormValues = Omit<TGeneralSettingsFormValues, GeneralSettingsFormFields.CHANNEL> & {
  [GeneralSettingsFormFields.CHANNEL]: number[];
  [GeneralSettingsBulkFormFields.GOOGLE_TAG_MANAGER]: boolean;
  [GeneralSettingsBulkFormFields.ADMIN_EMAILS]: boolean;
  [GeneralSettingsBulkFormFields.COPYRIGHT]: boolean;
  [GeneralSettingsBulkFormFields.SMTP_AUTH_PASS]: boolean;
  [GeneralSettingsBulkFormFields.SMTP_AUTH_USER]: boolean;
  [GeneralSettingsBulkFormFields.SMTP_HOST]: boolean;
  [GeneralSettingsBulkFormFields.SMTP_PORT]: boolean;
  [GeneralSettingsBulkFormFields.PHONES_FOOTER]: boolean;
  [GeneralSettingsBulkFormFields.PHONES_CONTACT_US]: boolean;
  [GeneralSettingsBulkFormFields.POPULAR_PRODUCTS]: boolean;
  [GeneralSettingsBulkFormFields.CONTACT_INFO_EMAIL]: boolean;
  [GeneralSettingsBulkFormFields.CONTACT_INFO_WORKING_HOURS]: boolean;
  [GeneralSettingsBulkFormFields.CONTACT_INFO_ADDRESS]: boolean;
};
