import { FormItem } from '@/components';
import styled from 'styled-components';

export const WideFormItem = styled(FormItem)`
  width: 100%;
`;

export const RegularFormItem = styled(FormItem)`
  width: 200px;
`;
