import { ButtonProps } from 'antd';
import { ForwardedRef, forwardRef } from 'react';
import { StyledButton } from './ButtonStyles';

export type IButtonProps = ButtonProps;

const Button = ({ ...props }: IButtonProps, ref: ForwardedRef<HTMLButtonElement>): JSX.Element => {
  return <StyledButton ref={ref} {...props} />;
};

export default forwardRef<HTMLButtonElement, IButtonProps>(Button);
