import { IApiError } from '@/models/apiError.model';
import { IShippingPrice } from '@/models/shippingPrice.model';

export const SLICE_NAME = 'shippingPrices';

export interface ShippingPriceState {
  shippingPrices: IShippingPrice[] | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: ShippingPriceState = {
  shippingPrices: null,
  isLoading: false,
  error: null,
};
