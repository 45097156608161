import { TextAreaProps } from 'antd/es/input';
import { TextAreaRef } from 'antd/es/input/TextArea';
import { forwardRef } from 'react';
import { StyledTextArea } from './InputStyles';

export type ITextAreaProps = TextAreaProps & React.RefAttributes<TextAreaRef>;

const TextArea = forwardRef<TextAreaRef, ITextAreaProps>(({ value, ...props }, ref) => (
  <StyledTextArea {...props} value={value} ref={ref} />
));

TextArea.displayName = 'CustomTextArea';

export default TextArea;
