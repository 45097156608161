import { IApiError } from '@/models/apiError.model';
import {
  ICreateVariableDTO,
  ICreateVariableResponse,
  IDeleteVariableDTO,
  IDeleteVariableResponse,
  IGetIVariablesResponse,
  IGetVariablesDTO,
  IUpdateVariableDTO,
  IUpdateVariableResponse,
} from '@/models/variable.model';
import { formatApiError } from '@/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import VariablesAPI from 'src/api/VariablesAPI';
import { SLICE_NAME } from './models';

export const getVariables = createAsyncThunk<
  IGetIVariablesResponse,
  IGetVariablesDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/getVariables`,
  async (params) => {
    const response = await VariablesAPI.getVariables(params);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateVariable = createAsyncThunk<
  IUpdateVariableResponse,
  IUpdateVariableDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/updateVariable`,
  async (data) => {
    const response = await VariablesAPI.updateVariable(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const deleteVariable = createAsyncThunk<
  IDeleteVariableResponse,
  IDeleteVariableDTO,
  { serializedErrorType: IApiError; fulfilledMeta: { id: string } }
>(
  `${SLICE_NAME}/deleteVariable`,
  async (data, thunkAPI) => {
    const response = await VariablesAPI.deleteVariable(data);
    return thunkAPI.fulfillWithValue(response.data, { id: data.id });
  },
  { serializeError: formatApiError },
);

export const createVariable = createAsyncThunk<
  ICreateVariableResponse,
  ICreateVariableDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/createVariable`,
  async (data) => {
    const response = await VariablesAPI.createVariable(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
