import { IUpdateReviewDTO } from '@/models/review.model';
import { EditReviewFormFields, TEditReviewFormValues } from '../typedefs';
import { Dayjs } from 'dayjs';

export class UpdateReviewDTO implements IUpdateReviewDTO {
  reviewId: string;

  reviewText: string;

  createdAt: Dayjs;

  userName?: string;

  durabilityRating: number;

  easeOfUseRating: number;

  valueForMoneyRating: number;

  constructor(values: TEditReviewFormValues & { reviewId: string }) {
    this.reviewId = values.reviewId;
    this.reviewText = values[EditReviewFormFields.REVIEW_TEXT];
    this.createdAt = values[EditReviewFormFields.CREATED_AT];
    this.userName = values[EditReviewFormFields.USER_NAME] || undefined;
    this.durabilityRating = values[EditReviewFormFields.DURABILITY_RATING];
    this.easeOfUseRating = values[EditReviewFormFields.EASE_OF_USE_RATING];
    this.valueForMoneyRating = values[EditReviewFormFields.VALUE_FOR_MONEY_RATING];
  }
}
