import { ProductsEndpoints } from '@/models/product.model';
import { ApiResponse, httpClient } from '@/utils';

class ProductsAPI {
  static checkProductExistsById(id: string): ApiResponse<boolean> {
    return httpClient.get<boolean>(`${ProductsEndpoints.ROOT}/${id}/check`);
  }
}

export default ProductsAPI;
