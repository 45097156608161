import { IMenuItemType, Menu as AntdMenu } from '@/ui';

type TMenuProps = {
  items: IMenuItemType[];
  selectedKey?: string;
};

const Menu = ({ items, selectedKey }: TMenuProps) => {
  return <AntdMenu mode="inline" items={items} selectedKeys={[selectedKey ?? '']} multiple={false} />;
};

export default Menu;
