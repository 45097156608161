import { FormInstance } from 'antd';
import { InternalNamePath } from 'antd/es/form/interface';
import { getFirstErrorFieldTabKey } from '../utils/getFirstErrorFieldTabKey';

export const useScrollToErrorField = (
  form: FormInstance,
  activeTab: string,
  setActiveTab: (activeTabKey: string) => void,
) => {
  const scrollFormTo = (name: InternalNamePath) => {
    // Introduce a slight delay to ensure the tab switch is completed before scrolling
    setTimeout(() => {
      form.scrollToField(name as string[], { behavior: 'smooth', block: 'center' });
    }, 0);
  };

  const scrollToErrorField = ({
    errorFields,
  }: {
    errorFields: {
      name: InternalNamePath;
      errors: string[];
    }[];
  }) => {
    const [firstErrorField] = errorFields;

    const currentTabError = errorFields.find((error) => {
      const tabKey = getFirstErrorFieldTabKey(error.name);
      return tabKey === activeTab;
    });

    if (currentTabError) {
      // Scroll to the first error on the current tab
      scrollFormTo(currentTabError.name);
    } else {
      // If no errors on the current tab, switch tabs and scroll to the first error
      const targetTabKey = getFirstErrorFieldTabKey(firstErrorField.name);
      setActiveTab(targetTabKey);
      scrollFormTo(firstErrorField.name);
    }
  };

  return { scrollToErrorField };
};
