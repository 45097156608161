import { httpClient, ApiResponse } from '@/utils';
import {
  staticChannelEndpoints,
  IGetChannelsResponse,
  IUpdateChannelDTO,
  getChannelEndpoints,
  IUpdateChannelResponse,
  ICreateChannelResponse,
  ICreateChannelDTO,
} from '@/models/channel.model';

class ChannelsAPI {
  static getChannels(): ApiResponse<IGetChannelsResponse> {
    const { GET_CHANNELS } = staticChannelEndpoints;
    return httpClient.get<IGetChannelsResponse>(GET_CHANNELS);
  }

  static createChannel(data: ICreateChannelDTO): ApiResponse<ICreateChannelResponse> {
    const { CREATE_CHANNEL } = staticChannelEndpoints;
    return httpClient.post<ICreateChannelResponse>(CREATE_CHANNEL, data);
  }

  static updateChannel({ id, ...data }: IUpdateChannelDTO): ApiResponse<IUpdateChannelResponse> {
    const { UPDATE_CHANNEL } = getChannelEndpoints({ id });
    return httpClient.patch<IUpdateChannelResponse>(UPDATE_CHANNEL, data);
  }
}

export default ChannelsAPI;
