import { IUpdateShippingPricesDTO } from '@/models/shippingPrice.model';

class UpdateShippingPricesDTO implements IUpdateShippingPricesDTO {
  ids: string[];

  price: number;

  constructor({ ids, price }: { ids: string[]; price: string }) {
    this.ids = ids;
    this.price = parseFloat(price);
  }
}

export default UpdateShippingPricesDTO;
