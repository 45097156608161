import { useEffect } from 'react';
import {
  getShippingPrices,
  selectShippingPrices,
  selectShippingPricesError,
  selectShippingPricesIsLoading,
} from '../feature';
import { useAppDispatch, useAppState } from '@/store';

export const useGetShippingPrices = () => {
  const shippingPrices = useAppState(selectShippingPrices);
  const isLoading = useAppState(selectShippingPricesIsLoading);
  const error = useAppState(selectShippingPricesError);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (shippingPrices || isLoading || !!error) return;
    dispatch(getShippingPrices());
  }, [isLoading, error, dispatch, shippingPrices]);

  return {
    shippingPrices,
    isLoading,
    error,
  };
};
