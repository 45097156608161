// Global
import { Form } from 'antd';
import { FormListProps } from 'antd/es/form';
import { FC } from 'react';

// Components
import { UpdateContentDefaultBlock } from '../../formBlocks/UpdateContentDefaultBlock';
import { VariableType } from 'src/shared/ui/VariablesDetector/VariableDetector.typedefs';

interface UpdateDefaultBlocksFormListProps {
  fieldName: FormListProps['name'];
  registerInputRef: (fieldName: string, ref: any, type: VariableType) => void;
  handleInputChange: (fieldName: string, value: string) => void;
}

export const UpdateDefaultBlocksFormList: FC<UpdateDefaultBlocksFormListProps> = ({
  fieldName,
  registerInputRef,
  handleInputChange,
}) => {
  return (
    <Form.List name={fieldName}>
      {(fields) =>
        fields.map((field) => (
          <UpdateContentDefaultBlock
            registerInputRef={registerInputRef}
            handleInputChange={handleInputChange}
            key={field.key}
            fieldName={field.name}
          />
        ))
      }
    </Form.List>
  );
};
