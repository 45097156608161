// Globals
import { MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash';

// Store
import { useAppDispatch } from '@/store';
import { updateShippingPrice, updateShippingPrices } from '../../feature';

// Modules
import { Header } from '@/modules/Layout';

// Components
import { Button, Table, InputNumber, Select, Spinner } from '@/ui';
import { Form, FormItem, Modal, ModalSize } from '@/components';
import { StyledContainer, StyledTopBlock } from './ShippingPricesContainerStyles';

// Models
import { IShippingPrice } from '@/models/shippingPrice.model';

// Helpers
import { useGetShippingPrices, useShippingPriceFormRules } from '../../hooks';
import { UpdateShippingPriceDTO, UpdateShippingPricesDTO } from '../../dtos';
import { getStatesColumn, getStatesOptions, ShippingPriceFormFields } from '../../helpers';
import { useBoolean } from '@/hooks';

const ShippingPricesContainer = () => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const { shippingPrices, isLoading } = useGetShippingPrices();
  const [selectedPrice, setSelectedPrice] = useState<IShippingPrice | null>(null);
  const [country, setCountry] = useState<string | null>(null);
  const { value: open, toggle: toggleModal } = useBoolean();

  const pricesGroupedByCountry = useMemo(() => groupBy(shippingPrices, 'countryId'), [shippingPrices]);
  const rules = useShippingPriceFormRules();

  const handleSelectCountry = (event: MouseEvent<HTMLButtonElement>) => {
    const countryID = event.currentTarget.dataset.id;
    if (!countryID) return;
    setCountry(countryID);
    setSelectedPrice(null);
    toggleModal();
  };

  const handleClose = () => {
    setCountry(null);
    toggleModal();
  };

  const handleBulkUpdate = async ({ price, states }: { price: string; states: string[] }) => {
    if (!country) return;
    const dto = new UpdateShippingPricesDTO({
      ids: states,
      price,
    });
    await dispatch(updateShippingPrices(dto));
    setCountry(null);
    toggleModal();
  };

  const handleSave = async (values: { price: string }) => {
    if (!selectedPrice) return;
    if (!values.price) {
      setSelectedPrice(null);
      return;
    }
    const dto = new UpdateShippingPriceDTO({
      ...values,
      id: selectedPrice.id,
    });

    await dispatch(updateShippingPrice(dto));
    setSelectedPrice(null);
  };

  return (
    <>
      <Header title={t('menu_shipping_prices')} suptitle={t('menu_website_management')} />
      <StyledContainer>
        <Spinner spinning={isLoading}>
          {Object.entries(pricesGroupedByCountry)?.map(([countryCode, prices]) => (
            <div key={countryCode}>
              <div>
                <Table
                  columns={getStatesColumn(t, isLoading, selectedPrice, setSelectedPrice, handleSave)}
                  dataSource={prices}
                  pagination={false}
                  loading={isLoading}
                  rowKey="id"
                  title={() => (
                    <StyledTopBlock>
                      <h2>{new Intl.DisplayNames(['en'], { type: 'region' }).of(countryCode)}</h2>
                      <Button type="primary" size="small" data-id={countryCode} onClick={handleSelectCountry}>
                        {t('copy_to_bulk')}
                      </Button>
                    </StyledTopBlock>
                  )}
                />
              </div>
            </div>
          ))}
        </Spinner>
      </StyledContainer>
      <Modal
        visible={open}
        onCancel={handleClose}
        size={ModalSize.LARGE}
        destroyOnClose
        title={country ? new Intl.DisplayNames(['en'], { type: 'region' }).of(country) : undefined}
      >
        <Form onFinish={handleBulkUpdate}>
          <FormItem name={ShippingPriceFormFields.PRICE} rules={rules[ShippingPriceFormFields.PRICE]}>
            <InputNumber placeholder={t('price')} />
          </FormItem>
          {!!country && (
            <FormItem name={ShippingPriceFormFields.STATES} rules={rules[ShippingPriceFormFields.STATES]}>
              <Select
                options={getStatesOptions(pricesGroupedByCountry[country])}
                isMultiselect
                placeholder={t('state')}
              />
            </FormItem>
          )}
          <Button type="primary" block htmlType="submit" loading={isLoading}>
            {t('save')}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default ShippingPricesContainer;
