import { useTranslation } from 'react-i18next';
import { HowItWorksCollapse } from './HowItWorks.style';

export const HowItWorks = (): JSX.Element => {
  const { t } = useTranslation(['common']);
  return (
    <HowItWorksCollapse
      size="large"
      items={[
        {
          key: 'help',
          label: t('how_variables_work_label'),
          children: <div dangerouslySetInnerHTML={{ __html: t('how_variables_work_description') }} />,
        },
      ]}
    />
  );
};
