import { IApiError } from '@/models/apiError.model';
import { IChannel } from '@/models/channel.model';

export const SLICE_NAME = 'channels';

export interface ChannelsState {
  channels: IChannel[] | null;
  channel: IChannel | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: ChannelsState = {
  channels: null,
  channel: null,
  isLoading: false,
  error: null,
};
