import { IPaginationDTO, IPaginationResponse } from '@/models/pagination.model';
import { RedirectType } from '@/modules/Redirects/typedefs';

export enum RedirectEndpoint {
  ROOT = '/redirects',
}

export const staticRedirectsEndpoints = {
  GET_REDIRECTS: RedirectEndpoint.ROOT,
  CREATE_REDIRECT: RedirectEndpoint.ROOT,
  IMPORT_REDIRECTS: `${RedirectEndpoint.ROOT}/import`,
};

export const getRedirectsEndpoints = ({ id }: { id: string }) => ({
  UPDATE_REDIRECT: `${RedirectEndpoint.ROOT}/${id}`,
  DELETE_REDIRECT: `${RedirectEndpoint.ROOT}/${id}`,
});

export interface IRedirect {
  id: string;
  channelId: number;
  source: string;
  target: string;
  code: RedirectType;
  createdAt: Date;
  updatedAt: Date;
}

// ============== DTO ==============

export type IGetRedirectsDTO = IPaginationDTO & {
  search?: string;
  channelId?: number;
};

export type IUpdateRedirectDTO = Partial<IRedirect> & {
  id: string;
};

export type IDeleteRedirectDTO = {
  id: string;
};

export type IRedirectsImportDTO = {
  channelId: number;
  file: File;
};

export type ICreateRedirectDTO = Omit<IRedirect, 'id' | 'createdAt' | 'updatedAt'>;

// ============== Response ==============

export type ICreateRedirectResponse = IRedirect;

export type IUpdateRedirectResponse = IRedirect;

export type IDeleteRedirectResponse = IRedirect;

export type IGetRedirectResponse = { redirects: IRedirect[] } & IPaginationResponse;

export type IRedirectsImportResponse = {
  success: boolean;
  error?: string;
};
