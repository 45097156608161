import { useGetRequiredRule, useLengthValidationRule } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { VariableFormFields } from '../helpers/types';

const useVariableFormRules = () => {
  const { t } = useTranslation(['validations']);

  const requiredRule = useGetRequiredRule({ t });
  const lengthRule = useLengthValidationRule({ t, max: 1500 });

  return {
    [VariableFormFields.CODE]: [requiredRule],
    [VariableFormFields.DEFAULT_VALUE]: [requiredRule, lengthRule],
    [VariableFormFields.VALUE]: [lengthRule],
  };
};

export default useVariableFormRules;
