import { css, RuleSet } from 'styled-components';
import { COLORS, FONT_SIZES, FONT_WEIGHTS, HEXColor } from './theme';

export const pickThemeColor = (colorName: keyof typeof COLORS): HEXColor => COLORS[colorName] || COLORS.BLACK;

export const pickThemeFontSize = (size: keyof typeof FONT_SIZES): string => FONT_SIZES[size];

export const pickGradientColor = (
  firstColorName: keyof typeof COLORS,
  secondColorName: keyof typeof COLORS,
): HEXColor => {
  return ` linear-gradient(
      93.95deg,
    ${pickThemeColor(firstColorName)} -8.48%,
    ${pickThemeColor(secondColorName)} 104.65%
    )`;
};

export const pickGradientBorder = (
  background: keyof typeof COLORS,
  firstColorName: keyof typeof COLORS,
  secondColorName: keyof typeof COLORS,
): HEXColor => {
  return `linear-gradient(${COLORS[background]},${COLORS[background]}) padding-box,
   linear-gradient(to right, ${COLORS[firstColorName]} 0%, ${COLORS[secondColorName]} 100%) border-box;`;
};

export const pickThemeFontStyles = (
  size: keyof typeof FONT_SIZES,
  height: keyof typeof FONT_SIZES,
  weight: keyof typeof FONT_WEIGHTS = 'NORMAL',
): RuleSet => css`
  font-size: ${FONT_SIZES[size]};
  line-height: ${FONT_SIZES[height]};
  font-weight: ${FONT_WEIGHTS[weight]};
`;
