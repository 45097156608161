interface ValidationRules {
  required?: boolean;
  allowedTypes?: string[];
  maxSize?: number;
}

interface Schema {
  type: string;
  properties?: Record<string, Schema>;
  items?: Schema;
  validations?: ValidationRules;
}

export function parseAcceptTypesFromSchema(
  schema: Schema,
  parentKey: string[] = [],
): Record<string, string | undefined> {
  const acceptTypes: Record<string, string | undefined> = {};

  if (!schema || !schema.properties) {
    return acceptTypes;
  }

  for (const key in schema.properties) {
    const property = schema.properties[key];

    if (property.validations && property.validations.allowedTypes) {
      const fieldPath = [...parentKey, key].join('.');
      acceptTypes[fieldPath] = property.validations.allowedTypes.join(',');
    }

    if (property.properties) {
      Object.assign(acceptTypes, parseAcceptTypesFromSchema(property, [...parentKey, key]));
    }

    if (property.items && property.items.properties) {
      Object.assign(acceptTypes, parseAcceptTypesFromSchema(property.items, [...parentKey, key]));
    }
  }

  return acceptTypes;
}
