import { useTranslation } from 'react-i18next';
import { useGetEmailRule, useGetRequiredRule } from '@/hooks';
import { AuthFieldsNames } from '../helpers/types';

const useSignInFormRules = () => {
  const { t } = useTranslation(['validations']);

  const emailRule = useGetEmailRule({ t });
  const requiredRule = useGetRequiredRule({ t });

  return {
    [AuthFieldsNames.EMAIL]: [emailRule, requiredRule],
    [AuthFieldsNames.PASSWORD]: [requiredRule],
  };
};

export default useSignInFormRules;
