// Globals
import { debounce } from 'lodash';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

// Store
import { useAppDispatch, useAppState } from '@/store';
import { getVariables } from '../../feature';

// Modules
import { selectChannel } from '@/modules/Channels';

// Helpers
import { VariableStyledSearch } from './VariableSearch.style';
import { GetVariablesDTO } from '../../dtos/GetVariables.dto';

interface VariableSearchProps {
  loading: boolean;
}

export const VariableSearch: FC<VariableSearchProps> = ({ loading }) => {
  const { t } = useTranslation(['common']);
  const dispatch = useAppDispatch();
  const currentChannel = useAppState(selectChannel);

  const [query, setQuery] = useSearchParams();

  const handleSearch = debounce(async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      setQuery({ search: event.target.value, page: '1' });
    } else {
      setQuery({ page: '1' });
    }
    if (!currentChannel?.id) return;
    const dto = new GetVariablesDTO({ page: 1, channelId: currentChannel.id, query: event.target.value });
    dispatch(getVariables(dto)).unwrap();
  }, 500);

  return (
    <VariableStyledSearch
      defaultValue={query.get('search') || ''}
      loading={loading}
      size="large"
      onChange={handleSearch}
      placeholder={t('variables_filter_placeholder')}
    />
  );
};
