// Globals
import { useTranslation } from 'react-i18next';

// Modules
import { Header } from '@/modules/Layout';

// Store

// Helpers
import { selectChannel } from '@/modules/Channels';
import { useAppState } from '@/store';

// Components

// Hooks
import { useGetPage } from '../../hooks/useGetPage';
import { useGetPages } from '../../hooks/useGetPages';

// Utils
import { pagesTableColumns } from '../../utils/pagesTableColumns';

// Styles
import { UpdatePage } from '../../components/UpdatePage';
import { PagesStyledTable, StyledPagesContainerInner } from './PagesContainer.styles';

export const PagesContainer = () => {
  const { t } = useTranslation(['common']);
  const currentChannel = useAppState(selectChannel);
  const { loadPage } = useGetPage();

  const { pages, isLoading: pagesIsLoading } = useGetPages(currentChannel?.id);

  return (
    <>
      <Header title={t('menu_pages_management')} />
      <StyledPagesContainerInner>
        <PagesStyledTable
          columns={pagesTableColumns}
          rowKey={'id'}
          dataSource={pages}
          loading={pagesIsLoading}
          pagination={false}
          rowHoverable
          onRow={(record) => ({
            onClick: () => {
              loadPage(record.type);
            },
          })}
        />
      </StyledPagesContainerInner>
      <UpdatePage />
    </>
  );
};
