import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { PagesState, SLICE_NAME } from './models';

const pageSelector = (state: RootState): PagesState => state[SLICE_NAME];

export const selectPages = createSelector(pageSelector, (state: PagesState) => state.pages);

export const selectPagesIsLoading = createSelector(pageSelector, (state: PagesState) => state.isLoading);

export const selectPagesError = createSelector(pageSelector, (state: PagesState) => state.error);

export const selectCurrentPage = createSelector(pageSelector, (state: PagesState) => state.currentPage);

export const selectCurrentPageIsLoading = createSelector(
  pageSelector,
  (state: PagesState) => state.currentPageIsLoading,
);

export const selectCurrentPageError = createSelector(pageSelector, (state: PagesState) => state.currentPageError);

export const selectUpdateStatusIsLoading = createSelector(
  pageSelector,
  (state: PagesState) => state.updateStatusIsLoading,
);

export const selectUpdateStatusError = createSelector(pageSelector, (state: PagesState) => state.updateStatusError);
