import { DatePicker, DatePickerProps } from 'antd';
import styled, { css } from 'styled-components';
export const getStylesBySize = (size: DatePickerProps['size']) => {
  switch (size) {
    case 'small':
      return css`
        height: 2.75rem;
      `;
    case 'large':
      return css`
        height: 3.25rem;
      `;
    case 'middle':
      return css`
        height: 3rem;
      `;
    default:
      return css`
        height: 2.875rem;
      `;
  }
};

export const StyledDatePicker = styled(DatePicker)<{
  size: DatePickerProps['size'];
}>`
  ${({ size }) => getStylesBySize(size)}
` as typeof DatePicker;
