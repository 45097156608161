import { Collapse, List, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { VariableDetectorCollapse } from './VariableDetector.style';
import { VariableInfo } from './VariableDetector.typedefs';

const { Panel } = Collapse;
const { Paragraph } = Typography;

export interface VariableDetectorProps {
  variables: VariableInfo[];
  onSelectVariable: (variable: VariableInfo) => void;
}

export const VariableDetector: React.FC<VariableDetectorProps> = ({ variables, onSelectVariable }) => {
  const { t } = useTranslation(['common']);

  if (!variables.length) {
    return null;
  }

  const groupedVariables = variables.reduce(
    (acc, variable) => {
      if (!acc[variable.inputName]) {
        acc[variable.inputName] = [];
      }
      acc[variable.inputName].push(variable);
      return acc;
    },
    {} as Record<string, VariableInfo[]>,
  );

  return (
    <VariableDetectorCollapse>
      <Panel header={`${t('variables_detector_title')}: ${variables.length}`} key="variables">
        <Paragraph>{t('variables_detector_hint')}</Paragraph>
        {Object.keys(groupedVariables).map((inputName) => (
          <Collapse size="small" key={inputName}>
            <Panel header={`Input: ${inputName}`} key={inputName}>
              <List
                size="small"
                dataSource={groupedVariables[inputName]}
                renderItem={(variable, index) => (
                  <List.Item>
                    <Button size="small" type="dashed" onClick={() => onSelectVariable(variable)}>
                      {index + 1}. {variable.value}
                    </Button>
                  </List.Item>
                )}
              />
            </Panel>
          </Collapse>
        ))}
      </Panel>
    </VariableDetectorCollapse>
  );
};
