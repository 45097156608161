// Globals
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Models
import { ReviewStatus } from '@/models/review.model';

// Store
import { useAppDispatch, useAppState } from '@/store';
import { createReview } from '../../feature';

// Modules
import { selectChannel } from '@/modules/Channels';
import { selectChannels } from '@/modules/Channels/feature';

// Components
import { Form, FormItem, useForm, useWatchForm, Modal, ModalSize } from '@/components';
import { CheckIcon, PlusIcon } from '@/icons';
import { Button, Input, PopConfirm, Rate, Select, TextArea } from '@/ui';
import DatePicker from 'src/shared/ui/DatePicker/DatePicker';
import { WideFormItem } from '../EditReviewForm';
import { ReviewDetailsBlock, SubRateContainer, SubRateOuter } from '../ReviewDetailsBlock';
import { RegularFormItem } from './CreateReviewFormStyles';

// Helpers
import { useBoolean } from '@/hooks';
import { CreateReviewDTO } from '../../dtos/CreateReviewDTO';
import { useCreateReviewFormRules } from '../../hooks/useCreateReviewFormRules';
import { useOverallRating } from '../../hooks/useOverallRating';
import { CreateReviewFormFields, TCreateReviewFormValues } from '../../typedefs';
import { mapInitialFormValues } from '../../utils/mappers';

export const CreateReviewForm = () => {
  const { value: modalOpened, setTrue: openModal, setFalse: closeModal } = useBoolean();
  const { t } = useTranslation(['common']);
  const selectedChannel = useAppState(selectChannel);
  const channels = useAppState(selectChannels);
  const [form] = useForm<CreateReviewFormFields>();
  const overallRating = useOverallRating(form);
  const initialValues = useMemo(() => mapInitialFormValues(selectedChannel?.id ?? 0), [selectedChannel]);

  const dispatch = useAppDispatch();

  const selectedStatus = useWatchForm(CreateReviewFormFields.STATUS, form);

  const rules = useCreateReviewFormRules();

  const channelOptions =
    channels?.map((channel) => ({
      label: channel.name,
      value: channel.id,
    })) || [];

  const statusOptions = Object.values(ReviewStatus)
    .filter((status) => status !== ReviewStatus.ALL)
    .map((status) => ({
      label: status,
      value: status,
    }));

  const handleSubmit = useCallback(() => {
    form.validateFields();
    form.submit();
  }, [form]);

  const handleCreateReview = useCallback(
    async (values: TCreateReviewFormValues) => {
      const dto = new CreateReviewDTO({ ...values });
      try {
        await dispatch(createReview(dto)).unwrap();
        closeModal();
        form.resetFields();
      } catch (error) {
        console.error(error);
      }
    },
    [closeModal, dispatch, form],
  );

  return (
    <>
      <Button type="primary" onClick={openModal} icon={<PlusIcon />}>
        {t('btn_add_review')}
      </Button>
      <Modal
        withFooter
        footer={() =>
          selectedStatus === ReviewStatus.PENDING ? (
            <Button type="primary" onClick={handleSubmit} icon={<CheckIcon />}>
              {t('btn_add_review')}
            </Button>
          ) : (
            <PopConfirm
              title={t('add_review_confirm')}
              description={t('add_review_description')}
              onConfirm={handleSubmit}
            >
              <Button type="primary" icon={<CheckIcon />}>
                {t('btn_add_review')}
              </Button>
            </PopConfirm>
          )
        }
        visible={modalOpened}
        size={ModalSize.HUGE}
        onCancel={closeModal}
        title={t('title_create_review')}
      >
        <Form form={form} onFinish={handleCreateReview} initialValues={initialValues}>
          <ReviewDetailsBlock title={t('title_channel')}>
            <RegularFormItem name={CreateReviewFormFields.CHANNEL_ID} rules={rules[CreateReviewFormFields.CHANNEL_ID]}>
              <Select options={channelOptions} value={selectedChannel?.id} />
            </RegularFormItem>
          </ReviewDetailsBlock>
          <ReviewDetailsBlock title={t('title_product_id')}>
            <RegularFormItem name={CreateReviewFormFields.PRODUCT_ID} rules={rules[CreateReviewFormFields.PRODUCT_ID]}>
              <Input />
            </RegularFormItem>
          </ReviewDetailsBlock>
          <ReviewDetailsBlock title={t('title_date_and_time')}>
            <RegularFormItem name={CreateReviewFormFields.CREATED_AT} rules={rules[CreateReviewFormFields.CREATED_AT]}>
              <DatePicker showTime />
            </RegularFormItem>
          </ReviewDetailsBlock>
          <ReviewDetailsBlock title={t('title_user_name')}>
            <RegularFormItem name={CreateReviewFormFields.USER_NAME} rules={rules[CreateReviewFormFields.USER_NAME]}>
              <Input />
            </RegularFormItem>
          </ReviewDetailsBlock>
          <ReviewDetailsBlock title={t('title_comment')}>
            <WideFormItem name={CreateReviewFormFields.REVIEW_TEXT} rules={rules[CreateReviewFormFields.REVIEW_TEXT]}>
              <TextArea autoSize={{ minRows: 4 }} />
            </WideFormItem>
          </ReviewDetailsBlock>
          <ReviewDetailsBlock title={t('title_status')}>
            <RegularFormItem name={CreateReviewFormFields.STATUS} rules={rules[CreateReviewFormFields.STATUS]}>
              <Select options={statusOptions} />
            </RegularFormItem>
          </ReviewDetailsBlock>
          <ReviewDetailsBlock title={t('title_overall_rating')}>
            <Rate disabled value={overallRating} />
          </ReviewDetailsBlock>
          <ReviewDetailsBlock title={t('title_rating_by_parameter')}>
            <SubRateOuter>
              <SubRateContainer>
                <p>{t('text_easy_to_use')}:</p>
                <FormItem
                  name={CreateReviewFormFields.EASE_OF_USE_RATING}
                  rules={rules[CreateReviewFormFields.EASE_OF_USE_RATING]}
                >
                  <Rate />
                </FormItem>
              </SubRateContainer>
              <SubRateContainer>
                <p>{t('text_durability')}:</p>
                <FormItem
                  name={CreateReviewFormFields.DURABILITY_RATING}
                  rules={rules[CreateReviewFormFields.DURABILITY_RATING]}
                >
                  <Rate />
                </FormItem>
              </SubRateContainer>
              <SubRateContainer>
                <p>{t('text_value_for_money')}:</p>
                <FormItem
                  name={CreateReviewFormFields.VALUE_FOR_MONEY_RATING}
                  rules={rules[CreateReviewFormFields.VALUE_FOR_MONEY_RATING]}
                >
                  <Rate />
                </FormItem>
              </SubRateContainer>
            </SubRateOuter>
          </ReviewDetailsBlock>
        </Form>
      </Modal>
    </>
  );
};
