// core
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';

// components
import { Form, useForm } from '@/components';

// store
import { selectCurrentPage, updatePage } from '@/modules/Pages/feature';

// shared
import { useAppDispatch } from '@/store';
import { useScrollToErrorField } from '../../hooks/useScrollToErrorField';
import { prepareFormDataToTransfer } from '../../utils/prepareFormFieldsToTransfer';
import { getInitialValues } from './utils';

interface Props {
  children: React.ReactNode;
  setActiveTab: (key: string) => void;
  activeTab: string;
}

export const UpdatePageFormProvider: FC<Props> = ({ children, activeTab, setActiveTab }) => {
  const [form] = useForm();
  const currentPage = useSelector(selectCurrentPage);
  const dispatch = useAppDispatch();
  const { scrollToErrorField } = useScrollToErrorField(form, activeTab, setActiveTab);

  useEffect(() => {
    form.setFieldsValue(getInitialValues(currentPage));
    form.resetFields();
  }, [currentPage, form]);

  const handleFinish = async (values: any) => {
    if (!currentPage) {
      return;
    }
    const data = prepareFormDataToTransfer(currentPage, values);

    await dispatch(
      updatePage({
        pageId: currentPage.id,
        data,
      }),
    );
  };

  return (
    <Form
      form={form}
      initialValues={getInitialValues(currentPage)}
      onFinishFailed={scrollToErrorField}
      onFinish={handleFinish}
    >
      {children}
    </Form>
  );
};
