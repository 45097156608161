// Globals
import { InfoCircleOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Form, FormItem } from '@/components';
import { Input, Select } from '@/ui';

// Helpers
import { CreateChannelFormFields, getChannelsOptions, TCreateChannelFormValues } from '../../helpers';
import { useChannelFormRules } from '../../hooks';

// Models
import { ChannelType, IChannel } from '@/models/channel.model';
import { ChannelTypeFilterValues } from '../../helpers/types';

type TChannelFormProps = {
  onSubmit: (values: TCreateChannelFormValues) => void;
  children?: React.ReactNode;
  initialValues?: Partial<TCreateChannelFormValues>;
  channels?: IChannel[] | null;
  isCreating?: boolean;
};

const ChannelForm = ({ onSubmit, children, initialValues, channels, isCreating = false }: TChannelFormProps) => {
  const rules = useChannelFormRules();
  const { t } = useTranslation(['common']);
  const options = useMemo(() => getChannelsOptions(channels), [channels]);

  return (
    <Form onFinish={onSubmit} initialValues={initialValues}>
      {isCreating && (
        <>
          <FormItem
            name={CreateChannelFormFields.NAME}
            label={t('channel_name')}
            rules={rules[CreateChannelFormFields.NAME]}
          >
            <Input />
          </FormItem>
          <FormItem
            name={CreateChannelFormFields.SLUG}
            label={t('channel_code')}
            rules={rules[CreateChannelFormFields.SLUG]}
            tooltip={{
              title: t('channel_code_help'),
              icon: <InfoCircleOutlined />,
            }}
          >
            <Input />
          </FormItem>
          <FormItem
            name={CreateChannelFormFields.TYPE}
            label={t('channel_type')}
            rules={rules[CreateChannelFormFields.TYPE]}
          >
            <Select
              defaultValue={ChannelType.PRODUCTS}
              options={ChannelTypeFilterValues.filter((type) => type.value !== '')}
            />
          </FormItem>
        </>
      )}
      <FormItem
        name={CreateChannelFormFields.SITE_URL}
        label={t('channel_url')}
        rules={rules[CreateChannelFormFields.SITE_URL]}
      >
        <Input />
      </FormItem>
      {channels && (
        <FormItem
          name={CreateChannelFormFields.COPY_CHANNEL_ID}
          label={t('channel_copy')}
          rules={rules[CreateChannelFormFields.COPY_CHANNEL_ID]}
        >
          <Select options={options} />
        </FormItem>
      )}
      {children}
    </Form>
  );
};

export default ChannelForm;
