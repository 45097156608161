import { Select, SelectProps } from 'antd';
import styled, { createGlobalStyle, css } from 'styled-components';
import { ChevronIcon } from '@/icons';
import { COLORS, pickThemeFontSize, pickThemeFontStyles } from '@/theme';

export const getStylesBySize = (size: SelectProps['size']) => {
  switch (size) {
    case 'small':
      return css`
        height: 2.75rem;
      `;
    case 'large':
      return css`
        height: 3.25rem;
      `;
    case 'middle':
      return css`
        height: 3rem;
      `;
    default:
      return css`
        height: 2rem;
      `;
  }
};

export const StyledWrapper = styled.div`
  position: relative;
`;

export const StyledPrefix = styled.span`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: ${pickThemeFontSize('20')};
  color: ${COLORS.WHITE};
  margin: 0;
  padding: 0 0.625rem 0 1rem;
  z-index: 1;
`;

export const SelectDropdownGlobalStyles = createGlobalStyle`
  .ant-select-dropdown {
    padding: 0.25rem 0;
    box-shadow: none;
    border: 1px solid ${COLORS.GRAY_4};

    .ant-select-item-option-selected {
      background: ${COLORS.GRAY_LIGHTER} !important;
      border-radius: 0 !important;
    }
  }
`;

const arrowStyles = css`
  .ant-select-arrow .anticon:not(.ant-select-suffix) {
    pointer-events: none;
  }

  &.ant-select-open .ant-select-arrow:not(.ant-select-arrow-loading) {
    transform: rotate(-180deg) translateY(50%);
  }

  .ant-select-arrow {
    transition: transform 0.24s ease;
    color: ${COLORS.TEXT_PRIMARY};
    width: 1.5rem;
    height: 1.5rem;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .ant-select-clear {
    width: 1.5rem;
    height: 1.5rem;
    font-size: ${pickThemeFontSize('16')};
    margin-top: -0.5rem;
  }
`;

const selectStyles = css`
  ${StyledPrefix} + &.ant-select {
    &-single:not(&-customize-input) .ant-select-selector,
    &-multiple:not(&-customize-input) .ant-select-selector {
      padding-left: 2.875rem;
    }

    &-multiple .ant-select-selection-placeholder,
    &-single .ant-select-selector .ant-select-selection-search {
      left: 2.875rem;
    }
  }

  & .ant-select-selection-overflow {
    gap: 0.375rem;
    padding: 0.375rem;
  }

  &.ant-select-disabled {
    .ant-select-selector {
      border: 1px dashed ${COLORS.GRAY_3} !important;
      color: ${COLORS.GRAY_2};
      background: ${COLORS.GRAY_1} !important;
    }
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  ${arrowStyles};
  ${selectStyles};
` as typeof Select;

export const StyledDownWrapper = styled.div`
  & .ant-select-item {
    ${pickThemeFontStyles('16', '24')};

    padding: 0 1rem;

    &-option {
      & .ant-select-item-option-content,
      & .ant-select-item-option-state {
        padding: 0.25rem 0;
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const StyledDownIcon = styled(ChevronIcon)`
  font-size: ${pickThemeFontSize('24')};
`;

export const StyledTag = styled.div<{
  $isHighlighted?: boolean;
}>`
  ${pickThemeFontStyles('14', '16', 'SEMI_BOLD')};
  text-align: start;
  background: ${COLORS.GRAY_4};
  padding: 0.25rem 0.75rem;
  border-radius: 3rem;
  height: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid ${COLORS.GRAY_4};

  & button {
    display: flex;
  }

  & .anticon {
    font-size: ${pickThemeFontSize('16')};
    color: ${COLORS.GRAY_DARK};
    cursor: pointer;
  }

  &:focus-within {
    border: 1px solid;
    box-sizing: border-box;
  }

  ${({ $isHighlighted }) =>
    $isHighlighted &&
    css`
      background: ${COLORS.GRAY_LIGHTER};
    `}
`;
