const checkIfBinary = (data: unknown) => data instanceof Blob || data instanceof File;

const convertModelToFormData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model: any,
  form: FormData | null = null,
  namespace = '',
  withPlainObject = false,
): FormData => {
  const formData = form || new FormData();

  Object.keys(model).forEach((propertyName) => {
    if (!Object.prototype.hasOwnProperty.call(model, propertyName)) return;

    const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;

    if (!model[propertyName] && model[propertyName] === undefined) {
      return;
    }

    if (model[propertyName] instanceof Date) {
      formData.append(formKey, model[propertyName].toISOString());
      return;
    }

    if (model[propertyName] instanceof Array) {
      model[propertyName].forEach((element: unknown) => {
        const tempFormKey = `${formKey}`;

        if (element instanceof Object && !checkIfBinary(element)) {
          convertModelToFormData(element, formData, tempFormKey);
        } else if (checkIfBinary(element)) {
          formData.append(tempFormKey, element as Blob);
        } else {
          formData.append(tempFormKey, String(element));
        }
      });

      return;
    }

    if (model[propertyName] instanceof Object && !checkIfBinary(model[propertyName])) {
      if (withPlainObject) {
        formData.append(formKey, JSON.stringify(model[propertyName]));
        return;
      }
      convertModelToFormData(model[propertyName], formData, formKey);
      return;
    }

    formData.append(formKey, model[propertyName]);
  });

  return formData;
};

export default convertModelToFormData;
