import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { ReviewsState, SLICE_NAME } from './models';

const settingsSelector = (state: RootState): ReviewsState => state[SLICE_NAME];

export const selectReviews = createSelector(settingsSelector, (state: ReviewsState) => state.reviews);

export const selectReviewsTotal = createSelector(settingsSelector, (state: ReviewsState) => state.total);

export const selectReviewsPageSize = createSelector(settingsSelector, (state: ReviewsState) => state.pageSize);

export const selectReviewsStatusFilter = createSelector(settingsSelector, (state: ReviewsState) => state.statusFilter);

export const selectReviewsPage = createSelector(settingsSelector, (state: ReviewsState) => state.page);

export const selectReviewsIsLoading = createSelector(settingsSelector, (state: ReviewsState) => state.isLoading);

export const selectReviewsError = createSelector(settingsSelector, (state: ReviewsState) => state.error);

export const selectSelectedReviewId = createSelector(settingsSelector, (state: ReviewsState) => state.selectedReviewId);
