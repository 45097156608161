import { PaginationProps as AntPaginationProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { ReactNode, useCallback } from 'react';
import { StyledItem, StyledPagination } from './PaginationStyles';

type PaginationProps = AntPaginationProps;

const Pagination = ({ onChange, ...props }: PaginationProps) => {
  const { t } = useTranslation(['common']);
  const pageSize = props.pageSize ?? props.defaultPageSize ?? 10;
  const lastPage = Math.ceil((props.total ?? 0) / pageSize) || 1;

  const itemRender = useCallback(
    (_page: number, type: 'jump-next' | 'jump-prev' | 'next' | 'page' | 'prev', element: ReactNode) => {
      if (type === 'prev' || type === 'next') {
        const isPrev = type === 'prev';
        const label = t(isPrev ? 'first' : 'last');
        return (
          <StyledItem
            $isPrev={isPrev}
            onClick={(e) => {
              e.stopPropagation();
              onChange?.(isPrev ? 1 : lastPage, pageSize);
            }}
          >
            {label}
          </StyledItem>
        );
      }
      return element;
    },
    [t, onChange, pageSize, lastPage],
  );

  return (
    <StyledPagination
      itemRender={itemRender}
      locale={{
        next_page: t('last'),
        prev_page: t('first'),
      }}
      showSizeChanger={false}
      onChange={onChange}
      size="small"
      {...props}
    />
  );
};

export default Pagination;
