import { IShippingPrice } from '@/models/shippingPrice.model';
import { renderPrice } from '@/utils';
import { TableColumnsType } from 'antd';
import { TFunction } from 'i18next';
import { Button } from '@/ui';
import { StyledButton, StyledForm, StyledFormItem, StyledInput, StyledWrapper } from '../components/Styled';
import { ShippingPriceFormFields } from './types';

export const getStatesColumn = (
  t: TFunction,
  isLoading: boolean,
  selectedPrice?: IShippingPrice | null,
  onSelect?: (record: IShippingPrice) => void,
  onSave?: (values: { price: string }) => void,
): TableColumnsType<IShippingPrice> => [
  {
    title: t('country'),
    dataIndex: 'countryId',
    key: 'countryId',
    render: (countryId: string) => countryId,
    width: '20%',
  },
  {
    title: t('state'),
    dataIndex: 'name',
    key: 'stateId',
    render: (stateName: string) => stateName,
  },
  {
    title: t('price'),
    dataIndex: 'price',
    key: 'price',
    width: '20%',
    render: (price: number, record) =>
      selectedPrice?.id === record.id ? (
        <StyledForm onFinish={onSave} initialValues={{ [ShippingPriceFormFields.PRICE]: price }}>
          <StyledFormItem name={ShippingPriceFormFields.PRICE}>
            <StyledInput min={0} prefix="$" size="small" />
          </StyledFormItem>
          <StyledWrapper>
            <Button htmlType="submit" size="small" loading={isLoading}>
              {t('save')}
            </Button>
          </StyledWrapper>
        </StyledForm>
      ) : (
        <StyledButton onClick={() => onSelect?.(record)}>{renderPrice(price)}</StyledButton>
      ),
  },
];
