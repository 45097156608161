import { checkProductExistsById } from '@/modules/Products/feature';
import { useAppDispatch } from '@/store';
import { useCallback } from 'react';

export const useCheckProductExists = () => {
  const dispatch = useAppDispatch();

  const checkProductExist = useCallback(
    async (productId: string) => {
      const result = await dispatch(checkProductExistsById(productId));

      return result.payload;
    },
    [dispatch],
  );

  return {
    checkProductExist,
  };
};
