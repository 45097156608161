import {
  getRedirectsEndpoints,
  ICreateRedirectDTO,
  ICreateRedirectResponse,
  IDeleteRedirectDTO,
  IDeleteRedirectResponse,
  IGetRedirectResponse,
  IGetRedirectsDTO,
  IRedirectsImportDTO,
  IRedirectsImportResponse,
  IUpdateRedirectDTO,
  IUpdateRedirectResponse,
  staticRedirectsEndpoints,
} from '@/models/redirect.model';
import { ApiResponse, convertModelToFormData, httpClient } from '@/utils';

class RedirectsAPI {
  static getRedirects(params: IGetRedirectsDTO): ApiResponse<IGetRedirectResponse> {
    const { GET_REDIRECTS } = staticRedirectsEndpoints;
    return httpClient.get<IGetRedirectResponse>(GET_REDIRECTS, {
      params,
    });
  }

  static createRedirect(data: ICreateRedirectDTO): ApiResponse<ICreateRedirectResponse> {
    const { CREATE_REDIRECT } = staticRedirectsEndpoints;
    return httpClient.post<ICreateRedirectResponse>(CREATE_REDIRECT, data);
  }

  static updateRedirect({ id, ...data }: IUpdateRedirectDTO): ApiResponse<IUpdateRedirectResponse> {
    const { UPDATE_REDIRECT } = getRedirectsEndpoints({ id });
    return httpClient.patch<IUpdateRedirectResponse>(UPDATE_REDIRECT, data);
  }

  static deleteRedirect({ id }: IDeleteRedirectDTO): ApiResponse<IDeleteRedirectResponse> {
    const { DELETE_REDIRECT } = getRedirectsEndpoints({ id });
    return httpClient.delete<IDeleteRedirectResponse>(DELETE_REDIRECT);
  }

  static importRedirects(data: IRedirectsImportDTO): ApiResponse<IRedirectsImportResponse> {
    const { IMPORT_REDIRECTS } = staticRedirectsEndpoints;
    const formData = convertModelToFormData(data, new FormData(), '', true);
    return httpClient.post<IRedirectsImportResponse>(IMPORT_REDIRECTS, formData);
  }
}

export default RedirectsAPI;
