// Globals
import { Trans, useTranslation } from 'react-i18next';

// Components
import { ChannelIcon, ContactIcon, CopyrightIcon, HashIcon, LabelIcon, PhoneIcon, UserIcon } from '@/icons';
import { SettingBlock } from '../../components';
import { StyledEmail, StyledList } from './GeneralSettingsListStyles';

// Models
import { IGeneralSettings } from '@/models/settings.model';

type IGeneralSettingsListProps = IGeneralSettings;

const GeneralSettingsList = ({
  adminEmails,
  copyright,
  googleTagManagement,
  phones,
  smtp,
  channel,
  popularProductIds,
  contactInfo,
}: IGeneralSettingsListProps) => {
  const { t } = useTranslation(['common']);
  return (
    <>
      <SettingBlock title={t('title_google_tag_management')} icon={<LabelIcon />}>
        {googleTagManagement}
      </SettingBlock>
      <SettingBlock title={t('title_channel')} icon={<ChannelIcon />}>
        {channel?.name ?? channel?.slug}
      </SettingBlock>
      <SettingBlock title={t('title_admin_email')} icon={<UserIcon />}>
        <StyledList>
          {adminEmails.map((email) => (
            <StyledEmail key={email}>{email}</StyledEmail>
          ))}
        </StyledList>
      </SettingBlock>
      <SettingBlock title={t('title_smtp')} icon={<HashIcon />}>
        <div>{t('text_smtp_port', { port: smtp?.port })}</div>
        <div>{t('text_smtp_host', { host: smtp?.host })}</div>
        <Trans
          i18nKey="text_smtp_username"
          t={t}
          components={{
            link: <a href={`mailto:${smtp.host}`}>{smtp?.auth?.user}</a>,
          }}
        />
        <div>{t('text_smtp_password', { pass: smtp?.auth?.pass })}</div>
      </SettingBlock>
      <SettingBlock title={t('title_copyright')} icon={<CopyrightIcon />}>
        {copyright}
      </SettingBlock>
      <SettingBlock title={t('title_popular_products')} icon={<UserIcon />}>
        <StyledList>
          {popularProductIds.map((id) => (
            <StyledEmail key={id}>{id}</StyledEmail>
          ))}
        </StyledList>
      </SettingBlock>
      <SettingBlock title={t('title_phones')} icon={<PhoneIcon />}>
        <div>{t('text_phones_footer', { phones: phones.footer ?? '---' })}</div>
        <div>{t('text_phones_contact', { phones: phones.contactUsPage ?? '---' })}</div>
      </SettingBlock>
      <SettingBlock title={t('title_contact_info')} icon={<ContactIcon />}>
        <div>{t('text_address', { address: contactInfo?.address })}</div>
        <div>{t('text_working_hours', { workingHours: contactInfo?.workingHours })}</div>
        <div>{t('text_contact_email', { email: contactInfo?.email })}</div>
      </SettingBlock>
    </>
  );
};

export default GeneralSettingsList;
