import { useWatchForm } from '@/components';
import { FormInstance } from 'antd';
import { CreateReviewFormFields } from '../typedefs';

export const useOverallRating = (form: FormInstance): number => {
  const easeOfUseRating = useWatchForm(CreateReviewFormFields.EASE_OF_USE_RATING, form);
  const durabilityRating = useWatchForm(CreateReviewFormFields.DURABILITY_RATING, form);
  const valueForMoneyRating = useWatchForm(CreateReviewFormFields.VALUE_FOR_MONEY_RATING, form);

  return parseFloat(((easeOfUseRating + durabilityRating + valueForMoneyRating) / 3).toFixed(1));
};
