import { IApiError } from '@/models/apiError.model';
import { IPaginationResponse } from '@/models/pagination.model';
import { IVariable } from '@/models/variable.model';

export const SLICE_NAME = 'variables';

export interface VariablesState {
  variables: IVariable[] | null;
  pagination: IPaginationResponse | null;
  search: string | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: VariablesState = {
  variables: null,
  pagination: null,
  isLoading: false,
  search: '',
  error: null,
};
