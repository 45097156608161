import { RedirectType } from '../typedefs';

export enum RedirectFormTypeModalViews {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
}

export enum RedirectsFormFields {
  SOURCE = 'link',
  TARGET = 'title',
  CODE = 'description',
}

export enum RedirectsImportFormFields {
  FILE = 'file',
}

export type TCreateRedirectFormValues = {
  [RedirectsFormFields.SOURCE]: string;
  [RedirectsFormFields.TARGET]: string;
  [RedirectsFormFields.CODE]: RedirectType;
};

export type TUpdateRedirectFormValues = {
  [RedirectsFormFields.SOURCE]: string;
  [RedirectsFormFields.TARGET]: string;
  [RedirectsFormFields.CODE]: RedirectType;
};

export type TRedirectsImportFormValues = {
  file: File;
};

export enum RedirectFormType {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  IMPORT = 'import',
}
