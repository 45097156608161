import { COLORS, pickThemeFontSize, pickThemeFontStyles } from '@/theme';
import { Button, ButtonProps } from 'antd';
import styled, { css } from 'styled-components';

const getColorStyles = (color: string | undefined) => {
  if (!color) {
    return '';
  }

  return css`
    background-color: ${({ theme }) => color ?? theme.token?.colorPrimary};
    color: ${({ theme }) => theme.token?.colorPrimaryText};

    &:hover {
      background-color: ${color} !important;
    }

    &:disabled {
      background-color: ${color} !important;
      opacity: 0.8;

      &:hover {
        filter: none;
      }
    }
  `;
};

const getStylesByType = (type: ButtonProps['type']) => {
  switch (type) {
    case 'primary':
      return css<{
        danger?: boolean;
      }>`
        background-color: ${({ theme, danger }) => !danger && theme.token?.colorPrimary};
        color: ${({ theme, danger }) => !danger && theme.token?.colorPrimaryText};

        &:hover {
          background-color: ${({ theme, danger }) => !danger && theme.token?.colorPrimaryHover};
        }

        &:disabled {
          background-color: ${({ theme, danger }) => !danger && theme.token?.colorTextTertiary};
        }
      `;
    case 'dashed':
      return css`
        background-color: ${COLORS.WHITE};
        color: ${COLORS.GRAY_MAIN};
        border: none;
      `;
    case 'link':
      return css`
        border: 1px solid ${COLORS.WHITE};
        background-color: transparent;
        color: ${({ theme }) => theme.token?.colorPrimaryText};

        &:hover {
          background-color: ${COLORS.WHITE} !important;
          color: ${({ theme }) => theme.token?.colorPrimaryHover};
        }

        &:disabled {
          background-color: transparent !important;
          color: ${({ theme }) => theme.token?.colorTextTertiary};
          border-color: ${({ theme }) => theme.token?.colorTextTertiary};
        }
      `;
    case 'text':
      return css`
        padding: 0;
        color: inherit;
        background-color: transparent;
      `;
    case 'default':
    default:
      return css`
        border: 1px solid ${({ theme }) => theme.token?.colorPrimary};
        color: ${({ theme }) => theme.token?.colorPrimary};
        background-color: transparent;

        &:hover {
          color: ${({ theme }) => theme.token?.colorPrimaryText};
        }

        &:disabled {
          background-color: transparent;
          border: 1px solid ${COLORS.GRAY};
          color: ${COLORS.GRAY};
        }
      `;
  }
};

export const StyledButton = styled(Button)`
  &.ant-btn {
    ${pickThemeFontStyles('14', '18', 'BOLD')};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    padding: 0 1.5rem;

    & .anticon {
      ${pickThemeFontStyles('24', '24')};
      height: ${pickThemeFontSize('24')};
    }

    &:hover {
      filter: brightness(1.2);
    }

    ${(props) => getStylesByType(props.type)}
    ${(props) => getColorStyles(props.color)}
  }
`;
