import { ChannelStatus, IChannel } from '@/models/channel.model';
import { useEffect } from 'react';
import { useAppDispatch, useAppState } from '@/store';
import { changeChannel, selectChannel, selectChannels } from '../feature';

export const useGetDefaultChannel = () => {
  const channels = useAppState(selectChannels);
  const activeChannel = useAppState(selectChannel);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (activeChannel) return;
    const activeChannels = channels?.filter(({ status }) => status === ChannelStatus.ACTIVE);
    const defaultChannel = activeChannels?.at(0);
    try {
      const rawChannels = localStorage.getItem('channel');
      if (rawChannels) {
        const channel = JSON.parse(rawChannels);
        if (channel && activeChannels?.find(({ id }) => id === channel?.id)) {
          dispatch(changeChannel(channel));
        } else if (defaultChannel) {
          dispatch(changeChannel(defaultChannel));
          localStorage.setItem('channel', JSON.stringify(defaultChannel));
        }
      } else if (defaultChannel) {
        dispatch(changeChannel(defaultChannel));
        localStorage.setItem('channel', JSON.stringify(defaultChannel));
      }
    } catch {}
  }, [activeChannel, channels, dispatch]);

  const onChangeChannel = (channel: IChannel) => {
    dispatch(changeChannel(channel));
    try {
      localStorage.setItem('channel', JSON.stringify(channel));
    } catch {}
  };

  return { activeChannel, onChangeChannel };
};
