import { useAppDispatch, useAppState } from '@/store';
import { useEffect } from 'react';
import { getRobotsTxt, selectRobots, selectRobotsError, selectRobotsIsLoading } from '../feature';

const useGetRobots = ({ channel }: { channel?: string }) => {
  const isLoading = useAppState(selectRobotsIsLoading);
  const robots = useAppState(selectRobots);
  const error = useAppState(selectRobotsError);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoading || error || robots || !channel) return;
    dispatch(getRobotsTxt({ channel }));
  }, [channel, dispatch, error, isLoading, robots]);

  return {
    isLoading,
    robots,
    error,
  };
};

export default useGetRobots;
