import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthAPI } from '@/api';
import { formatApiError } from '@/utils';
import { IApiError } from '@/models/apiError.model';
import { IAuthResponse, ILoginDTO } from '@/models/auth.model';
import { SLICE_NAME } from './models';

export const loginUser = createAsyncThunk<IAuthResponse, ILoginDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/login`,
  async (data: ILoginDTO) => {
    const response = await AuthAPI.login(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
