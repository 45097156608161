export const environments = {
  DEV: 'development',
  PROD: 'production',
  STAGE: 'stage',
};

export const env = {
  API_URL: process.env.NEXT_PUBLIC_API_URL || '',
  BASE_API_URL: process.env.REACT_APP_API_URL || '',
  BASE_URL: process.env.REACT_APP_FRONT_END_URL || '',
  NODE_ENV: process.env.NODE_ENV || environments.PROD,
};
