import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AUTHORIZATION_TOKEN_STORAGE_KEY } from '@/constants/common';
import { appCookiesStorage, showApiErrors } from '@/utils';
import { loginUser } from './actionCreators';
import { SLICE_NAME, AuthState, initialState } from './models';

export const authSlice = createSlice({
  name: SLICE_NAME,
  initialState: () => {
    const accessToken = appCookiesStorage.getItem(AUTHORIZATION_TOKEN_STORAGE_KEY) ?? null;
    return {
      ...initialState,
      authToken: accessToken,
      isAuthorized: !!accessToken,
    };
  },
  reducers: {
    setIsAuthorized(state, action: PayloadAction<boolean>) {
      state.isAuthorized = action.payload;
    },
    resetAuthState() {
      return { ...initialState };
    },
    logOut() {
      appCookiesStorage.removeItem(AUTHORIZATION_TOKEN_STORAGE_KEY);
      localStorage.clear();
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state: AuthState, action) => {
        const { payload } = action;

        state.isLoading = false;
        state.isAuthorized = true;
        state.authToken = payload.accessToken;
      })
      .addCase(loginUser.pending, (state: AuthState) => {
        state.isLoading = true;
        state.error = null;
      })

      .addCase(loginUser.rejected, (state: AuthState, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        showApiErrors(error);
      });
  },
});

export const { setIsAuthorized, logOut, resetAuthState } = authSlice.actions;
