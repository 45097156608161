// Globals
import { Navigate } from 'react-router-dom';

// Store
import { useAppState } from '@/store';
import { selectIsAuthorized } from '../../feature';

// Components
import LogInContainer from '../LogInContainer/LogInContainer';
import { StyledFormWrapper, StyledPageWrapper } from './AuthPageContentStyles';

// Helpers
import { ROUTES } from '@/constants/routes';

const AuthPageContent = () => {
  const isAuthorized = useAppState(selectIsAuthorized);

  if (isAuthorized) {
    return <Navigate to={ROUTES.HOME} replace />;
  }

  return (
    <StyledPageWrapper>
      <StyledFormWrapper>
        <LogInContainer />
      </StyledFormWrapper>
    </StyledPageWrapper>
  );
};

export default AuthPageContent;
