export enum ModalViews {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
}

export enum MetatagsFormFields {
  LINK = 'link',
  TITLE = 'title',
  DESCRIPTION = 'description',
  KEYWORDS = 'keywords',
}

export type TCreateMetatagsTemplateFormValues = {
  [MetatagsFormFields.LINK]: string;
  [MetatagsFormFields.TITLE]: string;
  [MetatagsFormFields.DESCRIPTION]: string;
  [MetatagsFormFields.KEYWORDS]: string[];
};

export type TUpdateMetatagsTemplateFormValues = {
  [MetatagsFormFields.TITLE]: string;
  [MetatagsFormFields.DESCRIPTION]: string;
  [MetatagsFormFields.KEYWORDS]: string[];
};
