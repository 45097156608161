import { useGetRequiredRule } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { ShippingPriceFormFields } from '../helpers/types';

const useShippingPriceFormRules = () => {
  const { t } = useTranslation(['validations']);

  const requiredRule = useGetRequiredRule({ t });

  return {
    [ShippingPriceFormFields.PRICE]: [requiredRule],
    [ShippingPriceFormFields.STATES]: [requiredRule],
  };
};

export default useShippingPriceFormRules;
