import { styled } from 'styled-components';
import { COLORS } from '@/theme';

export const StyledEmail = styled.div`
  background-color: ${COLORS.GRAY_4};
  border-radius: 99em;
  padding-inline: 0.5rem;
  display: inline-block;
`;

export const StyledList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;
