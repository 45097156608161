import { useAppDispatch, useAppState } from '@/store';
import { useEffect } from 'react';
import { getPages } from '../feature';
import { selectPages, selectPagesError, selectPagesIsLoading } from '../feature/selectors';

export const useGetPages = (channelId?: number) => {
  const pages = useAppState(selectPages);
  const isLoading = useAppState(selectPagesIsLoading);
  const error = useAppState(selectPagesError);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!channelId) {
      return;
    }

    dispatch(
      getPages({
        channelId,
      }),
    );
  }, [channelId, dispatch]);

  return {
    pages,
    isLoading,
    error,
  };
};
