import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { COLORS, pickThemeFontStyles } from '@/theme';

export const StyledNavLink = styled(NavLink)`
  ${pickThemeFontStyles('14', '21', 'BOLD')};
  padding: 0;
  height: auto;

  &:disabled,
  &:disabled:hover {
    color: ${COLORS.BLACK};
  }
`;
