// Globals
import { useTranslation } from 'react-i18next';
import { CSSProperties, memo } from 'react';
import { Color } from 'antd/es/color-picker';

// Styles
import { StyledPaginationInfo } from './PaginationInfoStyles';

type PaginationInfoProps = {
  backgroundColor?: Color;
  textColor?: Color;
};

const PaginationInfo = ({ backgroundColor, textColor }: PaginationInfoProps) => {
  const { t } = useTranslation(['common']);

  const style = {
    color: textColor?.toHexString?.() || textColor,
    backgroundColor: backgroundColor?.toHexString?.() || backgroundColor,
  } as CSSProperties;

  return (
    <>
      <p>{t('text_element_preview')}</p>
      <StyledPaginationInfo style={style}>
        <div>
          {t('category_list', {
            start: 1,
            end: 9,
            total: 2000,
          })}
        </div>
      </StyledPaginationInfo>
    </>
  );
};

export default memo(PaginationInfo);
