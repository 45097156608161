import { AUTHORIZATION_TOKEN_STORAGE_KEY } from '@/constants/common';

export enum AuthForms {
  LOG_IN = 'login',
}

export enum AuthEndpoints {
  SIGN_IN = '/auth/sign-in',
}

// ============== DTO ==============

export interface IAuthorizedRequestDTO {
  [AUTHORIZATION_TOKEN_STORAGE_KEY]?: string;
}

export interface ILoginDTO {
  email: string;
  password: string;
}

// ============== Response ==============

export interface IAuthResponse {
  accessToken: string;
}
