// components
import Tabs from 'src/shared/ui/Tabs/Tabs';
import { UpdatePageFormProvider } from './components/UpdatePageFormProvider';
// types
import { UpdatePageTabKey } from './UpdatePage.typedefs';
// utils
import { UpdatePageModal } from '../UpdatePageModal';
import { useUpdatePageTabs } from './hooks/useUpdatePageTabsItems';

export const UpdatePage = () => {
  const { tabItems, activeTab, setActiveTab } = useUpdatePageTabs();

  return (
    <UpdatePageFormProvider activeTab={activeTab} setActiveTab={setActiveTab}>
      <UpdatePageModal>
        <Tabs
          defaultActiveKey={UpdatePageTabKey.CONTENT}
          activeKey={activeTab}
          items={tabItems}
          onChange={setActiveTab}
        />
      </UpdatePageModal>
    </UpdatePageFormProvider>
  );
};
