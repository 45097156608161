import { ButtonHTMLAttributes, ReactNode } from 'react';
import { StyledIconButton } from './PureIconButtonStyled';

interface IPureIconButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

const PureIconButton = ({ children, className, ...props }: IPureIconButton) => (
  <StyledIconButton {...props} className={`ui-pure-icon-button ${className || ''}`} type="button">
    {children}
  </StyledIconButton>
);

export default PureIconButton;
