import { Empty } from 'antd';
import styled from 'styled-components';

export const StyledEmptyDataThumbnail = styled(Empty)`
  ellipse {
    fill: #f5f5f5;
  }

  g > g {
    stroke: #d9d9d9;
  }

  path + path {
    fill: #fafafa;
  }
`;
