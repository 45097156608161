import { Button } from '@/ui';
import { getImageDimensions } from '@/utils';
import { Image, UploadProps } from 'antd';
import { memo, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledBlock,
  StyledFigure,
  StyledFigureHoverMask,
  StyledImageUpload,
  StyledInfo,
  StyledZoomIcon,
} from './ImageUploadStyles';
type IImageUploadProps = UploadProps & {
  value?: string | File;
  onChange?: (file: File | Blob, imageUrl: string) => void;
  accept?: string;
};
const ImageUpload = ({ onChange, value, accept = '.png .svg', ...props }: IImageUploadProps) => {
  const { t } = useTranslation(['common']);
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number } | null>(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (value) {
      if (typeof value === 'string') {
        getImageDimensions(value).then(setImageDimensions);
      } else {
        getImageDimensions(URL.createObjectURL(value)).then(setImageDimensions);
      }
    }
  }, [value]);
  const handleSelectFile = useCallback(
    async (image: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = async () => {
        const url = reader.result as string;
        onChange?.(image, url);
      };
      return false;
    },
    [onChange],
  );
  const handlePreview = async (e: SyntheticEvent) => {
    e.stopPropagation();
    if (!value) {
      return;
    }
    setPreviewImage(typeof value === 'string' ? value : URL.createObjectURL(value));
    setPreviewOpen(true);
  };
  return (
    <StyledBlock>
      <StyledInfo>
        {t('label_dimensions', {
          width: imageDimensions?.width || 0,
          height: imageDimensions?.height || 0,
        })}
      </StyledInfo>
      <StyledImageUpload beforeUpload={handleSelectFile} showUploadList={false} accept={accept} {...props}>
        {value && (
          <StyledFigure onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <img
              src={typeof value === 'string' ? value : URL.createObjectURL(value)}
              alt="preview"
              onClick={handlePreview}
              onKeyDown={handlePreview}
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
              role="button"
            />
            {isHovered && (
              <StyledFigureHoverMask>
                <StyledZoomIcon />
              </StyledFigureHoverMask>
            )}
          </StyledFigure>
        )}
        <Button>{t('btn_upload_new')}</Button>
      </StyledImageUpload>
      {previewImage && (
        <Image
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </StyledBlock>
  );
};
export default memo(ImageUpload);
