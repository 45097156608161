import { IApiError } from '@/models/apiError.model';
import { IMetaTags } from '@/models/metatags.model';
import { IPaginationResponse } from '@/models/pagination.model';

export const SLICE_NAME = 'metatags';

export interface MetatagsState {
  metatags: IMetaTags[] | null;
  pagination: IPaginationResponse | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: MetatagsState = {
  metatags: null,
  pagination: null,
  isLoading: false,
  error: null,
};
