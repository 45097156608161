// Globals
import { useTranslation } from 'react-i18next';

// Components
import FileUpload from '../FileUpload/FileUpload';
import { Form, FormItem } from '@/components';
import { Button } from '@/ui';
import { StyledButtons } from './RedirectsForm.styles';
import { StyledMessage } from '../../containers/RedirectsContainer/RedirectsConatiner.styles';

// Helpers
import { RedirectsImportFormFields, TRedirectsImportFormValues } from '../../helpers/types';
import useRedirectFormRules from '../../hooks/useRedirectFormRules';
import { ALLOWED_IMPORT_FILE_TYPES } from '../../helpers/constants';

type TProps = {
  onSubmit: (values: TRedirectsImportFormValues) => void;
  isLoading?: boolean;
};

const ImportRedirectsForm = ({ onSubmit, isLoading }: TProps) => {
  const rules = useRedirectFormRules();
  const { t } = useTranslation(['common']);

  return (
    <Form onFinish={onSubmit}>
      <StyledMessage>{t(`import_description`)}</StyledMessage>
      <FormItem
        name={RedirectsImportFormFields.FILE}
        label={t('label_import_file')}
        rules={rules[RedirectsImportFormFields.FILE]}
      >
        <FileUpload accept={ALLOWED_IMPORT_FILE_TYPES} />
      </FormItem>
      <StyledButtons>
        <Button type="default" htmlType="submit" loading={isLoading}>
          {t('btn_redirects_import_start')}
        </Button>
      </StyledButtons>
    </Form>
  );
};

export default ImportRedirectsForm;
