import { FormItem, IFormInstance } from '@/components';
import { ChannelIcon, CopyrightIcon, HashIcon, LabelIcon, PhoneIcon, UserIcon } from '@/icons';
import { Input, Select, TextArea } from '@/ui';
import { useTranslation } from 'react-i18next';
import { GeneralSettingsFormFields, TGeneralSettingsFormValues } from '../../helpers/types';
import { useGeneralSettingsFormRules } from '../../hooks';
import SettingBlock from '../SettingBlock/SettingBlock';
import { StyledForm } from './GeneralSettingsFormStyles';

type IGeneralSettingsFormProps = {
  form: IFormInstance;
  onFinish?: (values: Partial<TGeneralSettingsFormValues>) => void;
  initialValues?: Partial<TGeneralSettingsFormValues>;
};

const GeneralSettingsForm = ({ form, onFinish, initialValues }: IGeneralSettingsFormProps) => {
  const { t } = useTranslation(['common']);
  const rules = useGeneralSettingsFormRules();

  return (
    <StyledForm form={form} onFinish={onFinish} initialValues={initialValues}>
      <SettingBlock title={t('title_google_tag_management')} icon={<LabelIcon />}>
        <FormItem
          name={GeneralSettingsFormFields.GOOGLE_TAG_MANAGER}
          rules={rules[GeneralSettingsFormFields.GOOGLE_TAG_MANAGER]}
        >
          <TextArea autoSize={{ minRows: 4, maxRows: 4 }} />
        </FormItem>
      </SettingBlock>
      <SettingBlock title={t('title_channel')} icon={<ChannelIcon />}>
        <FormItem name={GeneralSettingsFormFields.CHANNEL}>
          <Input disabled />
        </FormItem>
      </SettingBlock>
      <SettingBlock title={t('title_admin_email')} icon={<UserIcon />}>
        <FormItem name={GeneralSettingsFormFields.ADMIN_EMAILS} rules={rules[GeneralSettingsFormFields.ADMIN_EMAILS]}>
          <Select dropdownStyle={{ display: 'none' }} mode="tags" suffixIcon={null} />
        </FormItem>
      </SettingBlock>
      <SettingBlock title={t('title_smtp')} icon={<HashIcon />}>
        <FormItem
          name={GeneralSettingsFormFields.SMTP_PORT}
          rules={rules[GeneralSettingsFormFields.SMTP_PORT]}
          label={t('label_smtp_port')}
        >
          <Input />
        </FormItem>
        <FormItem
          name={GeneralSettingsFormFields.SMTP_HOST}
          rules={rules[GeneralSettingsFormFields.SMTP_HOST]}
          label={t('label_smtp_host')}
        >
          <Input />
        </FormItem>
        <FormItem
          name={GeneralSettingsFormFields.SMTP_AUTH_USER}
          rules={rules[GeneralSettingsFormFields.SMTP_AUTH_USER]}
          label={t('label_smtp_username')}
        >
          <Input />
        </FormItem>
        <FormItem
          name={GeneralSettingsFormFields.SMTP_AUTH_PASS}
          rules={rules[GeneralSettingsFormFields.SMTP_AUTH_PASS]}
          label={t('label_smtp_password')}
        >
          <Input />
        </FormItem>
      </SettingBlock>
      <SettingBlock title={t('title_copyright')} icon={<CopyrightIcon />}>
        <FormItem name={GeneralSettingsFormFields.COPYRIGHT} rules={rules[GeneralSettingsFormFields.COPYRIGHT]}>
          <Input />
        </FormItem>
      </SettingBlock>
      <SettingBlock title={t('title_popular_products')} icon={<UserIcon />}>
        <FormItem
          name={GeneralSettingsFormFields.POPULAR_PRODUCTS}
          rules={rules[GeneralSettingsFormFields.POPULAR_PRODUCTS]}
        >
          <Select dropdownStyle={{ display: 'none' }} mode="tags" suffixIcon={null} maxCount={8} />
        </FormItem>
      </SettingBlock>
      <SettingBlock title={t('title_phones')} icon={<PhoneIcon />}>
        <FormItem
          name={GeneralSettingsFormFields.PHONES_FOOTER}
          rules={rules[GeneralSettingsFormFields.PHONES_FOOTER]}
          label={t('label_phones_footer')}
        >
          <Input />
        </FormItem>
        <FormItem
          name={GeneralSettingsFormFields.PHONES_CONTACT_US}
          rules={rules[GeneralSettingsFormFields.PHONES_CONTACT_US]}
          label={t('label_phones_contact')}
        >
          <Input />
        </FormItem>
      </SettingBlock>
      <SettingBlock title={t('title_contact_info')} icon={<PhoneIcon />}>
        <FormItem
          name={GeneralSettingsFormFields.CONTACT_INFO_ADDRESS}
          rules={rules[GeneralSettingsFormFields.CONTACT_INFO_ADDRESS]}
          label={t('label_contact_info_address')}
        >
          <TextArea rows={3} />
        </FormItem>
        <FormItem
          name={GeneralSettingsFormFields.CONTACT_INFO_EMAIL}
          rules={rules[GeneralSettingsFormFields.CONTACT_INFO_EMAIL]}
          label={t('label_contact_info_email')}
        >
          <Input />
        </FormItem>
        <FormItem
          name={GeneralSettingsFormFields.CONTACT_INFO_WORKING_HOURS}
          rules={rules[GeneralSettingsFormFields.CONTACT_INFO_WORKING_HOURS]}
          label={t('label_contact_info_working_hours')}
        >
          <Input />
        </FormItem>
      </SettingBlock>
    </StyledForm>
  );
};

export default GeneralSettingsForm;
