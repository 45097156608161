import { ICreateMetatagsDTO } from '@/models/metatags.model';
import { MetatagsFormFields, TCreateMetatagsTemplateFormValues } from '../helpers';

class CreateMetatagDTO implements ICreateMetatagsDTO {
  link: string;

  title: string;

  description: string;

  keywords?: string[];

  channelId: number;

  constructor(values: TCreateMetatagsTemplateFormValues & { channelId: number }) {
    this.link = decodeURIComponent(decodeURIComponent(values[MetatagsFormFields.LINK]));
    this.title = values[MetatagsFormFields.TITLE];
    this.description = values[MetatagsFormFields.DESCRIPTION];
    this.keywords = values[MetatagsFormFields.KEYWORDS];
    this.channelId = values.channelId;
  }
}

export default CreateMetatagDTO;
