export type HEXColor = string;

export const COLORS = {
  WHITE: '#ffffff',
  BLACK: '#000000',
  BLUE_MAIN: '#0073BB',
  BLUE_NASA: '#0B3D91',
  GRAY_MAIN: '#393939',
  GRAY_DARK: '#383838',
  GRAY: '#B0B0B0',
  GRAY_1: '#f2f2f2',
  GRAY_2: '#929292',
  GRAY_3: '#dbdbdb',
  GRAY_4: '#EEEEEE',
  GRAY_5: '#dcdcdc',
  GRAY_6: '#737373',
  GRAY_7: '#F8F8F8',
  GRAY_LIGHT: '#D3D3D3',
  GRAY_LIGHTER: '#e5e5e5',
  GRAY_LIGHTEST: '#E6E6E6',
  DIVIDER: '#D6D6D6',
  RED: '#EC2128',
  RED_NASA: '#FC3D21',
  ORANGE_AMAZON: '#ff9900',
  YELLOW: '#F6C16B',
  GREEN: '#1AAD91',
  GRAY_DARKEST: '#212121',
  PLACEHOLDER: '#777777',
  COLOR_SECONDARY: '#232323',
  BLUE_DARK: '#014773',
  STATUS_ERROR: '#EC2128',
  STATUS_SUCCESS: '#0E9A4E',
  STATUS_WARNING: '#F7A62E',
  RATING: '#F7C15F',
  TEXT_PRIMARY: '#1D1D1D',
  TEXT_SECONDARY: '#353535',
  TEXT_HINT: '#7E7E7E',
  TEXT_LIGHT: '#535353',
  COLOR_BORDER_SECONDARY: '#E9E9E9',
  COLOR_SEARCH: '#F9F9F9',
  HIGHLIGHT: '#f3e354',
};

export const FONT_WEIGHTS = {
  EXTRA_BOLD: 800,
  BOLD: 700,
  SEMI_BOLD: 600,
  NORMAL: 400,
};

export const FONT_SIZES = {
  '12': '12px',
  '14': '14px',
  '16': '16px',
  '18': '18px',
  '20': '20px',
  '21': '21px',
  '22': '22px',
  '24': '24px',
  '26': '26px',
  '27': '27px',
  '28': '28px',
  '30': '30px',
  '32': '32px',
  '36': '36px',
  '40': '40px',
  '43': '43.2px',
  '46': '46px',
  '48': '48px',
  '56': '56px',
};

export const MOBILE_LAYOUT_MAX_WIDTH = 1024;

const SCREEN_SIZES = {
  MOBILE_S: '320px',
  MOBILE: '375px',
  MOBILE_L: '425px',
  MOBILE_XL: '480px',
  TABLET: '834px',
  LAPTOP_S: '1025px',
  LAPTOP_M: '1366px',
  LAPTOP: '1440px',
  DESKTOP: '1920px',
  DESKTOP_L: '2560px',
};

export const DEVICES = {
  MOBILE_S: `(min-width: ${SCREEN_SIZES.MOBILE_S})`,
  MOBILE: `(min-width: ${SCREEN_SIZES.MOBILE})`,
  MOBILE_L: `(min-width: ${SCREEN_SIZES.MOBILE_L})`,
  MOBILE_XL: `(min-width: ${SCREEN_SIZES.MOBILE_XL})`,
  TABLET: `(min-width: ${SCREEN_SIZES.TABLET})`,
  LAPTOP_S: `(min-width: ${SCREEN_SIZES.LAPTOP_S})`,
  LAPTOP_M: `(min-width: ${SCREEN_SIZES.LAPTOP_M})`,
  LAPTOP: `(min-width: ${SCREEN_SIZES.LAPTOP})`,
  DESKTOP: `(min-width: ${SCREEN_SIZES.DESKTOP})`,
  DESKTOP_L: `(min-width: ${SCREEN_SIZES.DESKTOP_L})`,
};
