import {
  ShippingPriceEndpoints,
  IUpdateShippingPriceDTO,
  getShippingPriceEndpoints,
  IGetShippingPricesResponse,
  IUpdateShippingPriceResponse,
  IUpdateShippingPricesDTO,
  IUpdateShippingPricesResponse,
} from '@/models/shippingPrice.model';
import { ApiResponse, httpClient } from '@/utils';

class ShippingPriceAPI {
  static getShippingPrices(): ApiResponse<IGetShippingPricesResponse> {
    return httpClient.get<IGetShippingPricesResponse>(ShippingPriceEndpoints.ROOT);
  }

  static updateShippingPrice({ id, ...data }: IUpdateShippingPriceDTO): ApiResponse<IUpdateShippingPriceResponse> {
    const { UPDATE_SHIPPING_PRICE } = getShippingPriceEndpoints({ id });
    return httpClient.patch<IUpdateShippingPriceResponse>(UPDATE_SHIPPING_PRICE, data);
  }

  static updateShippingPrices(data: IUpdateShippingPricesDTO): ApiResponse<IUpdateShippingPricesResponse> {
    return httpClient.patch<IUpdateShippingPricesResponse>(ShippingPriceEndpoints.ROOT, data);
  }
}

export default ShippingPriceAPI;
