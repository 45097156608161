import { Empty } from 'antd';
import { StyledEmptyDataThumbnail } from './EmptyDataThumbnailStyles';

interface IEmptyDataThumbnail {
  className?: string;
  message?: JSX.Element | string;
}

const EmptyDataThumbnail = ({ message = 'No data', className }: IEmptyDataThumbnail) => (
  <StyledEmptyDataThumbnail className={className} description={message} image={Empty.PRESENTED_IMAGE_SIMPLE} />
);

export default EmptyDataThumbnail;
