import { IApiError } from '@/models/apiError.model';

export const SLICE_NAME = 'products';

export interface ProductsState {
  productExists: boolean | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: ProductsState = {
  productExists: null,
  isLoading: false,
  error: null,
};
