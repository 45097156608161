import {
  IGetPageDTO,
  IGetPageResponse,
  IGetPagesDTO,
  IGetPagesResponse,
  IUpdatePageDto,
  IUpdatePageResponse,
  IUpdatePageStatusResponse,
  PagesEndpoints,
} from '@/models/page.model';
import { PageStatus } from '@/modules/Pages/typedefs';
import { ApiResponse, convertModelToFormData, httpClient } from '@/utils';

class PagesAPI {
  static getPages(data: IGetPagesDTO): ApiResponse<IGetPagesResponse> {
    return httpClient.get<IGetPagesResponse>(PagesEndpoints.ROOT, {
      params: data,
    });
  }

  static getPage({ pageType, ...data }: IGetPageDTO): ApiResponse<IGetPageResponse> {
    return httpClient.get<IGetPageResponse>(`${PagesEndpoints.ROOT}/${pageType}`, {
      params: data,
    });
  }

  static updatePageStatus(pageId: string, status: PageStatus): ApiResponse<IUpdatePageStatusResponse> {
    return httpClient.patch<IUpdatePageStatusResponse>(`${PagesEndpoints.ROOT}/${pageId}/update-status`, { status });
  }

  static updatePage(pageId: string, data: IUpdatePageDto): ApiResponse<IUpdatePageResponse> {
    const { channelIds, ...rest } = data;
    const formData = convertModelToFormData(rest, new FormData(), '', true);
    return httpClient.patch<IUpdatePageResponse>(`${PagesEndpoints.ROOT}/${pageId}`, formData, {
      params: { channelIds },
    });
  }
}

export default PagesAPI;
