import { DatePickerProps } from 'antd';
import { StyledDatePicker } from './DatePickerStyles';

type IDatePickerProps = DatePickerProps;

const DatePicker = (props: IDatePickerProps) => {
  return <StyledDatePicker {...props} />;
};

export default DatePicker;
