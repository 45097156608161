export enum RobotsEndpoints {
  ROOT = '/robots-txt',
}

export const staticRobotsEndpoints = {
  GET_ROBOTS: RobotsEndpoints.ROOT,
  UPDATE_ROBOTS: RobotsEndpoints.ROOT,
};

export interface IRobotsTxt {
  content: string;
}

// ============== DTO ==============

export type IGetRobotsDTO = { channel: string };

export type IUpdateRobotsDTO = Partial<IRobotsTxt> & {
  channelId: number;
};

// ============== Response ==============

export type IUpdateRobotsResponse = IRobotsTxt;

export type IGetRobotsResponse = IRobotsTxt;
