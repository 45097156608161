import { InputProps } from 'antd';
import { StyledPasswordInput } from './InputStyles';

export type IInputProps = InputProps;

const PasswordInput = ({ prefix, value, ...props }: IInputProps): JSX.Element => (
  <StyledPasswordInput {...props} prefix={prefix} value={value} />
);

export default PasswordInput;
