// Globals
import { ReactNode } from 'react';

// Components
import { StyledContent, StyledLayoutWrapper } from './MainLayoutContentStyles';

interface IMainLayout {
  children: ReactNode;
}

const MainLayoutContent = ({ children }: IMainLayout) => (
  <StyledLayoutWrapper>
    <StyledContent>{children}</StyledContent>
  </StyledLayoutWrapper>
);

export default MainLayoutContent;
