import { TFunction } from 'i18next';
import { ExampleRoutes } from './types';

export interface IFooterLink {
  label: string;
  route: string;
}

interface ILinksBlock {
  links: IFooterLink[];
  title: string;
}

const getOrderingBlock = (translate: TFunction): ILinksBlock => ({
  title: translate('nav_ordering'),
  links: [
    {
      label: translate('nav_price_protection'),
      route: ExampleRoutes.PRICE_PROTECTION,
    },
    {
      label: translate('nav_shipping_and_returns'),
      route: ExampleRoutes.SHIPPING_AND_RETURNS,
    },
    { label: translate('nav_affiliates'), route: ExampleRoutes.AFFILIATES },
  ],
});

const getContactsLinks = (translate: TFunction) => ({
  title: translate('nav_contacts'),
  links: [
    { label: translate('nav_about_us'), route: ExampleRoutes.ABOUT_US },
    { label: translate('nav_contact_us'), route: ExampleRoutes.CONTACT_US },
    { label: translate('nav_investor_info'), route: ExampleRoutes.INVESTOR_INFO },
    { label: translate('nav_careers'), route: ExampleRoutes.CAREERS },
  ],
});

const getSecurityLinks = (translate: TFunction) => ({
  title: translate('nav_security'),
  links: [
    {
      label: translate('nav_guaranteed_privacy_security'),
      route: ExampleRoutes.GUARANTEED_PRIVACY_SECURITY,
    },
    { label: translate('nav_privacy_policy'), route: ExampleRoutes.PRIVACY_POLICY },
    {
      label: translate('nav_terms_and_conditions'),
      route: ExampleRoutes.TERMS_AND_CONDITIONS,
    },
    { label: translate('nav_prop_65'), route: ExampleRoutes.PROP_65 },
  ],
});

export const getFooterLinks = (translate: TFunction): ILinksBlock[] => [
  getOrderingBlock(translate),
  getContactsLinks(translate),
  getSecurityLinks(translate),
];
