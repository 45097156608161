// Globals
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { ReactNode, useCallback, useMemo, useState } from 'react';

// Models
import { ReviewStatus } from '@/models/review.model';

// Modules
import { Header } from '@/modules/Layout';

// Store
import { useAppDispatch } from '@/store';
import { setPage, setSelectedReviewId, setStatusFilter, updateReview } from '../../feature';

// Components
import { Modal, ModalSize, useForm } from '@/components';
import { Select, Pagination, Table } from '@/ui';
import { CreateReviewForm } from '../../components/CreateReviewForm';
import { EditReviewForm } from '../../components/EditReviewForm';
import { ReviewDetails } from '../../components/ReviewDetails';
import { ReviewDetailsModalFooter } from '../../components/ReviewDetailsModalFooter';
import { PaginationWrapper, StyledReviewsContainerInner } from './ReviewsContainerStyles';

// helpers
import { UpdateReviewDTO } from '../../dtos';
import { statusFilterOptions } from '../../constants/statusFilterOptions';
import { useGetReviews } from '../../hooks';
import { TEditReviewFormValues } from '../../typedefs';
import { reviewsTableColumns } from '../../utils/tableColumns';

export const ReviewsContainer = () => {
  const { t } = useTranslation(['common']);
  const { reviews, isLoading, total, page, pageSize, statusFilter, selectedReviewId } = useGetReviews();
  const dispatch = useAppDispatch();
  const [modalOpened, setModalOpened] = useState(false);
  const [reviewInEditMode, setReviewInEditMode] = useState<boolean>(false);
  const [form] = useForm<TEditReviewFormValues>();

  const selectedReview = useMemo(
    () => reviews.find((review) => review.id === selectedReviewId) || null,
    [reviews, selectedReviewId],
  );

  const submitForm = useCallback(async () => {
    try {
      await form.validateFields();
      form.submit();
    } catch (error) {
      console.error(error);
    }
  }, [form]);

  const toggleEditMode = useCallback(() => {
    if (!reviewInEditMode) {
      form.setFieldsValue({
        ...selectedReview,
        createdAt: dayjs(selectedReview?.createdAt),
      });
    }
    setReviewInEditMode((prev) => !prev);
  }, [form, reviewInEditMode, selectedReview]);

  const openModal = useCallback(
    ({ newSelectedReviewId }: { newSelectedReviewId: string }) => {
      dispatch(setSelectedReviewId(newSelectedReviewId));
      setModalOpened(true);
    },
    [dispatch],
  );

  const closeModal = useCallback(() => {
    setModalOpened(false);
    setReviewInEditMode(false);
    dispatch(setSelectedReviewId(null));
  }, [dispatch]);

  const setCurrentPage = useCallback(
    (currentPage: number) => {
      dispatch(setPage(currentPage));
    },
    [dispatch],
  );

  const setCurrentStatusFilter = useCallback(
    (status: ReviewStatus) => {
      dispatch(setStatusFilter(status));
    },
    [dispatch],
  );

  const handleUpdateReview = useCallback(
    async (values: TEditReviewFormValues) => {
      if (!selectedReview) return;
      const dto = new UpdateReviewDTO({ ...values, reviewId: selectedReview.id });
      try {
        await dispatch(updateReview(dto)).unwrap();
        setReviewInEditMode(false);
        form.resetFields();
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, form, selectedReview],
  );

  const components = {
    header: {
      wrapper: (props: { children: ReactNode }) => (
        <thead className="ant-table-thead">
          {props.children}
          <tr className="sub-header">
            <td className="ant-table-cell" colSpan={reviewsTableColumns.length - 1} />
            <td className="ant-table-cell select">
              <Select
                options={statusFilterOptions}
                placeholder="Filter by status"
                value={statusFilter}
                size="small"
                onChange={setCurrentStatusFilter}
              />
            </td>
          </tr>
        </thead>
      ),
    },
  };

  return (
    <>
      <Header title={t('menu_reviews')}>
        <CreateReviewForm />
      </Header>
      <StyledReviewsContainerInner>
        <Table
          columns={reviewsTableColumns}
          rowKey={'id'}
          dataSource={reviews}
          loading={isLoading}
          pagination={false}
          components={components}
          virtual
          rowHoverable
          onRow={(record) => {
            return {
              onClick: () => {
                openModal({ newSelectedReviewId: record.id });
              },
            };
          }}
        />
        <PaginationWrapper>
          <Pagination disabled={isLoading} total={total} current={page} pageSize={pageSize} onChange={setCurrentPage} />
        </PaginationWrapper>
        <Modal
          visible={modalOpened}
          onCancel={closeModal}
          size={ModalSize.HUGE}
          withFooter
          centered
          title={t('title_review_for', { productId: selectedReview?.productId })}
          footer={() => (
            <ReviewDetailsModalFooter
              reviewId={selectedReview?.id}
              reviewStatus={selectedReview?.status}
              closeModal={closeModal}
              toggleEditMode={toggleEditMode}
              reviewInEditMode={reviewInEditMode}
              submitForm={submitForm}
            />
          )}
        >
          {selectedReview && reviewInEditMode ? (
            <EditReviewForm review={selectedReview} form={form} onFinish={handleUpdateReview} />
          ) : (
            <ReviewDetails review={selectedReview} />
          )}
        </Modal>
      </StyledReviewsContainerInner>
    </>
  );
};
