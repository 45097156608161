import { Menu } from 'antd';
import { styled } from 'styled-components';

export const StyledMenu = styled(Menu)`
  background-color: inherit;
  border: none !important;

  .ant-menu-submenu-title {
    margin: 0 !important;
  }

  .ant-menu-submenu-selected .ant-menu-item-selected.ant-menu-item-only-child a {
    color: ${({ theme }) => theme.token?.colorPrimaryText};
  }

  .ant-menu-submenu-title {
    background-color: inherit !important;
  }

  .ant-menu-item {
    margin: 0 !important;
    border-radius: 0;
    width: 100%;

    a {
      color: inherit;
    }
  }

  .ant-menu-item-only-child.ant-menu-item-selected {
    background-color: transparent;
    border-inline-end: 3px solid ${({ theme }) => theme.token?.colorPrimary} !important;
    a {
      color: inherit !important;
      font-weight: 600;
    }
  }

  .ant-menu-submenu {
    border-radius: 0;

    .ant-menu-item {
      &.ant-menu-item-selected {
        background-color: ${({ theme }) => theme.token?.colorPrimary} !important;
        color: ${({ theme }) => theme.token?.colorPrimaryText};

        a {
          color: inherit;
        }
      }
    }

    &.ant-menu-submenu-selected {
      .ant-menu-title-content span {
        font-weight: 600;
      }
    }

    &.ant-menu-submenu-active {
      background-color: inherit;
    }
  }

  .ant-menu-submenu.ant-menu-submenu-open {
    border-inline-end: 3px solid ${({ theme }) => theme.token?.colorPrimary} !important;
  }
`;
