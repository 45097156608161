import { IApiError } from '@/models/apiError.model';
import { IRobotsTxt } from '@/models/robots.model';

export const SLICE_NAME = 'robots';

export interface RobotsState {
  robots: IRobotsTxt | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: RobotsState = {
  robots: null,
  isLoading: false,
  error: null,
};
