// Globals
import { useTranslation } from 'react-i18next';

// Components
import { Form, FormItem } from '@/components';
import { CheckIcon } from '@/icons';
import { Button, Input } from '@/ui';
import { StyledButtons } from './VariableForm.styles';

// Helpers

import {
  TCreateVariableFormValues,
  TUpdateVariableFormValues,
  VariableFormFields,
  VariableFormType,
} from '../../helpers/types';
import useVariableFormRules from '../../hooks/useVariableFormRules';

type TVariableFormFormProps = {
  onSubmit: (values: TCreateVariableFormValues) => void;
  initialValues?: Partial<TUpdateVariableFormValues>;
  formType: VariableFormType;
  isLoading?: boolean;
};

const VariableForm = ({ onSubmit, initialValues, isLoading, formType }: TVariableFormFormProps) => {
  const rules = useVariableFormRules();
  const { t } = useTranslation(['common']);

  return (
    <Form onFinish={onSubmit} initialValues={initialValues}>
      <FormItem name={VariableFormFields.CODE} label={t('label_code')} rules={rules[VariableFormFields.CODE]}>
        <Input disabled={formType === VariableFormType.UPDATE} placeholder={t('placeholder_variable_code')} />
      </FormItem>
      <FormItem
        name={VariableFormFields.DEFAULT_VALUE}
        label={t('label_varaible_default_value')}
        rules={rules[VariableFormFields.DEFAULT_VALUE]}
      >
        <Input placeholder={t('placeholder_variable_default_value')} />
      </FormItem>
      <FormItem
        name={VariableFormFields.VALUE}
        label={t('label_variable_value')}
        rules={rules[VariableFormFields.VALUE]}
      >
        <Input placeholder={t('placeholder_variable_value')} />
      </FormItem>
      <StyledButtons>
        <Button
          type="primary"
          htmlType="submit"
          icon={formType === VariableFormType.UPDATE && <CheckIcon />}
          loading={isLoading}
        >
          {t(formType === VariableFormType.CREATE ? 'btn_create_variable' : 'btn_save')}
        </Button>
      </StyledButtons>
    </Form>
  );
};

export default VariableForm;
