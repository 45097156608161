// Globals
import { useTranslation } from 'react-i18next';
import { ComponentProps, CSSProperties, memo } from 'react';
import { Color } from 'antd/es/color-picker';

// Components
import { Button } from '@/ui';
import { CartIcon } from '@/icons';
import { ExampleButtonLabel } from './ExampleButtonStyles';

export type TExampleButtonProps = ComponentProps<typeof Button> & {
  textColor?: Color;
  backgroundColor?: Color;
};

const ExampleButton = ({ textColor, backgroundColor, ...props }: TExampleButtonProps) => {
  const { t } = useTranslation('common');
  const style = {
    color: textColor?.toHexString?.() || textColor,
    backgroundColor: backgroundColor?.toHexString?.() || backgroundColor,
  } as CSSProperties;

  return (
    <div>
      <ExampleButtonLabel>{t('text_element_preview')}</ExampleButtonLabel>
      <Button type="primary" icon={<CartIcon />} style={style} {...props}>
        {t('cart')}
      </Button>
    </div>
  );
};

export default memo(ExampleButton);
