// Globals
import { TableColumnsType } from 'antd';
import { TFunction } from 'i18next';

// Components
import { Button } from '@/ui';
import { StyledButtons, StyledTruncate } from '../components';

// Models
import { PenIcon, TrashIcon } from '@/icons';
import { IVariable, IVariableUsage } from '@/models/variable.model';

export const variablesColumns = (
  t: TFunction,
  onDelete?: (record: IVariable) => void,
  onEdit?: (record: IVariable) => void,
): TableColumnsType<IVariable> => [
  {
    dataIndex: 'code',
    key: 'code',
    width: 200,
    title: t('column_code'),
    render: (code: string) => <StyledTruncate $width="200">{code}</StyledTruncate>,
  },
  {
    dataIndex: 'defaultValue',
    key: 'defaultValue',
    width: 200,
    title: t('column_default_value'),
    render: (source: string) => <StyledTruncate $width="200">{source}</StyledTruncate>,
  },
  {
    dataIndex: 'value',
    key: 'value',
    width: 200,
    title: t('column_value'),
    render: (target: string) => <StyledTruncate $width="200">{target}</StyledTruncate>,
  },
  {
    dataIndex: 'usages',
    key: 'usages',
    width: 50,
    title: t('column_total_usages'),
    render: (usages: IVariableUsage[]) => (
      <StyledTruncate $width="50">{usages.reduce((acc, el) => acc + el.usages, 0)}</StyledTruncate>
    ),
  },
  {
    title: t('column_action'),
    key: 'action',
    width: 150,
    render: (_, record) => (
      <StyledButtons>
        <Button
          icon={<PenIcon />}
          type="primary"
          size="small"
          block
          onClick={() => {
            onEdit?.(record);
          }}
        />
        <Button
          type="primary"
          size="small"
          icon={<TrashIcon />}
          danger
          block
          onClick={() => {
            onDelete?.(record);
          }}
        />
      </StyledButtons>
    ),
  },
];
