// Globals
import { useTranslation } from 'react-i18next';

// Components
import { Button } from '@/ui';
import { Result } from 'antd';
import { isRouteErrorResponse, useNavigate, useRouteError } from 'react-router-dom';
import { StyledWrapper } from './AppErrorStyles';
import { formatErrorMessage } from '@/utils';

const AppError = () => {
  const { t } = useTranslation(['common']);
  const error = useRouteError();
  const navigate = useNavigate();

  const handleHome = () => {
    navigate('/');
  };

  const { statusText, status } = isRouteErrorResponse(error)
    ? error
    : {
        statusText: formatErrorMessage(error),
        status: 500,
      };

  return (
    <Result status={'500'} title={status} subTitle={statusText}>
      <StyledWrapper>
        <Button type={'primary'} onClick={handleHome}>
          {t('error_app_go_home')}
        </Button>
      </StyledWrapper>
    </Result>
  );
};

export default AppError;
