import { httpClient, ApiResponse, convertModelToFormData } from '@/utils';
import {
  staticSettingsEndpoints,
  IGetSettingsResponse,
  IGetSettingsDTO,
  getSettingsEndpoints,
  IUpdateUISettingsDTO,
  IUpdateGeneralSettingsDTO,
  IUpdateGeneralSettingsResponse,
  IGetUISettingsDTO,
  IGetUISettingsResponse,
  IBulkUpdateGeneralSettingsDTO,
  IBulkUpdateGeneralSettingsResponse,
  IUpdateUISettingsResponse,
} from '@/models/settings.model';

class SettingsAPI {
  static getSettings(params: IGetSettingsDTO): ApiResponse<IGetSettingsResponse> {
    const { GET_SETTINGS } = staticSettingsEndpoints;
    return httpClient.get<IGetSettingsResponse>(GET_SETTINGS, {
      params,
    });
  }

  static getUISettings(params: IGetUISettingsDTO): ApiResponse<IGetUISettingsResponse> {
    const { GET_UI_SETTINGS } = staticSettingsEndpoints;
    return httpClient.get<IGetUISettingsResponse>(GET_UI_SETTINGS, {
      params,
    });
  }

  static updateGeneralSettings({
    uuid,
    ...data
  }: IUpdateGeneralSettingsDTO): ApiResponse<IUpdateGeneralSettingsResponse> {
    const { UPDATE_SETTINGS } = getSettingsEndpoints({ id: uuid });
    return httpClient.patch<IGetSettingsResponse>(UPDATE_SETTINGS, data);
  }

  static bulkUpdateSettings(data: IBulkUpdateGeneralSettingsDTO): ApiResponse<IBulkUpdateGeneralSettingsResponse> {
    const { UPDATE_SETTINGS } = staticSettingsEndpoints;
    return httpClient.patch<IBulkUpdateGeneralSettingsResponse>(UPDATE_SETTINGS, data);
  }

  static updateUISettings(data: IUpdateUISettingsDTO): ApiResponse<IUpdateUISettingsResponse> {
    const { UPDATE_UI_SETTINGS } = staticSettingsEndpoints;
    return httpClient.patch<IUpdateUISettingsResponse>(
      UPDATE_UI_SETTINGS,
      convertModelToFormData(data, new FormData(), '', true),
    );
  }
}

export default SettingsAPI;
