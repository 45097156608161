import { styled } from 'styled-components';
import { LayoutHeader } from '@/ui';
import { COLORS, pickThemeFontStyles } from '@/theme';

export const StyledHeader = styled(LayoutHeader)`
  background-color: ${COLORS.COLOR_SEARCH};
  border-bottom: 1px solid ${COLORS.GRAY_LIGHTEST};
  padding: 2rem 2.5rem;
  height: auto;
`;

export const StyledSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledSuptitle = styled.p`
  ${pickThemeFontStyles('14', '16')};
  color: ${COLORS.TEXT_HINT};
  margin: 0;
  text-transform: capitalize;
`;

export const StyledTitle = styled.h1`
  ${pickThemeFontStyles('26', '32', 'BOLD')};
  margin: 0;
  text-transform: uppercase;
`;
