import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { SLICE_NAME, VariablesState } from './models';

const variablesSelector = (state: RootState): VariablesState => state[SLICE_NAME];

export const selectVariables = createSelector(variablesSelector, (state: VariablesState) => state.variables);

export const selectVariablesIsLoading = createSelector(variablesSelector, (state: VariablesState) => state.isLoading);

export const selectVariablesError = createSelector(variablesSelector, (state: VariablesState) => state.error);

export const selectVariablesPagination = createSelector(variablesSelector, (state: VariablesState) => state.pagination);

export const selectVariablesSearch = createSelector(variablesSelector, (state: VariablesState) => state.search);
