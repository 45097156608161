import { PayloadAction, createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { showApiErrors } from '@/utils';
import { getChannels, updateChannel, createChannel } from './actionCreators';
import { SLICE_NAME, ChannelsState, initialState } from './models';
import { IChannel } from '@/models/channel.model';
import { ResponseStatusCodes } from '@/constants/common';

export const channelSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    changeChannel(state, action: PayloadAction<IChannel>) {
      state.channel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChannels.fulfilled, (state: ChannelsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.channels = payload;
      })
      .addCase(createChannel.fulfilled, (state: ChannelsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.channels = [payload, ...(state.channels ?? [])];
      })
      .addCase(updateChannel.fulfilled, (state: ChannelsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.channels = (state.channels ?? []).map((channel) => (channel.id === payload.id ? payload : channel));
      })
      .addMatcher(isPending(getChannels, createChannel, updateChannel), (state: ChannelsState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected(getChannels, createChannel, updateChannel), (state: ChannelsState, action) => {
        const { error } = action;
        const skipError =
          error.status === ResponseStatusCodes.NOT_AUTHORIZED || error.status === ResponseStatusCodes.SOMETHING_WRONG;

        state.isLoading = false;
        state.error = skipError ? null : error;
        showApiErrors(error);
      });
  },
});

export const { changeChannel } = channelSlice.actions;
