import { FormItem } from '@/components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { styled } from 'styled-components';

export const StyledContainer = styled.div<{
  $background?: string;
}>`
  padding: 1.5rem 2rem;
  background-color: ${({ $background }) => $background ?? COLORS.WHITE};
`;

export const StyledBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  margin-top: 1rem;

  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
`;

export const StyledFormItem = styled(FormItem)<{
  $background?: string;
}>`
  margin: 0;

  figure {
    background-color: ${({ $background }) => $background ?? 'transparent'};
  }
`;

export const StyledColorFormItem = styled(FormItem)<{
  $description?: string;
}>`
  margin-bottom: 1rem;

  .ant-form-item-label::after {
    content: '${({ $description }) => $description ?? ''}';
    display: block;
    ${pickThemeFontStyles('12', '26')};
  }

  label {
    ${pickThemeFontStyles('14', '16', 'BOLD')};
  }
`;

export const StyledDescription = styled.span`
  ${pickThemeFontStyles('12', '16')};
`;

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('26', '32', 'BOLD')};
`;

export const StyledColors = styled.div``;
