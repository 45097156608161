import { IUpdateShippingPriceDTO } from '@/models/shippingPrice.model';

class UpdateShippingPricesDTO implements IUpdateShippingPriceDTO {
  id: string;

  price: number;

  constructor({ id, price }: { id: string; price: string }) {
    this.id = id;
    this.price = Number.parseFloat(price);
  }
}

export default UpdateShippingPricesDTO;
