import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 2rem;
`;

export const StyledTopBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  gap: 2rem;
`;
