import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { RedirectsState, SLICE_NAME } from './models';

const redirectsSelector = (state: RootState): RedirectsState => state[SLICE_NAME];

export const selectRedirects = createSelector(redirectsSelector, (state: RedirectsState) => state.redirects);

export const selectRedirectsIsLoading = createSelector(redirectsSelector, (state: RedirectsState) => state.isLoading);

export const selectRedirectsError = createSelector(redirectsSelector, (state: RedirectsState) => state.error);

export const selectRedirectsPagination = createSelector(redirectsSelector, (state: RedirectsState) => state.pagination);

export const selectImportResult = createSelector(redirectsSelector, (state: RedirectsState) => state.importResult);
