import { ReviewStatus } from '@/models/review.model';
import { Dayjs } from 'dayjs';

export enum EditReviewFormFields {
  CREATED_AT = 'createdAt',
  REVIEW_TEXT = 'reviewText',
  EASE_OF_USE_RATING = 'easeOfUseRating',
  DURABILITY_RATING = 'durabilityRating',
  VALUE_FOR_MONEY_RATING = 'valueForMoneyRating',
  USER_NAME = 'userName',
}

export type TEditReviewFormValues = {
  [EditReviewFormFields.CREATED_AT]: Dayjs;
  [EditReviewFormFields.REVIEW_TEXT]: string;
  [EditReviewFormFields.EASE_OF_USE_RATING]: number;
  [EditReviewFormFields.DURABILITY_RATING]: number;
  [EditReviewFormFields.VALUE_FOR_MONEY_RATING]: number;
  [EditReviewFormFields.USER_NAME]?: string;
};

export enum CreateReviewFormFields {
  CREATED_AT = 'createdAt',
  CHANNEL_ID = 'channelId',
  REVIEW_TEXT = 'reviewText',
  EASE_OF_USE_RATING = 'easeOfUseRating',
  DURABILITY_RATING = 'durabilityRating',
  VALUE_FOR_MONEY_RATING = 'valueForMoneyRating',
  STATUS = 'status',
  USER_NAME = 'userName',
  PRODUCT_ID = 'productId',
}

export type TCreateReviewFormValues = {
  [CreateReviewFormFields.CREATED_AT]: Dayjs;
  [CreateReviewFormFields.CHANNEL_ID]: number;
  [CreateReviewFormFields.REVIEW_TEXT]: string;
  [CreateReviewFormFields.EASE_OF_USE_RATING]: number;
  [CreateReviewFormFields.DURABILITY_RATING]: number;
  [CreateReviewFormFields.VALUE_FOR_MONEY_RATING]: number;
  [CreateReviewFormFields.STATUS]: ReviewStatus;
  [CreateReviewFormFields.USER_NAME]: string;
  [CreateReviewFormFields.PRODUCT_ID]: string;
};
