import { StyledTable } from 'src/shared/ui/Table/TableStyles';
import styled from 'styled-components';

export const StyledPagesContainerInner = styled.div`
  padding: 32px;
`;

export const PagesStyledTable = styled(StyledTable)`
  tbody .ant-table-cell {
    cursor: pointer;
  }
`;
