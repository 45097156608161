import { IPaginationDTO, IPaginationResponse } from '@/models/pagination.model';

export enum VariableEndpoint {
  ROOT = '/variables',
}

export const staticVariablesEndpoints = {
  GET_VARIABLES: VariableEndpoint.ROOT,
  CREATE_VARIABLE: VariableEndpoint.ROOT,
};

export const getVariablesEndpoints = ({ id }: { id: string }) => ({
  UPDATE_VARIABLE: `${VariableEndpoint.ROOT}/${id}`,
  DELETE_VARIABLE: `${VariableEndpoint.ROOT}/${id}`,
});

// ============== Model ==============

export interface IVariableUsage {
  channelName: string;
  pageName: string;
  usages: number;
}

export interface IVariable {
  id: string;
  defaultValue: string;
  value: string;
  code: string;
  createdAt: Date;
  updatedAt: Date;
  usages: IVariableUsage[];
}

// ============== DTO ==============

export type IGetVariablesDTO = IPaginationDTO & {
  search?: string;
  channelId?: number;
};

export type IUpdateVariableDTO = Partial<IVariable> & {
  id: string;
};

export type IDeleteVariableDTO = {
  id: string;
};

export type ICreateVariableDTO = Omit<IVariable, 'id' | 'createdAt' | 'updatedAt' | 'usages'>;

// ============== Response ==============

export type ICreateVariableResponse = IVariable;

export type IUpdateVariableResponse = IVariable;

export type IDeleteVariableResponse = IVariable;

export type IGetIVariablesResponse = { variables: IVariable[] } & IPaginationResponse;
