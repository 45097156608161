import { ReactNode } from 'react';
import { Layout as AntdLayout } from 'antd';

interface ILayoutComponent {
  children?: ReactNode;
  className?: string;
}

const LayoutHeader = (props: ILayoutComponent) => <AntdLayout.Header {...props} />;

export default LayoutHeader;
