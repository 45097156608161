import { UpdatePageContentBannerPart } from './components/UpdatePageContentBannerPart';
import { UpdatePageContentFormPart } from './components/UpdatePageContentFormPart/UpdatePageContentFormPart';
import { UpdatePageGalleryFormPart } from './components/UpdatePageGalleryFormPart';
import { UpdatePageSeoFormPart } from './components/UpdatePageSeoFormPart';
import { UpdatePageTabKey } from './UpdatePage.typedefs';

export const UPDATE_PAGE_TAB_LABEL_MAP = {
  [UpdatePageTabKey.CONTENT]: 'Content',
  [UpdatePageTabKey.GALLERY]: 'Gallery',
  [UpdatePageTabKey.BANNER]: 'Banner',
  [UpdatePageTabKey.SEO]: 'SEO',
};

export const UPDATE_PAGE_TABS_MAP = {
  [UpdatePageTabKey.CONTENT]: {
    key: UpdatePageTabKey.CONTENT,
    tabKey: UpdatePageTabKey.CONTENT,
    label: UPDATE_PAGE_TAB_LABEL_MAP[UpdatePageTabKey.CONTENT],
    children: <UpdatePageContentFormPart />,
    forceRender: true,
  },
  [UpdatePageTabKey.BANNER]: {
    key: UpdatePageTabKey.BANNER,
    tabKey: UpdatePageTabKey.BANNER,
    label: UPDATE_PAGE_TAB_LABEL_MAP[UpdatePageTabKey.BANNER],
    children: <UpdatePageContentBannerPart />,
    forceRender: true,
  },
  [UpdatePageTabKey.GALLERY]: {
    key: UpdatePageTabKey.GALLERY,
    tabKey: UpdatePageTabKey.GALLERY,
    label: UPDATE_PAGE_TAB_LABEL_MAP[UpdatePageTabKey.GALLERY],
    children: <UpdatePageGalleryFormPart />,
    forceRender: true,
  },
  [UpdatePageTabKey.SEO]: {
    key: UpdatePageTabKey.SEO,
    tabKey: UpdatePageTabKey.SEO,
    label: UPDATE_PAGE_TAB_LABEL_MAP[UpdatePageTabKey.SEO],
    children: <UpdatePageSeoFormPart />,
    forceRender: true,
  },
};
