import { OpenInNewIcon } from '@/icons';
import { Button } from '@/ui';
import { useTranslation } from 'react-i18next';
export const OpenVariablesModuleButton = () => {
  const { t } = useTranslation(['common']);

  const handleOpenVariablesModule = () => {
    window.open(`/variables`, '_blank');
  };

  return (
    <Button type="primary" onClick={handleOpenVariablesModule} iconPosition="end" icon={<OpenInNewIcon />}>
      {t('variables.openModule')}
    </Button>
  );
};
