import { ReviewStatus } from '@/models/review.model';
import { FC } from 'react';
import Tag from 'src/shared/ui/Tag/Tag';

interface Props {
  status: ReviewStatus.APPROVED | ReviewStatus.PENDING;
}

export const ReviewStatusTag: FC<Props> = ({ status }) => {
  const text = status[0] + status.slice(1).toLowerCase();
  const colors = {
    [ReviewStatus.PENDING]: 'blue',
    [ReviewStatus.APPROVED]: 'green',
  };

  return <Tag color={colors[status]}>{text}</Tag>;
};
