// Global
import { FC } from 'react';

interface IProductLinkProps {
  siteUrl: string;
  productId: string;
}

export const ProductLink: FC<IProductLinkProps> = ({ siteUrl, productId }) => (
  <a
    onClick={(e) => e.stopPropagation()} // Prevent click event propagation to avoid modal opening
    href={`${siteUrl}/product/${productId}`}
    target="_blank"
    rel="noreferrer"
  >
    {siteUrl}/product/{productId}
  </a>
);
