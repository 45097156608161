import { styled } from 'styled-components';

export const StyledSearchWrapper = styled.div`
  display: flex;
  gap: 20%;
  align-items: center;
  flex-wrap: wrap;

  input {
    flex-grow: 1;
    width: auto !important;
  }
`;

export const StyledId = styled.div`
  width: 100%;
  text-align: center;
`;

export const StyledButtons = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;

export const StyledTruncate = styled.div<{
  $width?: string;
}>`
  white-space: nowrap;
  overflow: hidden;
  max-width: ${({ $width }) => $width || '10rem'};
  text-overflow: ellipsis;
`;
