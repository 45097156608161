// Global
import { IBasePage } from '@/models/page.model';
import { renderDate } from '@/utils';
import { TableColumnsType } from 'antd';
import { StatusSwitch } from '../components/StatusSwitch/StatusSwitch';
import { PageStatus } from '../typedefs';

export const pagesTableColumns: TableColumnsType = [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Last update',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: 180,
    render: (date: string) => renderDate(date, 'D MMM, hh:mm a'),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: PageStatus, record: IBasePage) => <StatusSwitch pageId={record.id} status={status} />,
  },
];
