import { combineSlices } from '@reduxjs/toolkit';
import { robotsSlice } from '@/modules/RobotsTxt';
import { authSlice } from '@/modules/Auth';
import { channelSlice } from '@/modules/Channels';
import { metatagsSlice } from '@/modules/Metatags';
import { pagesSlice } from '@/modules/Pages/feature/slice';
import { productSlice } from '@/modules/Products';
import { redirectsSlice } from '@/modules/Redirects/feature';
import { reviewsSlice } from '@/modules/Reviews';
import { settingsSlice } from '@/modules/Settings';
import { shippingPricesSlice } from '@/modules/ShippingPrices';
import { variablesSlice } from '@/modules/Variables/feature';

export const rootReducer = combineSlices(
  authSlice,
  channelSlice,
  settingsSlice,
  reviewsSlice,
  productSlice,
  shippingPricesSlice,
  pagesSlice,
  metatagsSlice,
  robotsSlice,
  redirectsSlice,
  variablesSlice,
);
