import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { ChannelsState, SLICE_NAME } from './models';
import { ChannelStatus } from '@/models/channel.model';

const channelsSelector = (state: RootState): ChannelsState => state[SLICE_NAME];

export const selectChannels = createSelector(channelsSelector, (state: ChannelsState) => state.channels);

export const selectChannelsIsLoading = createSelector(channelsSelector, (state: ChannelsState) => state.isLoading);

export const selectChannelsError = createSelector(channelsSelector, (state: ChannelsState) => state.error);

export const selectChannel = createSelector(channelsSelector, (state: ChannelsState) => state.channel);

export const selectActiveChannels = createSelector(channelsSelector, (state: ChannelsState) =>
  (state.channels ?? []).filter((channel) => channel.status === ChannelStatus.ACTIVE),
);
