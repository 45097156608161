import { useGetRequiredRule, useLengthValidationRule } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { RedirectsFormFields, RedirectsImportFormFields } from '../helpers/types';

const useRedirectFormRules = () => {
  const { t } = useTranslation(['validations']);

  const requiredRule = useGetRequiredRule({ t });
  const lengthRule = useLengthValidationRule({ t, max: 1500 });

  return {
    [RedirectsFormFields.CODE]: [requiredRule],
    [RedirectsFormFields.SOURCE]: [requiredRule, lengthRule],
    [RedirectsFormFields.TARGET]: [requiredRule, lengthRule],
    [RedirectsImportFormFields.FILE]: [requiredRule],
  };
};

export default useRedirectFormRules;
