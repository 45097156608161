// Globals
import { useCallback } from 'react';

// Store
import { useAppDispatch, useAppState } from '@/store';

// Components
import { LogInForm } from '../../components';

// Helpers
import { TLogInFormValues } from '../../helpers/types';
import LoginDTO from '../../dtos/LoginDTO';
import { loginUser } from '../../feature/actionCreators';
import { selectAuthIsLoading } from '../../feature/selectors';
import { appCookiesStorage } from '@/utils';
import { AUTHORIZATION_TOKEN_EXPIRES, AUTHORIZATION_TOKEN_STORAGE_KEY } from '@/constants/common';

const LogInContainer = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppState(selectAuthIsLoading);

  const handleSubmit = useCallback(
    async (values: TLogInFormValues) => {
      const loginDto = new LoginDTO(values);
      const payload = await dispatch(loginUser(loginDto)).unwrap();
      appCookiesStorage.setItem(AUTHORIZATION_TOKEN_STORAGE_KEY, payload.accessToken, {
        expires: AUTHORIZATION_TOKEN_EXPIRES,
      });
    },
    [dispatch],
  );

  return <LogInForm handleSubmit={handleSubmit} isLoading={isLoading} />;
};

export default LogInContainer;
