export enum UpdatePageTabKey {
  CONTENT = 'content',
  GALLERY = 'gallery',
  BANNER = 'banner',
  SEO = 'seo',
}

export enum UpdatePageInput {
  TITLE = 'title',
  DESCRIPTION = 'description',
  KEYWORDS = 'keywords',
  TEXT = 'text',
  BLOCKS = 'blocks',
  IMAGE = 'image',
  IMAGE_NAME = 'imageName',
  LINK = 'link',
  ID = 'id',
}
