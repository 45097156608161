// Global
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Form, FormItem, IFormInstance } from '@/components';
import { Input, Rate, TextArea } from '@/ui';
import DatePicker from 'src/shared/ui/DatePicker/DatePicker';
import { ProductLink } from '../ProductLink';
import { ReviewDetailsBlock, SubRateContainer, SubRateOuter } from '../ReviewDetailsBlock';
import { WideFormItem } from './EditReviewFormStyles';

// State
import { IReview } from '@/models/review.model';

// Hooks
import { useEditReviewFormRules } from '../../hooks/useEditReviewFormRules';
import { useOverallRating } from '../../hooks/useOverallRating';

// Typedefs
import { EditReviewFormFields, TEditReviewFormValues } from '../../typedefs';

// Utils
import dayjs from 'dayjs';

interface IEditReviewFormProps {
  review: IReview;
  form: IFormInstance;
  onFinish?: (values: TEditReviewFormValues) => void;
}

export const EditReviewForm: FC<IEditReviewFormProps> = ({ review, onFinish, form }) => {
  const { t } = useTranslation(['common']);
  const overallRating = useOverallRating(form);
  const initialValues: TEditReviewFormValues = {
    [EditReviewFormFields.CREATED_AT]: dayjs(review.createdAt),
    [EditReviewFormFields.REVIEW_TEXT]: review.reviewText,
    [EditReviewFormFields.EASE_OF_USE_RATING]: review.easeOfUseRating,
    [EditReviewFormFields.DURABILITY_RATING]: review.durabilityRating,
    [EditReviewFormFields.VALUE_FOR_MONEY_RATING]: review.valueForMoneyRating,
    [EditReviewFormFields.USER_NAME]: review.userName,
  };

  const rules = useEditReviewFormRules();

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      <ReviewDetailsBlock title={t('title_user_name')}>
        <FormItem name={EditReviewFormFields.USER_NAME} rules={rules[EditReviewFormFields.USER_NAME]}>
          <Input />
        </FormItem>
      </ReviewDetailsBlock>
      <ReviewDetailsBlock title={t('title_date_and_time')}>
        <FormItem name={EditReviewFormFields.CREATED_AT} rules={rules[EditReviewFormFields.CREATED_AT]}>
          <DatePicker showTime />
        </FormItem>
      </ReviewDetailsBlock>
      <ReviewDetailsBlock title={t('title_product_link')}>
        <ProductLink siteUrl={review.channel.siteUrl} productId={review.productId} />
      </ReviewDetailsBlock>
      <ReviewDetailsBlock title={t('title_product_id')}>
        <p>{review.productId}</p>
      </ReviewDetailsBlock>
      <ReviewDetailsBlock title={t('title_comment')}>
        <WideFormItem name={EditReviewFormFields.REVIEW_TEXT} rules={rules[EditReviewFormFields.REVIEW_TEXT]}>
          <TextArea autoSize={{ minRows: 4 }} />
        </WideFormItem>
      </ReviewDetailsBlock>
      <ReviewDetailsBlock title={t('title_overall_rating')}>
        <Rate disabled value={overallRating} />
      </ReviewDetailsBlock>
      <ReviewDetailsBlock title={t('title_rating_by_parameter')}>
        <SubRateOuter>
          <SubRateContainer>
            <p>{t('text_easy_to_use')}:</p>
            <FormItem
              name={EditReviewFormFields.EASE_OF_USE_RATING}
              rules={rules[EditReviewFormFields.EASE_OF_USE_RATING]}
            >
              <Rate />
            </FormItem>
          </SubRateContainer>
          <SubRateContainer>
            <p>{t('text_durability')}:</p>
            <FormItem
              name={EditReviewFormFields.DURABILITY_RATING}
              rules={rules[EditReviewFormFields.DURABILITY_RATING]}
            >
              <Rate />
            </FormItem>
          </SubRateContainer>
          <SubRateContainer>
            <p>{t('text_value_for_money')}:</p>
            <FormItem
              name={EditReviewFormFields.VALUE_FOR_MONEY_RATING}
              rules={rules[EditReviewFormFields.VALUE_FOR_MONEY_RATING]}
            >
              <Rate />
            </FormItem>
          </SubRateContainer>
        </SubRateOuter>
      </ReviewDetailsBlock>
    </Form>
  );
};
