import { LoadingOutlined } from '@ant-design/icons';
import { Spin, SpinProps } from 'antd';

const antIcon = <LoadingOutlined spin />;

type ISpinnerProps = SpinProps;

const Spinner = (props: ISpinnerProps) => {
  return <Spin indicator={antIcon} {...props} />;
};

export default Spinner;
