import { COLORS, pickThemeFontStyles } from '@/theme';
import styled from 'styled-components';
import Input from '../Input/Input';

export const sizes: Record<'default' | 'large' | 'small', number> = {
  small: 20,
  default: 30,
  large: 44,
};

const getSize = (size: 'default' | 'large' | 'small' | number) => {
  const sizePx = typeof size === 'string' ? sizes[size] : size;
  return `${sizePx}px`;
};

export const StyledSearch = styled(Input)<{
  $size: 'default' | 'large' | 'small' | number;
}>`
  &.ant-input-search {
    width: 100%;
    border-radius: 0.25rem 0 0 0.25rem;
    ${pickThemeFontStyles('14', '20')};

    & .ant-input-wrapper {
      & input {
        background: ${COLORS.COLOR_SEARCH};
        color: ${({ theme }) => theme.token?.colorTextSecondary};
        border-radius: 0.25rem 0 0 0.25rem;
        border: 1px solid ${COLORS.GRAY_LIGHTEST};
        box-shadow: none;
        height: ${({ $size }) => getSize($size)};
        ${pickThemeFontStyles('14', '28', 'NORMAL')};

        &::placeholder {
          color: ${({ theme }) => theme.token?.colorTextTertiary};
          ${pickThemeFontStyles('14', '28', 'NORMAL')};
        }
      }
    }

    & .ant-input-group-addon {
      & .ant-input-search-button {
        height: ${({ $size }) => getSize($size)};
        width: ${({ $size }) => getSize($size)};
        border-radius: 0 0.25rem 0.25rem 0 !important;
        background-color: ${COLORS.GRAY_DARKEST};
        box-shadow: none;
        border: none;
      }

      & svg {
        fill: ${COLORS.WHITE};
      }
    }
  }
`;
