import { DragDropIcon } from '@/icons';
import styled from 'styled-components';

export const DraggableItemContainer = styled.div`
  background-color: white;
  border: 1px dashed gray;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 16px;
  background-color: 'white';
  cursor: grab;
`;

export const DraggableItemIcon = styled(DragDropIcon)`
  width: 100%;
  svg {
    margin-left: auto;
    cursor: grab;
    width: 32px;
    height: 32px;
  }
`;

export const DraggableItemActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;
