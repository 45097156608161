import { Form } from '@/components';
import { DEVICES } from '@/theme';
import { styled } from 'styled-components';

export const StyledForm = styled(Form)`
  display: grid;
  column-gap: 1rem;
  min-height: 20vh;

  @media screen and (${DEVICES.LAPTOP_S}) {
    display: block;
    column-count: 2;
    column-gap: 2rem;
  }
`;
