import { Switch } from '@/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentPage } from '../../feature';
import { ChannelsSelect } from '../ChannelsSelect';
import {
  InnerContainer,
  OuterContainer,
  StyledAlert,
  StyledFormItem,
  SwitchContainer,
} from './ApplyToManyChannels.style';

export const ApplyToManyChannels = () => {
  const { t } = useTranslation(['common']);
  const currentPage = useSelector(selectCurrentPage);
  const [bulkMode, setBulkMode] = useState(false);

  return (
    <OuterContainer>
      <InnerContainer>
        {bulkMode ? (
          <StyledFormItem name={['channelIds']} label={t('form.channels')}>
            <ChannelsSelect placeholder={t('form.channels_placeholder')} />
          </StyledFormItem>
        ) : (
          <p>
            UUID: <strong>{currentPage?.id}</strong>
          </p>
        )}
        <SwitchContainer>
          <strong>{t('bulk_mode')}</strong>
          <Switch checked={bulkMode} onChange={setBulkMode} />
        </SwitchContainer>
      </InnerContainer>
      {bulkMode && (
        <StyledAlert
          message={t('apply_to_many_channels_warning_title')}
          description={t('apply_to_many_channels_warning_description')}
          type="warning"
          closable
        />
      )}
    </OuterContainer>
  );
};
