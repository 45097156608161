import { Layout as AntdLayout, SiderProps } from 'antd';
import { ReactNode } from 'react';

interface ILayoutSidebar extends SiderProps {
  children: ReactNode;
  className?: string;
}

const LayoutSidebar = (props: ILayoutSidebar) => <AntdLayout.Sider {...props} />;

export default LayoutSidebar;
