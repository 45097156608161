export enum VariableFormFields {
  CODE = 'code',
  DEFAULT_VALUE = 'defaultValue',
  VALUE = 'value',
}

export type TCreateVariableFormValues = {
  [VariableFormFields.CODE]: string;
  [VariableFormFields.DEFAULT_VALUE]: string;
  [VariableFormFields.VALUE]: string;
};

export type TUpdateVariableFormValues = {
  [VariableFormFields.CODE]: string;
  [VariableFormFields.DEFAULT_VALUE]: string;
  [VariableFormFields.VALUE]: string;
};

export enum VariableFormType {
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
}
