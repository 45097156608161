import { Form, FormItem } from '@/components';
import { InputNumber } from '@/ui';
import { styled } from 'styled-components';

export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 2.5rem;
  text-align: start;
  width: 100%;
`;

export const StyledInput = styled(InputNumber)`
  width: 100%;
`;

export const StyledForm = styled(Form)`
  display: flex;
  gap: 1rem;
`;

export const StyledWrapper = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StyledFormItem = styled(FormItem)`
  margin: 0;
`;
