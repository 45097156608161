import { Upload, UploadProps, Button, UploadFile } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledBlock } from './FileUploadStyles';

type IFileUploadProps = UploadProps & {
  onChange?: (file: File | Blob, imageUrl: string) => void;
};

const FileUpload = ({ onChange, ...props }: IFileUploadProps) => {
  const { t } = useTranslation(['common']);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onUploadFile = (info: any) => {
    setFileList(info.fileList);
  };

  const handleSelectFile = useCallback(
    async (file: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = async () => {
        const url = reader.result as string;
        onChange?.(file, url);
      };
      return false;
    },
    [onChange],
  );

  return (
    <StyledBlock>
      <Upload
        {...props}
        beforeUpload={handleSelectFile}
        accept={'text/csv'}
        onChange={onUploadFile}
        maxCount={1}
        fileList={fileList}
      >
        <Button icon={<UploadOutlined />}>{t('btn_upload_new')}</Button>
      </Upload>
    </StyledBlock>
  );
};
export default memo(FileUpload);
