import { IFullPage, IUpdatePageDto } from '@/models/page.model';
import { UpdatePageDTO } from '@/modules/Pages/dtos/UpdatePage.dto';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { UpdatePageInput } from '../UpdatePage.typedefs';

// This function prepares the form data to be transferred to the server
// It removes the images from the form data and adds them to the images array
// And adds the UUID to the new form blocks to relate them to the form blocks on backend
// It also adds the UUID to the new form blocks

export const prepareFormDataToTransfer = (currentPage: IFullPage, formData: any): IUpdatePageDto => {
  const { id: currentPageId, type } = currentPage;

  const { content, seo, channelIds } = formData;
  const contentCopy = cloneDeep(content);

  contentCopy.id = currentPageId;

  const images: File[] = [];

  const excludeImagesAndRename = (obj: any) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      if (key === UpdatePageInput.LINK) {
        const prefixMatch = value.match(/(https?:\/\/)/);
        if (!prefixMatch) {
          obj[key] = `https://${value}`;
        }
      }

      if (value instanceof File) {
        const uuid = obj.id || uuidv4();

        // Added UUID for new form blocks
        if (!obj.id) {
          obj.id = uuid;
        }

        const newFileName = `${uuid}-${value.name}`;
        const fileWithNewName = new File([value], newFileName, { type: value.type, lastModified: value.lastModified });
        images.push(fileWithNewName);

        delete obj[key];
      } else if (value && typeof value === 'object' && !Array.isArray(value)) {
        excludeImagesAndRename(value);
      } else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (item && typeof item === 'object') {
            // Update the order field
            if ('order' in item) {
              item.order = index + 1;
            }
            excludeImagesAndRename(item);
          }
        });
      }
    });
  };

  excludeImagesAndRename(contentCopy);

  return new UpdatePageDTO({
    type,
    content: contentCopy,
    seo,
    images,
    channelIds,
  });
};
