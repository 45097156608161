import { selectCurrentPage } from '@/modules/Pages/feature';
import { TabsProps } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UPDATE_PAGE_TABS_MAP } from '../UpdatePage.constants';
import { UpdatePageTabKey } from '../UpdatePage.typedefs';

export const useUpdatePageTabs = () => {
  const [activeTab, setActiveTab] = useState<string>(UpdatePageTabKey.CONTENT);
  const page = useSelector(selectCurrentPage);

  const getUpdatePageTabItems = useCallback(() => {
    const newItems: TabsProps['items'] = [];

    if (!page) {
      return newItems;
    }

    newItems.push(UPDATE_PAGE_TABS_MAP[UpdatePageTabKey.CONTENT]);

    if (page?.content?.banner) {
      newItems.push(UPDATE_PAGE_TABS_MAP[UpdatePageTabKey.BANNER]);
    }

    if (page?.content?.gallery) {
      newItems.push(UPDATE_PAGE_TABS_MAP[UpdatePageTabKey.GALLERY]);
    }

    newItems.push(UPDATE_PAGE_TABS_MAP[UpdatePageTabKey.SEO]);

    return newItems;
  }, [page]);

  const [items, setItems] = useState<TabsProps['items']>(getUpdatePageTabItems());

  useEffect(() => {
    setItems(getUpdatePageTabItems());
    setActiveTab(UpdatePageTabKey.CONTENT);
  }, [page, getUpdatePageTabItems]);

  return { tabItems: items, activeTab, setActiveTab };
};
