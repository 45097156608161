import { FormItem } from '@/components';
import { COLORS, pickThemeFontStyles } from '@/theme';
import { Button } from '@/ui';
import styled from 'styled-components';

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DiscardButton = styled(Button)`
  margin-right: 8px;
`;

export const WideFormItem = styled(FormItem)`
  width: 100%;

  .ant-row {
    display: flex;
    flex-direction: column;
  }
  .ant-form-item-label > label {
    ${pickThemeFontStyles('16', '26', 'SEMI_BOLD')};
    color: ${COLORS.TEXT_LIGHT};
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &::after {
      content: '';
    }
  }

  .ant-form-item {
    margin-bottom: 0.75rem;
    &:last-of-type {
      margin-bottom: 0;
    }

    .ant-form-item-label {
      padding: 0;
    }
  }
`;

export const ContentBlockContainer = styled.div`
  border: 1px solid gray;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 16px;
  background-color: 'white';
`;
