// Global imports
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

// Component imports

// Store imports
import { useAppDispatch } from '@/store';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { selectCurrentPage, setCurrentPage } from '../../feature';
import { ModalActionsButtons } from '../UpdatePage/components/ModalActionButtons';
import { ApplyToManyChannels } from '../ApplyToManyChannels';

interface Props {
  children: React.ReactNode;
}

export const UpdatePageModal: FC<Props> = ({ children }) => {
  const currentPage = useSelector(selectCurrentPage);
  const dispatch = useAppDispatch();
  const form = useFormInstance();
  const { t } = useTranslation(['common']);

  const handleClose = () => {
    if (form.isFieldsTouched()) {
      Modal.confirm({
        title: t('unsaved_changes_title'),
        content: t('unsaved_changes_content'),
        okText: t('save'),
        cancelText: t('discard'),
        onOk: async () => {
          try {
            await form.submit();
          } catch {
            return;
          }
          // form.resetFields();
          // dispatch(setCurrentPage(null));
        },
        onCancel: () => {
          form.resetFields();
          dispatch(setCurrentPage(null));
        },
      });
    } else {
      form.resetFields();
      dispatch(setCurrentPage(null));
    }
  };

  return (
    <Modal
      width={1000}
      open={Boolean(currentPage)}
      closable
      onCancel={handleClose}
      footer={null}
      loading={!currentPage}
      destroyOnClose
    >
      {currentPage === null ? (
        <p>Loading...</p>
      ) : (
        <>
          <h2>
            Page: <strong>{currentPage?.title}</strong>
          </h2>
          <ApplyToManyChannels />
          {children}
          <ModalActionsButtons onClose={handleClose} />
        </>
      )}
    </Modal>
  );
};
