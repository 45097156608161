import { showApiErrors } from '@/utils';
import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';
import { getRobotsTxt, updateRobotsTxt } from './actionCreators';
import { RobotsState, SLICE_NAME, initialState } from './models';

export const robotsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(isFulfilled(getRobotsTxt, updateRobotsTxt), (state: RobotsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.error = null;
        state.robots = payload;
      })
      .addMatcher(isPending(getRobotsTxt, updateRobotsTxt), (state: RobotsState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(isRejected(getRobotsTxt, updateRobotsTxt), (state: RobotsState, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        showApiErrors(error);
      });
  },
});
