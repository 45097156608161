import { COLORS } from '@/theme';
import { LayoutSidebar } from '@/ui';
import { styled } from 'styled-components';

export const StyledSidebar = styled(LayoutSidebar)`
  width: 18rem;
  border-inline-end: 1px solid ${COLORS.GRAY_LIGHTER};
  background-color: ${COLORS.COLOR_SEARCH} !important;
  overflow-y: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
`;
