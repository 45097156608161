import { DOMAINS, IDomainConfig } from '@/models/global.model';

export const DOMAIN_CONFIGS: Record<string, IDomainConfig> = {
  [DOMAINS.AMAZON]: {
    domain: DOMAINS.AMAZON,
    phone: '800-889-0661',
    title: 'AMAZON',
  },
  [DOMAINS.CONSTRUCTION_COMPLETE]: {
    domain: DOMAINS.CONSTRUCTION_COMPLETE,
    phone: '800-889-0661',
    title: 'CONSTRUCTION COMPLETE',
  },
  [DOMAINS.NASA]: {
    domain: DOMAINS.NASA,
    phone: '1-800-800-8000',
    title: 'NASA',
  },
};
