import styled from 'styled-components';
import { LayoutContent, Layout } from '@/ui';
import { COLORS } from '@/theme';

export const StyledContent = styled(LayoutContent)`
  width: 100%;
  padding: 0;
  margin: 0;
`;

export const StyledLayoutWrapper = styled(Layout)`
  width: 100%;
  height: 100%;
  display: flex;
  margin-left: 18rem;
  min-height: 0;
  flex: 1;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
`;
