import { useTranslation } from 'react-i18next';
import { useGetRequiredRule, useLengthValidationRule } from '@/hooks';
import { MetatagsFormFields } from '../helpers/types';
import { MAX_KEYWORDS_LENGTH } from '../helpers';

const useMetatagsFormRules = () => {
  const { t } = useTranslation(['validations']);

  const requiredRule = useGetRequiredRule({ t });
  const titleLengthRule = useLengthValidationRule({ t, max: 250 });
  const descriptionLengthRule = useLengthValidationRule({ t, max: 500 });

  return {
    [MetatagsFormFields.LINK]: [requiredRule],
    [MetatagsFormFields.TITLE]: [requiredRule, titleLengthRule],
    [MetatagsFormFields.DESCRIPTION]: [requiredRule, descriptionLengthRule],
    [MetatagsFormFields.KEYWORDS]: [
      {
        type: 'array' as const,
        validator(_: unknown, value: string[] = []) {
          if (value?.join(',')?.length > MAX_KEYWORDS_LENGTH) {
            return Promise.reject(
              new Error(t('validation_message_max_length', { ns: 'validation', max: MAX_KEYWORDS_LENGTH })),
            );
          }

          return Promise.resolve();
        },
      },
    ],
  };
};

export default useMetatagsFormRules;
