import { ChannelStatus, ChannelType, IChannel } from '@/models/channel.model';
import { Button, Input } from '@/ui';
import { renderDate } from '@/utils';
import { TableColumnsType, Tag } from 'antd';
import { TFunction } from 'i18next';
import { ChangeEvent } from 'react';
import { StyledButtons, StyledSearchWrapper } from '../components';
import { StyledFilterWrapper, StyledSelectFilter } from '../components/Styled';
import { ChannelTypeFilterValues } from './types';

export const channelColumns = (
  t: TFunction,
  query: string,
  typeFilterValue: string,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  onChangeStatus?: (record: IChannel) => void,
  onEdit?: (record: IChannel) => void,
  onTypeChange?: (value: string) => void,
): TableColumnsType<IChannel> => [
  {
    title: (
      <StyledSearchWrapper>
        <span>{t('channel_table_columns_name')}</span>
        <Input value={query} onChange={onChange} size="small" placeholder={t('placeholder_search')} />
      </StyledSearchWrapper>
    ),
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: (
      <StyledFilterWrapper>
        <span>{t('channel_table_columns_type')}</span>
        <StyledSelectFilter
          value={typeFilterValue}
          options={ChannelTypeFilterValues}
          onChange={onTypeChange}
          size="small"
        />
      </StyledFilterWrapper>
    ),
    dataIndex: 'type',
    key: 'type',
    width: 220,
    render: (type: ChannelType) => type,
  },
  {
    title: t('channel_table_columns_code'),
    dataIndex: 'slug',
    key: 'slug',
    width: 220,
    render: (code: string) => code,
  },
  {
    title: t('channel_table_columns_date'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 180,
    render: (date: string) => renderDate(date, 'D MMM, hh:mm a'),
  },
  {
    title: t('channel_table_columns_status'),
    dataIndex: 'status',
    key: 'link',
    width: 250,
    render: (status: string) => <Tag color={status === ChannelStatus.ACTIVE ? 'green' : 'red'}>{status}</Tag>,
  },
  {
    title: t('channel_table_columns_action'),
    key: 'action',
    width: 260,
    render: (_, record) => (
      <StyledButtons>
        <Button
          type="primary"
          size="small"
          block
          onClick={() => {
            onEdit?.(record);
          }}
        >
          {t('edit')}
        </Button>
        <Button
          type="primary"
          size="small"
          block
          onClick={() => {
            onChangeStatus?.(record);
          }}
        >
          {record.status === ChannelStatus.ACTIVE ? t('deactivate') : t('activate')}
        </Button>
      </StyledButtons>
    ),
  },
];
