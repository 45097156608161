import { ReactNode } from 'react';
import { StyledBlock, StyledContent, StyledTitle } from './SettingBlockStyles';

type ISettingBlockProps = {
  children?: ReactNode;
  title: string;
  icon: ReactNode;
  additionalControls?: ReactNode;
};

const SettingBlock = ({ children, icon, title, additionalControls }: ISettingBlockProps) => {
  return (
    <StyledBlock>
      <StyledTitle>
        {icon}
        {title}
        {additionalControls}
      </StyledTitle>
      <StyledContent>{children}</StyledContent>
    </StyledBlock>
  );
};

export default SettingBlock;
