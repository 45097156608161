import { RateProps } from 'antd';
import { StyledRate, StyledStarIcon } from './RateStyles';

export type IRateProps = {
  size?: 'large' | 'medium' | 'small';
} & RateProps;

const Rate = ({ size = 'medium', ...props }: IRateProps) => (
  <StyledRate
    {...props}
    $size={size}
    character={({ index = 0, value = 0 }) => <StyledStarIcon $hovered={index < value} />}
  />
);

export default Rate;
