import { PaginationFields } from '@/models/pagination.model';
import { showApiErrors } from '@/utils';
import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { createRedirect, deleteRedirect, getRedirects, updateRedirect, importRedirects } from './actionCreators';
import { RedirectsState, SLICE_NAME, initialState } from './models';

export const redirectsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRedirects.fulfilled, (state: RedirectsState, action) => {
        const { payload } = action;
        state.redirects = payload?.redirects ?? [];
        state.pagination = pick(payload, [PaginationFields.PAGE_SIZE, PaginationFields.TOTAL, PaginationFields.PAGE]);
        state.isLoading = false;
      })
      .addCase(createRedirect.fulfilled, (state: RedirectsState) => {
        state.isLoading = false;
      })
      .addCase(updateRedirect.fulfilled, (state: RedirectsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.redirects = (state.redirects ?? []).map((metatag) => {
          if (metatag.id === payload.id) return payload;
          return metatag;
        });
      })
      .addCase(deleteRedirect.fulfilled, (state: RedirectsState, action) => {
        const { meta } = action;
        state.isLoading = false;
        state.redirects = (state.redirects ?? []).filter((redirect) => redirect.id !== meta.id);
      })
      .addCase(importRedirects.fulfilled, (state: RedirectsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.importResult = payload;
      })
      .addMatcher(
        isPending(getRedirects, createRedirect, updateRedirect, deleteRedirect, importRedirects),
        (state: RedirectsState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(getRedirects, createRedirect, updateRedirect, deleteRedirect, importRedirects),
        (state: RedirectsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          showApiErrors(error);
        },
      );
  },
});
