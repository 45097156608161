export enum ShippingPriceEndpoints {
  ROOT = '/shipping-prices',
}

export const shippingPriceEndpoints = {
  GET_SHIPPING_PRICES: ShippingPriceEndpoints.ROOT,
};

export const getShippingPriceEndpoints = ({ id }: { id: string }) => ({
  UPDATE_SHIPPING_PRICE: `${ShippingPriceEndpoints.ROOT}/${id}`,
});

export interface IShippingPrice {
  id: string;
  countryId: string;
  code: string;
  name: string;
  price: number;
}

// ============== DTO ==============

export interface IUpdateShippingPriceDTO {
  id: string;
  price: number;
}

export interface IUpdateShippingPricesDTO {
  ids: string[];
  price: number;
}

// ============== Response ==============

export type IGetShippingPricesResponse = IShippingPrice[];

export type IUpdateShippingPriceResponse = IShippingPrice;

export type IUpdateShippingPricesResponse = IShippingPrice[];
