import { UpdatePageTabKey } from '../UpdatePage.typedefs';

type GetFirstErrorFieldTabKey = (fieldName: (string | number)[]) => UpdatePageTabKey;

export const getFirstErrorFieldTabKey: GetFirstErrorFieldTabKey = (fieldName) => {
  if (fieldName[0] === 'content') {
    if (fieldName[1] === 'banner') {
      return UpdatePageTabKey.BANNER;
    }
    if (fieldName[1] === 'gallery') {
      return UpdatePageTabKey.GALLERY;
    }
    return UpdatePageTabKey.CONTENT;
  }
  if (fieldName[0] === 'seo') {
    return UpdatePageTabKey.SEO;
  }
  return UpdatePageTabKey.CONTENT;
};
