import { PaginationFields } from '@/models/pagination.model';
import { showApiErrors } from '@/utils';
import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import { createVariable, deleteVariable, getVariables, updateVariable } from './actionCreators';
import { initialState, SLICE_NAME, VariablesState } from './models';

export const variablesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVariables.fulfilled, (state: VariablesState, action) => {
        const { payload } = action;
        state.variables = payload?.variables ?? [];
        state.pagination = pick(payload, [PaginationFields.PAGE_SIZE, PaginationFields.TOTAL, PaginationFields.PAGE]);
        state.isLoading = false;
      })
      .addCase(createVariable.fulfilled, (state: VariablesState, action) => {
        const newVariable = action.payload;

        if (state.pagination && state.pagination.page !== 1) {
          state.isLoading = false;
          return;
        }

        state.variables = [newVariable, ...(state.variables ?? [])];
        if (state.pagination) {
          state.pagination.total += 1;
          if (state.variables.length > 10) {
            state.variables.pop();
          }
        }

        state.isLoading = false;
      })
      .addCase(updateVariable.fulfilled, (state: VariablesState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.variables = (state.variables ?? []).map((variable) => {
          if (variable.id === payload.id) return payload;
          return variable;
        });
      })
      .addCase(deleteVariable.fulfilled, (state: VariablesState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.variables = (state.variables ?? []).filter((redirect) => redirect.id !== payload.id);
        state.pagination!.total -= 1;
      })
      .addMatcher(isPending(getVariables, createVariable, updateVariable, deleteVariable), (state: VariablesState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addMatcher(
        isRejected(getVariables, createVariable, updateVariable, deleteVariable),
        (state: VariablesState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          showApiErrors(error);
        },
      );
  },
});
