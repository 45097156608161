import { useGetChannelRule, useGetRequiredRule, useGetUrlRule } from '@/hooks';
import { CreateChannelFormFields } from '../helpers/types';
import { useTranslation } from 'react-i18next';

const useGeneralSettingsFormRules = () => {
  const { t } = useTranslation(['validations']);

  const requiredRule = useGetRequiredRule({ t });
  const channelRule = useGetChannelRule({ t });
  const urlRule = useGetUrlRule({ t });

  return {
    [CreateChannelFormFields.TYPE]: [requiredRule],
    [CreateChannelFormFields.NAME]: [requiredRule],
    [CreateChannelFormFields.SITE_URL]: [requiredRule, urlRule],
    [CreateChannelFormFields.COPY_CHANNEL_ID]: [requiredRule],
    [CreateChannelFormFields.SLUG]: [requiredRule, channelRule],
  };
};

export default useGeneralSettingsFormRules;
