import { useAppDispatch, useAppState } from '@/store';
import { useCallback } from 'react';
import { updatePageStatus } from '../feature';
import { selectUpdateStatusError, selectUpdateStatusIsLoading } from '../feature/selectors';
import { PageStatus } from '../typedefs';

export const useUpdatePageStatus = () => {
  const isLoading = useAppState(selectUpdateStatusIsLoading);
  const error = useAppState(selectUpdateStatusError);

  const dispatch = useAppDispatch();

  const updatePageStatusCallback = useCallback(
    (pageId: string, status: PageStatus) => {
      dispatch(
        updatePageStatus({
          pageId,
          status,
        }),
      );
    },
    [dispatch],
  );

  return {
    updatePageStatus: updatePageStatusCallback,
    isLoading,
    error,
  };
};
