import { Radio } from 'antd';
import styled from 'styled-components';

export const StyledRadio = styled(Radio)`
  & .ant-radio-inner {
    border-width: 0.125rem;
  }
  & .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.token?.colorPrimary};
    background-color: transparent;

    &::after {
      background-color: ${({ theme }) => theme.token?.colorPrimary};
    }
  }
`;
