import { createAsyncThunk } from '@reduxjs/toolkit';
import { RobotsTxtAPI } from '@/api';
import { IApiError } from '@/models/apiError.model';
import { IUpdateRobotsDTO, IUpdateRobotsResponse, IGetRobotsDTO, IGetRobotsResponse } from '@/models/robots.model';
import { formatApiError } from '@/utils';
import { SLICE_NAME } from './models';

export const getRobotsTxt = createAsyncThunk<IGetRobotsResponse, IGetRobotsDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/getRobotsTxt`,
  async (data) => {
    const response = await RobotsTxtAPI.getRobots(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateRobotsTxt = createAsyncThunk<
  IUpdateRobotsResponse,
  IUpdateRobotsDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/updateRobotsTxt`,
  async (data) => {
    const response = await RobotsTxtAPI.updateRobots(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
