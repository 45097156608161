import { Tag } from 'antd';
import styled from 'styled-components';
import { pickThemeFontStyles } from '@/theme';

export const StyledCheckableTag = styled(Tag.CheckableTag)`
  ${pickThemeFontStyles('14', '26')};
  border: 1px solid ${({ theme }) => theme.token?.colorBorderSecondary};
  border-radius: 99em;
  padding: 0.375rem 0.75rem;
  display: inline-flex;
  gap: 0.25rem;
  align-items: center;
`;

export const StyledTag = styled(Tag)`
  ${pickThemeFontStyles('14', '26', 'BOLD')};
  border: none;
  border-radius: 99em;
  padding: 0.25rem 0.5rem;
  display: inline-flex;
  gap: 0.25rem;
  line-height: 1;
  align-items: center;
`;
