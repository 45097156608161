import { styled } from 'styled-components';

export const StyledLinkInputWrapper = styled.div`
  margin-bottom: 1rem;

  .ant-form-item {
    margin-bottom: 0;
    .ant-form-item-control {
      display: grid;
      align-items: center;
      column-gap: 1rem;
      grid-template-columns: auto 1fr;
      grid-template-areas: 'extra input' 'error -';

      .ant-form-item-control-input {
        flex-grow: 1;
        grid-area: input;
      }

      .ant-form-item-extra {
        min-height: auto;
        grid-area: extra;
      }
    }
  }
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`;
