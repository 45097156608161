import { CSSProperties, ReactNode } from 'react';
import { Layout as AntdLayout } from 'antd';

interface ILayoutComponent {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const LayoutFooter = (props: ILayoutComponent) => <AntdLayout.Footer {...props} />;

export default LayoutFooter;
