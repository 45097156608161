import { SearchProps } from 'antd/es/input';
import { StyledSearch } from './SearchStyles';

export type ISearchProps = {
  size: 'default' | 'large' | 'small' | number;
} & Omit<SearchProps, 'size'>;

const Search = ({ size, ...props }: ISearchProps) => <StyledSearch {...props} $size={size} />;

export default Search;
