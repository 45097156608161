// Global
import { Switch } from 'antd';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

// Hooks
import { useUpdatePageStatus } from '../../hooks/useChangePageStatus';

// Types
import { PageStatus } from '../../typedefs';

// UI
import { PopConfirm } from '@/ui';

interface Props {
  status: PageStatus;
  pageId: string;
}

export const StatusSwitch: FC<Props> = ({ status, pageId }) => {
  const { t } = useTranslation(['common']);
  const { updatePageStatus, isLoading } = useUpdatePageStatus();

  const handleChange = useCallback(
    () => updatePageStatus(pageId, status === PageStatus.INACTIVE ? PageStatus.ACTIVE : PageStatus.INACTIVE),
    [pageId, updatePageStatus, status],
  );

  return (
    <div onClick={(e) => e.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
      <PopConfirm
        title={t('modal_page_status_title')}
        description={t('modal_page_status_description')}
        onConfirm={handleChange}
      >
        <Switch
          checkedChildren={PageStatus.ACTIVE}
          checked={status === PageStatus.ACTIVE}
          unCheckedChildren={PageStatus.INACTIVE}
          loading={isLoading}
        />
      </PopConfirm>
    </div>
  );
};
