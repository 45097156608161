import styled, { css } from 'styled-components';
import { Input as AntInput, Input, InputNumber } from 'antd';
import { COLORS, DEVICES, pickThemeFontSize, pickThemeFontStyles } from '@/theme';

export const inputStyles = css`
  &.ant-input,
  &.ant-input-group-wrapper {
    width: 100%;
    background-color: ${({ theme }) => theme.token?.white};
    ${pickThemeFontStyles('14', '20')};
    color: ${COLORS.TEXT_PRIMARY};
  }

  ::placeholder {
    color: ${({ theme }) => theme.token?.colorPlaceholder};
  }

  &.ant-input-disabled {
    border: 1px dashed ${COLORS.GRAY_3} !important;
    color: ${COLORS.GRAY_2};
    background: ${COLORS.GRAY_1} !important;
  }

  &.ant-input-affix-wrapper {
    overflow: hidden;

    & .ant-input {
      ${pickThemeFontStyles('14', '20')};
    }

    & .ant-input-suffix {
      font-size: ${pickThemeFontSize('20')};
      margin: 0;
    }

    & .ant-input-prefix {
      font-size: ${pickThemeFontSize('20')};
      margin: 0;
    }
  }

  @media screen and ${DEVICES.LAPTOP_S} {
    &.ant-input {
      ${pickThemeFontStyles('16', '24')};
    }

    &.ant-input-affix-wrapper {
      & .ant-input {
        ${pickThemeFontStyles('16', '24')};
      }
    }
  }
`;

export const StyledInput = styled(AntInput)`
  ${inputStyles};
`;

export const StyledInputNumber = styled(InputNumber)`
  ${inputStyles};
  &.ant-input-number {
    width: 100%;
  }
`;

export const StyledPasswordInput = styled(AntInput.Password)`
  ${inputStyles};
`;

export const StyledTextArea = styled(Input.TextArea)`
  ${inputStyles}
`;
