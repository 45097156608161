// Globals
import { ChangeEvent } from 'react';
import { TableColumnsType } from 'antd';
import { TFunction } from 'i18next';

// Components
import { Button, Input, Tag } from '@/ui';
import { StyledButtons, StyledSearchWrapper, StyledTruncate } from '../components';
import { PenIcon, TrashIcon } from '@/icons';

// Models
import { IMetaTags } from '@/models/metatags.model';
import { COLORS } from '@/theme';

export const metatagsColumns = (
  t: TFunction,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  onDelete?: (record: IMetaTags) => void,
  onEdit?: (record: IMetaTags) => void,
): TableColumnsType<IMetaTags> => [
  {
    title: (
      <StyledSearchWrapper>
        <span>{t('column_source')}</span>
        <Input onChange={onChange} size="small" placeholder={t('placeholder_search')} />
      </StyledSearchWrapper>
    ),
    dataIndex: 'link',
    key: 'source',
    width: 400,
    render: (link: string) => (
      <StyledTruncate $width="15rem" title={link}>
        {link}
      </StyledTruncate>
    ),
  },
  {
    dataIndex: 'title',
    key: 'title',
    width: 200,
    title: t('update_page_form_seo_title'),
    render: (title: string) => <StyledTruncate>{title}</StyledTruncate>,
  },
  {
    dataIndex: 'description',
    key: 'description',
    width: 200,
    title: t('update_page_form_seo_description'),
    render: (description: string) => <StyledTruncate>{description}</StyledTruncate>,
  },
  {
    title: t('update_page_form_seo_keywords'),
    dataIndex: 'keywords',
    key: 'keywords',
    width: 400,
    render: (keywords: string[] = []) => (
      <div>
        {keywords?.map((keyword) => (
          <Tag color={COLORS.GRAY} key={keyword}>
            {keyword}
          </Tag>
        ))}
      </div>
    ),
  },
  {
    title: t('column_action'),
    key: 'action',
    width: 150,
    render: (_, record) => (
      <StyledButtons>
        <Button
          icon={<PenIcon />}
          type="primary"
          size="small"
          block
          onClick={() => {
            onEdit?.(record);
          }}
        />
        <Button
          type="primary"
          size="small"
          icon={<TrashIcon />}
          danger
          block
          onClick={() => {
            onDelete?.(record);
          }}
        />
      </StyledButtons>
    ),
  },
];
