import { IApiError } from '@/models/apiError.model';
import { IBasePage, IFullPage } from '@/models/page.model';

export const SLICE_NAME = 'pages';

export interface PagesState {
  pages: IBasePage[];
  isLoading: boolean;
  error: IApiError | null;
  updateStatusIsLoading: boolean;
  updateStatusError: IApiError | null;
  currentPage: IFullPage | null;
  currentPageIsLoading: boolean;
  currentPageError: IApiError | null;
}

export const initialState: PagesState = {
  pages: [],
  currentPage: null,
  currentPageIsLoading: false,
  currentPageError: null,
  isLoading: false,
  error: null,
  updateStatusIsLoading: false,
  updateStatusError: null,
};
