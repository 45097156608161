import { selectChannel } from '@/modules/Channels';
import { useAppDispatch, useAppState } from '@/store';
import { getPage } from '../feature';
import { selectCurrentPage, selectCurrentPageError, selectCurrentPageIsLoading } from '../feature/selectors';
import { PageType } from '../typedefs';

export const useGetPage = () => {
  const currentChannel = useAppState(selectChannel);
  const currentPage = useAppState(selectCurrentPage);
  const isLoading = useAppState(selectCurrentPageIsLoading);
  const error = useAppState(selectCurrentPageError);

  const dispatch = useAppDispatch();

  const loadPage = (pageType: PageType) => {
    if (!currentChannel) {
      return;
    }
    dispatch(
      getPage({
        channelSlug: currentChannel.slug,
        pageType,
        isRaw: true,
      }),
    );
  };

  return {
    currentPage,
    isLoading,
    error,
    loadPage,
  };
};
