import { IApiError } from '@/models/apiError.model';
import { ISetting } from '@/models/settings.model';

export const SLICE_NAME = 'settings';

export interface SettingsState {
  settings: ISetting | null;
  isLoading: boolean;
  error: IApiError | null;
}

export const initialState: SettingsState = {
  settings: null,
  isLoading: false,
  error: null,
};
