import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { SettingsState, SLICE_NAME } from './models';
import { groupBy } from 'lodash';

const settingsSelector = (state: RootState): SettingsState => state[SLICE_NAME];

export const selectSettings = createSelector(settingsSelector, (state: SettingsState) => state.settings);

export const selectSettingsIsLoading = createSelector(settingsSelector, (state: SettingsState) => state.isLoading);

export const selectSettingsError = createSelector(settingsSelector, (state: SettingsState) => state.error);

export const selectLogos = createSelector(settingsSelector, (state: SettingsState) => {
  return groupBy(state.settings?.images, 'metaType');
});
