import { useGetRequiredRule, useLengthValidationRule } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { EditReviewFormFields } from '../typedefs';

export const useEditReviewFormRules = () => {
  const { t } = useTranslation(['validations']);

  const lengthRule = useLengthValidationRule({ t, max: 2500 });
  const lengthRuleShort = useLengthValidationRule({ t, max: 100 });
  const requiredRule = useGetRequiredRule({ t });

  return {
    [EditReviewFormFields.CREATED_AT]: [requiredRule],
    [EditReviewFormFields.REVIEW_TEXT]: [lengthRule, requiredRule],
    [EditReviewFormFields.EASE_OF_USE_RATING]: [requiredRule],
    [EditReviewFormFields.DURABILITY_RATING]: [requiredRule],
    [EditReviewFormFields.VALUE_FOR_MONEY_RATING]: [requiredRule],
    [EditReviewFormFields.USER_NAME]: [requiredRule, lengthRuleShort],
  };
};
