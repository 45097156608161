import { createAsyncThunk } from '@reduxjs/toolkit';
import { ShippingPriceAPI } from '@/api';
import { formatApiError } from '@/utils';
import { IApiError } from '@/models/apiError.model';
import {
  IGetShippingPricesResponse,
  IUpdateShippingPriceDTO,
  IUpdateShippingPriceResponse,
  IUpdateShippingPricesDTO,
  IUpdateShippingPricesResponse,
} from '@/models/shippingPrice.model';
import { SLICE_NAME } from './models';

export const getShippingPrices = createAsyncThunk<IGetShippingPricesResponse, void, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/getShippingPrices`,
  async () => {
    const response = await ShippingPriceAPI.getShippingPrices();
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateShippingPrice = createAsyncThunk<
  IUpdateShippingPriceResponse,
  IUpdateShippingPriceDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/updateShippingPrice`,
  async (data) => {
    const response = await ShippingPriceAPI.updateShippingPrice(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateShippingPrices = createAsyncThunk<
  IUpdateShippingPricesResponse,
  IUpdateShippingPricesDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/updateShippingPrices`,
  async (data) => {
    const response = await ShippingPriceAPI.updateShippingPrices(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
