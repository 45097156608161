// Globals
import { Navigate, Outlet } from 'react-router-dom';

// Store
import { useAppState } from '@/store';
import { selectIsAuthorized } from '../../feature';

// Helpers
import { ROUTES } from '@/constants/routes';

const ProtectedRoute = ({ redirectPath = ROUTES.SIGN_IN }: { redirectPath?: ROUTES }) => {
  const isAuthenticated = useAppState(selectIsAuthorized);
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
