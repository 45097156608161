import { IUpdatePageDto } from '@/models/page.model';
import { PageContent, PageSEO, PageType } from '../typedefs';

export class UpdatePageDTO implements IUpdatePageDto {
  id: string;

  type: PageType;

  content: PageContent;

  seo: PageSEO;

  images: File[];

  channelIds: number[];

  constructor(values: any) {
    this.type = values.type;
    this.id = values.id;
    this.content = values.content;
    this.seo = values.seo;
    this.images = values.images;
    this.channelIds = values.channelIds ? values.channelIds : undefined;
  }
}
