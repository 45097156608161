import {
  getVariablesEndpoints,
  ICreateVariableDTO,
  ICreateVariableResponse,
  IDeleteVariableDTO,
  IDeleteVariableResponse,
  IGetIVariablesResponse,
  IGetVariablesDTO,
  IUpdateVariableDTO,
  IUpdateVariableResponse,
  staticVariablesEndpoints,
} from '@/models/variable.model';
import { ApiResponse, httpClient } from '@/utils';

class VariablesAPI {
  static getVariables(params: IGetVariablesDTO): ApiResponse<IGetIVariablesResponse> {
    const { GET_VARIABLES } = staticVariablesEndpoints;
    return httpClient.get<IGetIVariablesResponse>(GET_VARIABLES, {
      params,
    });
  }

  static createVariable(data: ICreateVariableDTO): ApiResponse<ICreateVariableResponse> {
    const { CREATE_VARIABLE } = staticVariablesEndpoints;
    return httpClient.post<ICreateVariableResponse>(CREATE_VARIABLE, data);
  }

  static updateVariable({ id, ...data }: IUpdateVariableDTO): ApiResponse<IUpdateVariableResponse> {
    const { UPDATE_VARIABLE } = getVariablesEndpoints({ id });
    return httpClient.patch<IUpdateVariableResponse>(UPDATE_VARIABLE, data);
  }

  static deleteVariable({ id }: IDeleteVariableDTO): ApiResponse<IDeleteVariableResponse> {
    const { DELETE_VARIABLE } = getVariablesEndpoints({ id });
    return httpClient.delete<IDeleteVariableResponse>(DELETE_VARIABLE);
  }
}

export default VariablesAPI;
