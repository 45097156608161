import { httpClient, ApiResponse } from '@/utils';
import {
  ICreateMetatagResponse,
  ICreateMetatagsDTO,
  IGetMetatagsResponse,
  staticMetatagsEndpoints,
  IUpdateMetatagsDTO,
  getMetatagsEndpoints,
  IUpdateMetatagResponse,
  IGetMetatagsDTO,
  IDeleteMetatagsDTO,
  IDeleteMetatagResponse,
} from '@/models/metatags.model';

class MetatagsAPI {
  static getMetatags(params: IGetMetatagsDTO): ApiResponse<IGetMetatagsResponse> {
    const { GET_METATAGS } = staticMetatagsEndpoints;
    return httpClient.get<IGetMetatagsResponse>(GET_METATAGS, {
      params,
    });
  }

  static createMetatag(data: ICreateMetatagsDTO): ApiResponse<ICreateMetatagResponse> {
    const { CREATE_METATAG } = staticMetatagsEndpoints;
    return httpClient.post<ICreateMetatagResponse>(CREATE_METATAG, data);
  }

  static updateMetatag({ id, ...data }: IUpdateMetatagsDTO): ApiResponse<IUpdateMetatagResponse> {
    const { UPDATE_METATAG } = getMetatagsEndpoints({ id });
    return httpClient.patch<IUpdateMetatagResponse>(UPDATE_METATAG, data);
  }

  static deleteMetatag({ id }: IDeleteMetatagsDTO): ApiResponse<IDeleteMetatagResponse> {
    const { DELETE_METATAG } = getMetatagsEndpoints({ id });
    return httpClient.delete<IDeleteMetatagResponse>(DELETE_METATAG);
  }
}

export default MetatagsAPI;
