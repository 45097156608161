import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { RobotsState, SLICE_NAME } from './models';

const robotsSelector = (state: RootState): RobotsState => state[SLICE_NAME];

export const selectRobots = createSelector(robotsSelector, (state: RobotsState) => state.robots);

export const selectRobotsIsLoading = createSelector(robotsSelector, (state: RobotsState) => state.isLoading);

export const selectRobotsError = createSelector(robotsSelector, (state: RobotsState) => state.error);
