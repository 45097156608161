import { InputProps, InputRef } from 'antd';
import { forwardRef } from 'react';
import { StyledInput } from './InputStyles';

export type IInputProps = InputProps & React.RefAttributes<InputRef>;

const Input = forwardRef<InputRef, IInputProps>(({ prefix, value, ...props }, ref) => (
  <StyledInput {...props} prefix={prefix} value={value} ref={ref} />
));

Input.displayName = 'CustomInput';

export default Input;
