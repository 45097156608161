// Global
import { FormListProps } from 'antd/es/form';
import { FC } from 'react';

// Components
import { VariableType } from 'src/shared/ui/VariablesDetector/VariableDetector.typedefs';
import { UpdateAdvantageBlocksFormList } from './UpdateAdvantageBlocksFormList';
import { UpdateDefaultBlocksFormList } from './UpdateDefaultBlocksFormList';
import { UpdateLinkBlocksFormList } from './UpdateLinkBlocksFormList';

interface FormContentBlocksListProps {
  blockType: string;
  fieldName: FormListProps['name'];
  registerInputRef: (fieldName: string, ref: any, type: VariableType) => void;
  handleInputChange: (fieldName: string, value: string) => void;
}

export const FormContentBlocksList: FC<FormContentBlocksListProps> = ({
  blockType,
  fieldName,
  handleInputChange,
  registerInputRef,
}) => {
  switch (blockType) {
    case 'link':
      return (
        <UpdateLinkBlocksFormList
          key={blockType}
          fieldName={fieldName}
          handleInputChange={handleInputChange}
          registerInputRef={registerInputRef}
        />
      );
    case 'default':
      return (
        <UpdateDefaultBlocksFormList
          key={blockType}
          fieldName={fieldName}
          handleInputChange={handleInputChange}
          registerInputRef={registerInputRef}
        />
      );
    case 'advantage':
      return (
        <UpdateAdvantageBlocksFormList
          key={blockType}
          fieldName={fieldName}
          handleInputChange={handleInputChange}
          registerInputRef={registerInputRef}
        />
      );
    default:
      return null;
  }
};
