// Globals
import { TableColumnsType } from 'antd';
import { TFunction } from 'i18next';
import { ChangeEvent } from 'react';

// Components
import { Button, Input } from '@/ui';
import { StyledButtons, StyledSearchWrapper, StyledTruncate } from '../components';

// Models
import { PenIcon, TrashIcon } from '@/icons';
import { IRedirect } from '@/models/redirect.model';

export const redirectsColumns = (
  t: TFunction,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
  onDelete?: (record: IRedirect) => void,
  onEdit?: (record: IRedirect) => void,
): TableColumnsType<IRedirect> => [
  {
    dataIndex: 'code',
    key: 'code',
    width: 200,
    title: t('column_code'),
    render: (code: string) => <StyledTruncate>{code}</StyledTruncate>,
  },
  {
    dataIndex: 'source',
    key: 'source',
    width: 200,
    title: (
      <StyledSearchWrapper>
        <span>{t('column_source')}</span>
        <Input onChange={onChange} size="small" placeholder={t('placeholder_search_redirect')} />
      </StyledSearchWrapper>
    ),
    render: (source: string) => <StyledTruncate>{source}</StyledTruncate>,
  },
  {
    dataIndex: 'target',
    key: 'target',
    width: 200,
    title: t('column_target'),
    render: (target: string) => <StyledTruncate>{target}</StyledTruncate>,
  },
  {
    title: t('column_action'),
    key: 'action',
    width: 150,
    render: (_, record) => (
      <StyledButtons>
        <Button
          icon={<PenIcon />}
          type="primary"
          size="small"
          block
          onClick={() => {
            onEdit?.(record);
          }}
        />
        <Button
          type="primary"
          size="small"
          icon={<TrashIcon />}
          danger
          block
          onClick={() => {
            onDelete?.(record);
          }}
        />
      </StyledButtons>
    ),
  },
];
