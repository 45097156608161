import { IBulkUpdateGeneralSettingsDTO } from '@/models/settings.model';
import {
  GeneralSettingsBulkFormFields,
  GeneralSettingsFormFields,
  TGeneralSettingsBulkFormValues,
} from '../helpers/types';

class BulkUpdateGeneralSettingsDTO implements IBulkUpdateGeneralSettingsDTO {
  adminEmails?: string[];

  copyright?: string;

  googleTagManagement?: string;

  channelIds: number[];

  smtp?: {
    host?: string;
    port?: number;
    auth?: {
      user?: string;
      pass?: string;
    };
  };

  phones?: {
    footer?: string;
    contactUsPage?: string;
  };

  popularProductIds?: string[];

  constructor(values: Partial<TGeneralSettingsBulkFormValues>) {
    this.channelIds = values[GeneralSettingsFormFields.CHANNEL] ?? [];
    if (values[GeneralSettingsBulkFormFields.COPYRIGHT]) {
      this.copyright = values[GeneralSettingsFormFields.COPYRIGHT];
    }
    if (values[GeneralSettingsBulkFormFields.GOOGLE_TAG_MANAGER]) {
      this.googleTagManagement = values[GeneralSettingsFormFields.GOOGLE_TAG_MANAGER];
    }
    if (values[GeneralSettingsBulkFormFields.ADMIN_EMAILS]) {
      this.adminEmails = values[GeneralSettingsFormFields.ADMIN_EMAILS];
    }

    const sendEmailConfig =
      values[GeneralSettingsBulkFormFields.SMTP_AUTH_PASS] ||
      values[GeneralSettingsBulkFormFields.SMTP_AUTH_USER] ||
      values[GeneralSettingsBulkFormFields.SMTP_HOST] ||
      values[GeneralSettingsBulkFormFields.SMTP_PORT];

    if (sendEmailConfig) {
      this.smtp = {};
      if (values[GeneralSettingsBulkFormFields.SMTP_HOST]) {
        this.smtp.host = values[GeneralSettingsFormFields.SMTP_HOST];
      }
      if (values[GeneralSettingsBulkFormFields.SMTP_PORT]) {
        this.smtp.port = Number(values[GeneralSettingsFormFields.SMTP_PORT]);
      }

      if (
        values[GeneralSettingsBulkFormFields.SMTP_AUTH_USER] ||
        values[GeneralSettingsBulkFormFields.SMTP_AUTH_PASS]
      ) {
        this.smtp.auth = {
          user: values[GeneralSettingsFormFields.SMTP_AUTH_USER],
          pass: values[GeneralSettingsFormFields.SMTP_AUTH_PASS],
        };
      }
    }

    const sendPhonesConfig =
      values[GeneralSettingsBulkFormFields.PHONES_FOOTER] || values[GeneralSettingsBulkFormFields.PHONES_CONTACT_US];
    if (sendPhonesConfig) {
      this.phones = {};
      if (values[GeneralSettingsBulkFormFields.PHONES_FOOTER]) {
        this.phones.footer = values[GeneralSettingsFormFields.PHONES_FOOTER]
          ? values[GeneralSettingsFormFields.PHONES_FOOTER]
          : undefined;
      }

      if (values[GeneralSettingsBulkFormFields.PHONES_CONTACT_US]) {
        this.phones.contactUsPage = values[GeneralSettingsFormFields.PHONES_CONTACT_US]
          ? values[GeneralSettingsFormFields.PHONES_CONTACT_US]
          : undefined;
      }
    }

    if (values[GeneralSettingsBulkFormFields.POPULAR_PRODUCTS]) {
      this.popularProductIds = values[GeneralSettingsFormFields.POPULAR_PRODUCTS];
    }
  }
}

export default BulkUpdateGeneralSettingsDTO;
