import { ThemeConfigProvider } from '@/modules/_Theme';
import Router from '@/router';

const App = () => (
  <ThemeConfigProvider>
    <Router />
  </ThemeConfigProvider>
);

export default App;
