import { useGetRequiredRule, useLengthValidationRule } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { CreateReviewFormFields } from '../typedefs';
import { useCheckProductExists } from './useCheckProductExists';

export const useCreateReviewFormRules = () => {
  const { t } = useTranslation(['validations']);
  const { checkProductExist } = useCheckProductExists();

  const lengthRule = useLengthValidationRule({ t, max: 2500 });
  const lengthRuleShort = useLengthValidationRule({ t, max: 100 });
  const requiredRule = useGetRequiredRule({ t });

  return {
    [CreateReviewFormFields.CREATED_AT]: [requiredRule],
    [CreateReviewFormFields.REVIEW_TEXT]: [lengthRule, requiredRule],
    [CreateReviewFormFields.CHANNEL_ID]: [requiredRule],
    [CreateReviewFormFields.EASE_OF_USE_RATING]: [requiredRule],
    [CreateReviewFormFields.DURABILITY_RATING]: [requiredRule],
    [CreateReviewFormFields.VALUE_FOR_MONEY_RATING]: [requiredRule],
    [CreateReviewFormFields.USER_NAME]: [requiredRule, lengthRuleShort],
    [CreateReviewFormFields.STATUS]: [requiredRule],
    [CreateReviewFormFields.PRODUCT_ID]: [
      requiredRule,
      {
        validator: async (_: any, value: string) => {
          try {
            if (!value) return await Promise.resolve();
            const exist = await checkProductExist(value);

            if (!exist) {
              // eslint-disable-next-line @typescript-eslint/return-await
              return Promise.reject(t('validation_product_does_not_exist', { ns: 'validation' }));
            }
          } catch (e) {
            console.error(e);
            return Promise.reject(t('validation_error_during_product_check', { ns: 'validation' }));
          }
          return Promise.resolve();
        },
      },
    ],
  };
};
