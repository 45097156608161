import isEmpty from 'lodash/isEmpty';
import { IUpdateUISettingsDTO } from '@/models/settings.model';
import { TUISettingsFormValues, UISettingsFormFields } from '../helpers/types';
import { ITheme } from '@/models/theme.model';

class UpdateUISettingsDTO implements IUpdateUISettingsDTO {
  headerLogo?: File;

  footerLogo?: File;

  emptyStateLogo?: File;

  mobileHeaderLogo?: File;

  companyLogo?: File;

  favicon48?: File;

  favicon96?: File;

  favicon144?: File;

  theme: Partial<ITheme> = {};

  channelId: number;

  constructor(
    values: Partial<TUISettingsFormValues> & {
      channelId: number;
    },
  ) {
    if (typeof values[UISettingsFormFields.HEADER_LOGO] !== 'string') {
      this.headerLogo = values[UISettingsFormFields.HEADER_LOGO];
    }

    if (typeof values[UISettingsFormFields.FOOTER_LOGO] !== 'string') {
      this.footerLogo = values[UISettingsFormFields.FOOTER_LOGO];
    }

    if (typeof values[UISettingsFormFields.EMPTY_STATE_LOGO] !== 'string') {
      this.emptyStateLogo = values[UISettingsFormFields.EMPTY_STATE_LOGO];
    }

    if (typeof values[UISettingsFormFields.MOBILE_HEADER_LOGO] !== 'string') {
      this.mobileHeaderLogo = values[UISettingsFormFields.MOBILE_HEADER_LOGO];
    }

    if (typeof values[UISettingsFormFields.COMPANY_LOGO] !== 'string') {
      this.companyLogo = values[UISettingsFormFields.COMPANY_LOGO];
    }

    if (typeof values[UISettingsFormFields.FAVICON48] !== 'string') {
      this.favicon48 = values[UISettingsFormFields.FAVICON48];
    }

    if (typeof values[UISettingsFormFields.FAVICON96] !== 'string') {
      this.favicon96 = values[UISettingsFormFields.FAVICON96];
    }

    if (typeof values[UISettingsFormFields.FAVICON144] !== 'string') {
      this.favicon144 = values[UISettingsFormFields.FAVICON144];
    }

    if (typeof values[UISettingsFormFields.PRIMARY_COLOR] !== 'string') {
      this.theme.primaryColor = values[UISettingsFormFields.PRIMARY_COLOR]?.toHexString?.();
    }

    if (typeof values[UISettingsFormFields.PRIMARY_HOVER_COLOR] !== 'string') {
      this.theme.primaryHoverColor = values[UISettingsFormFields.PRIMARY_HOVER_COLOR]?.toHexString?.();
    }

    if (typeof values[UISettingsFormFields.DISABLED_COLOR] !== 'string') {
      this.theme.disabledPrimaryColor = values[UISettingsFormFields.DISABLED_COLOR]?.toHexString?.();
    }

    if (typeof values[UISettingsFormFields.COLOR_ON_PRIMARY] !== 'string') {
      this.theme.colorOnPrimary = values[UISettingsFormFields.COLOR_ON_PRIMARY]?.toHexString?.();
    }

    if (typeof values[UISettingsFormFields.SECONDARY_COLOR] !== 'string') {
      this.theme.secondaryColor = values[UISettingsFormFields.SECONDARY_COLOR]?.toHexString?.();
    }

    if (typeof values[UISettingsFormFields.COLOR_ON_SECONDARY] !== 'string') {
      this.theme.colorOnSecondary = values[UISettingsFormFields.COLOR_ON_SECONDARY]?.toHexString?.();
    }

    if (isEmpty(this.theme)) {
      this.theme = undefined as unknown as Partial<ITheme>;
    }
    this.channelId = values.channelId;
  }
}

export default UpdateUISettingsDTO;
