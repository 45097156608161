import { Layout as AntdLayout } from 'antd';
import { ReactNode } from 'react';

interface ILayoutComponent {
  children?: ReactNode;
  className?: string;
}

const LayoutContent = (props: ILayoutComponent) => <AntdLayout.Content {...props} />;

export default LayoutContent;
