import styled from 'styled-components';
import { Layout } from '@/ui';
import { COLORS } from '@/theme';

export const StyledLayout = styled(Layout)`
  &.ant-layout {
    min-height: 100vh;
    flex: 1;
    background: ${COLORS.WHITE};
  }
`;

export const StyledTopBlock = styled.div`
  padding: 2rem 1.5rem 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledImageHolder = styled.div`
  overflow: hidden;

  img {
    aspect-ratio: 18 / 6;
    width: 100%;
    object-fit: contain;
  }
`;

export const StyledLogoutButton = styled.div`
  padding: 0 1.5rem 1rem 1.5rem;
  margin-top: auto;

  button {
    width: calc(18rem - 3.1rem) !important;
  }
`;
