import { IUpdateVariableDTO } from '@/models/variable.model';
import { TUpdateVariableFormValues, VariableFormFields } from '../helpers/types';

class UpdateVariableDTO implements IUpdateVariableDTO {
  value: string;

  defaultValue: string;

  code: string;

  id: string;

  channelId: number;

  constructor(values: TUpdateVariableFormValues & { id: string; channelId: number }) {
    this.code = values[VariableFormFields.CODE];
    this.defaultValue = values[VariableFormFields.DEFAULT_VALUE];
    this.value = values[VariableFormFields.VALUE];
    this.id = values.id;
    this.channelId = values.channelId;
  }
}

export default UpdateVariableDTO;
