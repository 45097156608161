import { IApiError } from '@/models/apiError.model';
import { formatApiError } from '@/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ProductsAPI from 'src/api/ProductsAPI';
import { SLICE_NAME } from './models';

export const checkProductExistsById = createAsyncThunk<boolean, string, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/checkProductExistsById`,
  async (id: string) => {
    const response = await ProductsAPI.checkProductExistsById(id);
    return response.data;
  },
  { serializeError: formatApiError, condition: (arg) => arg.length > 0 },
);
