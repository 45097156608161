import { IUpdateRedirectDTO } from '@/models/redirect.model';
import { RedirectsFormFields, TUpdateRedirectFormValues } from '../helpers/types';
import { RedirectType } from '../typedefs';

class UpdateRedirectDTO implements IUpdateRedirectDTO {
  source: string;

  target: string;

  code: RedirectType;

  id: string;

  constructor(values: TUpdateRedirectFormValues & { id: string }) {
    this.source = values[RedirectsFormFields.SOURCE];
    this.target = values[RedirectsFormFields.TARGET];
    this.code = values[RedirectsFormFields.CODE];
    this.id = values.id;
  }
}

export default UpdateRedirectDTO;
