import { IRedirectsImportDTO } from '@/models/redirect.model';
import { TRedirectsImportFormValues } from '../helpers/types';

class RedirectsImportDTO implements IRedirectsImportDTO {
  file: File;

  channelId: number;

  constructor(values: TRedirectsImportFormValues & { channelId: number }) {
    this.file = values.file;
    this.channelId = values.channelId;
  }
}

export default RedirectsImportDTO;
