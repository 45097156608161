import { FormItem } from '@/components';
import { Alert } from 'antd';
import styled from 'styled-components';

export const SwitchContainer = styled.div`
  display: flex;
  gap: 8px;

  font-size: 14px;
  font-weight: 500;
`;

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
`;

export const StyledFormItem = styled(FormItem)`
  width: 70%;
`;
