import { createAsyncThunk } from '@reduxjs/toolkit';
import { MetatagsAPI } from '@/api';
import { formatApiError } from '@/utils';
import { IApiError } from '@/models/apiError.model';
import {
  ICreateMetatagResponse,
  IGetMetatagsResponse,
  IUpdateMetatagResponse,
  IUpdateMetatagsDTO,
  ICreateMetatagsDTO,
  IGetMetatagsDTO,
  IDeleteMetatagsDTO,
  IDeleteMetatagResponse,
} from '@/models/metatags.model';
import { SLICE_NAME } from './models';

export const getMetatags = createAsyncThunk<IGetMetatagsResponse, IGetMetatagsDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/getMetatags`,
  async (params) => {
    const response = await MetatagsAPI.getMetatags(params);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateMetatagsTemplate = createAsyncThunk<
  IUpdateMetatagResponse,
  IUpdateMetatagsDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/updateMetatagsTemplate`,
  async (data) => {
    const response = await MetatagsAPI.updateMetatag(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const deleteMetatagsTemplate = createAsyncThunk<
  IDeleteMetatagResponse,
  IDeleteMetatagsDTO,
  { serializedErrorType: IApiError; fulfilledMeta: { id: string } }
>(
  `${SLICE_NAME}/deleteMetatagsTemplate`,
  async (data, thunkAPI) => {
    const response = await MetatagsAPI.deleteMetatag(data);
    return thunkAPI.fulfillWithValue(response.data, { id: data.id });
  },
  { serializeError: formatApiError },
);

export const createMetatagsTemplate = createAsyncThunk<
  ICreateMetatagResponse,
  ICreateMetatagsDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/createMetatagsTemplate`,
  async (data) => {
    const response = await MetatagsAPI.createMetatag(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
