import { StarFilled } from '@ant-design/icons';
import { Rate } from 'antd';
import styled, { css } from 'styled-components';
import { COLORS, pickThemeFontSize } from '@/theme';

const getSizeStyles = (size?: 'large' | 'medium' | 'small') => {
  switch (size) {
    case 'large': {
      return css`
        font-size: ${pickThemeFontSize('24')};
        line-height: ${pickThemeFontSize('24')};
      `;
    }
    case 'small': {
      return css`
        font-size: ${pickThemeFontSize('16')};
        line-height: ${pickThemeFontSize('16')};
      `;
    }
    default:
      return css`
        font-size: ${pickThemeFontSize('20')};
        line-height: ${pickThemeFontSize('20')};
      `;
  }
};

export const StyledRate = styled(Rate)<{
  $size?: 'large' | 'medium' | 'small';
}>`
  margin-bottom: 0.125rem;

  ${({ $size }) => getSizeStyles($size)};

  & .ant-rate-star:not(:last-child) {
    margin-right: 0.25rem;
  }
`;

export const StyledStarIcon = styled(StarFilled)<{ $hovered: boolean }>`
  & > svg {
    stroke: ${({ $hovered, theme }) => ($hovered ? COLORS.GRAY_LIGHT : theme.token?.colorRating)};
    fill: ${({ $hovered, theme }) => ($hovered ? theme.token?.colorRating : COLORS.GRAY_LIGHT)};
  }
`;
