import { useEffect } from 'react';
import {
  getChannels,
  selectActiveChannels,
  selectChannels,
  selectChannelsError,
  selectChannelsIsLoading,
} from '../feature';
import { useAppDispatch, useAppState } from '@/store';

export const useGetChannels = () => {
  const dispatch = useAppDispatch();
  const channels = useAppState(selectChannels);
  const activeChannels = useAppState(selectActiveChannels);
  const isLoading = useAppState(selectChannelsIsLoading);
  const error = useAppState(selectChannelsError);

  useEffect(() => {
    if (!channels && !isLoading && !error) {
      dispatch(getChannels());
    }
  }, [channels, isLoading, error, dispatch]);

  return { channels, isLoading, error, activeChannels };
};
