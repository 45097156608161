import { useTranslation } from 'react-i18next';
import { useGetRequiredRule } from '@/hooks';
import { RobotsFormFields } from '../helpers/types';

const useRobotsFormRules = () => {
  const { t } = useTranslation(['validations']);

  const requiredRule = useGetRequiredRule({ t });

  return {
    [RobotsFormFields.CONTENT]: [requiredRule],
  };
};

export default useRobotsFormRules;
