// Globals
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// Store
import { useAppDispatch, useAppState } from '@/store';
import { updateRobotsTxt } from '../../feature';

// Modules
import { selectChannel } from '@/modules/Channels';
import { Header } from '@/modules/Layout';

// Components
import { CheckIcon, PenIcon, RobotsIcon } from '@/icons';
import { Button, Spinner, TextArea } from '@/ui';
import { StyledContainer, StyledLabel, StyledText } from './RobotsTxtStyles';
import { Form, FormItem, useForm } from '@/components';

// Helpers
import { useGetRobots, useRobotsFormRules } from '../../hooks';
import { RobotsFormFields } from '../../helpers';

const RobotsTxtContainer = () => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [form] = useForm();
  const channel = useAppState(selectChannel);
  const dispatch = useAppDispatch();
  const { isLoading, robots } = useGetRobots({ channel: channel?.slug });
  const rules = useRobotsFormRules();

  const handleClick = async () => {
    if (isEditing) {
      try {
        await form.validateFields();
        form.submit();
      } catch {}
    } else {
      setIsEditing((prev) => !prev);
    }
  };

  const handleSubmit = (values: { content: string }) => {
    if (!values.content || !channel?.slug) return;
    setIsEditing(false);
    dispatch(
      updateRobotsTxt({
        channelId: channel.id,
        content: values.content,
      }),
    );
  };

  return (
    <>
      <Header title={t('menu_robots_txt')} suptitle={t('menu_seo')}>
        <Button
          icon={isEditing ? <CheckIcon /> : <PenIcon />}
          type="primary"
          onClick={handleClick}
          loading={isLoading}
          htmlType={isEditing ? 'submit' : 'button'}
        >
          {t(isEditing ? 'save_changes' : 'btn_edit')}
        </Button>
      </Header>
      <Spinner size="large" spinning={isLoading}>
        <StyledContainer>
          <StyledLabel>
            <RobotsIcon />
            {t('menu_robots_txt')}
          </StyledLabel>
          {isEditing ? (
            <Form initialValues={{ [RobotsFormFields.CONTENT]: robots?.content }} form={form} onFinish={handleSubmit}>
              <FormItem name={RobotsFormFields.CONTENT} rules={rules[RobotsFormFields.CONTENT]}>
                <TextArea autoSize={{ minRows: 9 }} />
              </FormItem>
            </Form>
          ) : (
            <StyledText>{robots?.content}</StyledText>
          )}
        </StyledContainer>
      </Spinner>
    </>
  );
};

export default RobotsTxtContainer;
