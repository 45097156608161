import {
  IUpdateRobotsDTO,
  IUpdateRobotsResponse,
  IGetRobotsDTO,
  IGetRobotsResponse,
  staticRobotsEndpoints,
} from '@/models/robots.model';
import { ApiResponse, httpClient } from '@/utils';

class RobotsTxtAPI {
  static getRobots(params: IGetRobotsDTO): ApiResponse<IGetRobotsResponse> {
    const { GET_ROBOTS } = staticRobotsEndpoints;
    return httpClient.get<IGetRobotsResponse>(GET_ROBOTS, {
      params,
    });
  }

  static updateRobots(data: IUpdateRobotsDTO): ApiResponse<IUpdateRobotsResponse> {
    const { UPDATE_ROBOTS } = staticRobotsEndpoints;
    return httpClient.post<IUpdateRobotsResponse>(UPDATE_ROBOTS, data);
  }
}

export default RobotsTxtAPI;
