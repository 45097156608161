import { ThemeConfig } from 'antd';
import { COLORS } from '@/theme';

interface AdditionalTokens {
  token: {
    colorBgSecondary: string;
    colorRating: string;
  };
}

export const baseTheme: AdditionalTokens & ThemeConfig = {
  components: {
    DatePicker: {
      controlHeight: 28,
      controlHeightLG: 34,
      controlHeightSM: 26,
      controlHeightXS: 26,
    },
  },
  token: {
    borderRadius: 6,
    borderRadiusLG: 6,
    borderRadiusSM: 6,
    borderRadiusXS: 6,
    colorBgSecondary: COLORS.GRAY_DARKEST,
    colorBorderSecondary: COLORS.COLOR_BORDER_SECONDARY,
    colorError: COLORS.STATUS_ERROR,
    colorLink: COLORS.BLUE_MAIN,
    colorPrimary: COLORS.BLUE_MAIN,
    colorPrimaryHover: COLORS.BLUE_DARK,
    colorPrimaryText: COLORS.TEXT_PRIMARY,
    colorSuccess: COLORS.STATUS_SUCCESS,
    colorText: COLORS.TEXT_SECONDARY,
    colorTextPlaceholder: COLORS.PLACEHOLDER,
    colorTextQuaternary: COLORS.TEXT_LIGHT,
    colorTextSecondary: COLORS.COLOR_SECONDARY,
    colorTextTertiary: COLORS.TEXT_HINT,
    colorWarning: COLORS.STATUS_WARNING,
    colorRating: COLORS.RATING,
    controlHeight: 46,
    controlHeightLG: 52,
    controlHeightSM: 40,
    controlHeightXS: 36,
  },
};

export const nasaTheme = {
  token: {
    ...baseTheme.token,
    colorLink: COLORS.BLUE_MAIN,
    colorPrimary: COLORS.BLUE_NASA,
    colorTextBase: COLORS.RED_NASA,
  },
};

export const amazonTheme = {
  token: {
    ...baseTheme.token,
    colorLink: COLORS.BLUE_MAIN,
    colorPrimary: COLORS.ORANGE_AMAZON,
    colorTextBase: COLORS.WHITE,
  },
};
