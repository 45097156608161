import { useAppDispatch, useAppState } from '@/store';
import { useEffect } from 'react';
import { GetRedirectsDTO } from '../dtos/GetRedirects.dto';
import {
  getRedirects,
  selectRedirects,
  selectRedirectsError,
  selectRedirectsIsLoading,
  selectRedirectsPagination,
} from '../feature';

export const useGetRedirects = ({ channelId }: { channelId?: number }) => {
  const isLoading = useAppState(selectRedirectsIsLoading);
  const redirects = useAppState(selectRedirects);
  const pagination = useAppState(selectRedirectsPagination);
  const error = useAppState(selectRedirectsError);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoading || error || !channelId || redirects) return;
    const dto = new GetRedirectsDTO({ page: 1, channelId });
    dispatch(getRedirects(dto)).unwrap();
  }, [channelId, dispatch, error, isLoading, redirects]);

  return {
    isLoading,
    redirects,
    error,
    pagination,
  };
};
