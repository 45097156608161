import { ICreateRedirectDTO } from '@/models/redirect.model';
import { RedirectsFormFields, TCreateRedirectFormValues } from '../helpers/types';
import { RedirectType } from '../typedefs';

class CreateRedirectDTO implements ICreateRedirectDTO {
  source: string;

  target: string;

  code: RedirectType;

  channelId: number;

  constructor(values: TCreateRedirectFormValues & { channelId: number }) {
    this.code = values[RedirectsFormFields.CODE];
    this.source = values[RedirectsFormFields.SOURCE];
    this.target = values[RedirectsFormFields.TARGET];
    this.channelId = values.channelId;
  }
}

export default CreateRedirectDTO;
