import { FC, ReactNode } from 'react';
import { StyledBlock, StyledContent, StyledTitle } from './ReviewDetailsBlockStyles';

interface ReviewDetailsBlockProps {
  title: string;
  children: ReactNode;
}

export const ReviewDetailsBlock: FC<ReviewDetailsBlockProps> = ({ title, children }) => {
  return (
    <StyledBlock>
      <StyledTitle>{title}</StyledTitle>
      <StyledContent>{children}</StyledContent>
    </StyledBlock>
  );
};
