import { Modal, ModalSize } from '@/components';
import { PlusIcon, TrashIcon } from '@/icons';
import { IRedirect } from '@/models/redirect.model';
import { selectChannel } from '@/modules/Channels';
import { Header } from '@/modules/Layout';
import { useAppDispatch, useAppState } from '@/store';
import { Button, Table } from '@/ui';
import { showSuccessToast } from '@/components';

import { debounce } from 'lodash';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RedirectsForm from '../../components/RedirectsForm/RedirectsForm';
import ImportRedirectsForm from '../../components/ImportRedirectsForm/ImportRedirectsForm';
import CreateRedirectDTO from '../../dtos/CreateRedirect.dto';
import { GetRedirectsDTO } from '../../dtos/GetRedirects.dto';
import UpdateRedirectDTO from '../../dtos/UpdateRedirect.dto';
import { createRedirect, deleteRedirect, getRedirects, updateRedirect, importRedirects } from '../../feature';
import { redirectsColumns } from '../../helpers/redirectColumns';
import {
  RedirectFormType,
  RedirectsFormFields,
  TCreateRedirectFormValues,
  TUpdateRedirectFormValues,
  TRedirectsImportFormValues,
} from '../../helpers/types';
import { useGetRedirects } from '../../hooks/useGetRedirects';
import { StyledButtons, StyledContainer, StyledMessage, StyledPagination } from './RedirectsConatiner.styles';
import RedirectsImportDTO from '../../dtos/RedirectsImport.dto';

export const RedirectsContainer = () => {
  const { t } = useTranslation();
  const channel = useAppState(selectChannel);
  const dispatch = useAppDispatch();

  const [selectedRedirect, setSelectedRedirect] = useState<IRedirect | null>(null);
  const [formType, setFormType] = useState<RedirectFormType | null>(null);

  const { isLoading, redirects, pagination } = useGetRedirects({
    channelId: channel?.id,
  });

  const handleSearch = debounce(async (event: ChangeEvent<HTMLInputElement>) => {
    const dto = new GetRedirectsDTO({ page: '1', query: event.target.value, channelId: channel?.id ?? 0 });
    dispatch(getRedirects(dto));
  }, 500);

  const handleDelete = (record: IRedirect) => {
    setSelectedRedirect(record);
    setFormType(RedirectFormType.DELETE);
  };

  const handleOpenEdit = (record: IRedirect) => {
    setSelectedRedirect(record);
    setFormType(RedirectFormType.UPDATE);
  };

  const handlePageChange = (page: number) => {
    const dto = new GetRedirectsDTO({ page, channelId: channel?.id ?? 0 });
    dispatch(getRedirects(dto));
  };

  const handleOpenCreate = () => {
    setSelectedRedirect(null);
    setFormType(RedirectFormType.CREATE);
  };

  const handleCloseModal = () => {
    setFormType(null);
  };

  const handleOpenImport = () => {
    setSelectedRedirect(null);
    setFormType(RedirectFormType.IMPORT);
  };

  const handleConfirmDelete = useCallback(async () => {
    if (!selectedRedirect) return;
    await dispatch(deleteRedirect({ id: selectedRedirect.id })).unwrap();
    setSelectedRedirect(null);
    setFormType(null);
  }, [dispatch, selectedRedirect]);

  const handleSubmitEditRedirect = useCallback(
    async (values: TUpdateRedirectFormValues) => {
      if (!selectedRedirect?.id || !channel?.id) return;
      const updateDto = new UpdateRedirectDTO({ ...values, id: selectedRedirect?.id });
      try {
        await dispatch(updateRedirect(updateDto)).unwrap();
        setSelectedRedirect(null);
        setFormType(null);
      } catch {}
    },
    [channel?.id, dispatch, selectedRedirect?.id],
  );

  const handleSubmitRedirectsImport = useCallback(
    async (values: TRedirectsImportFormValues) => {
      setSelectedRedirect(null);
      if (!channel?.id) return;
      const importDto = new RedirectsImportDTO({ ...values, channelId: channel.id });
      try {
        const result = await dispatch(importRedirects(importDto)).unwrap();
        if (result.success) {
          showSuccessToast({ message: t('import_success_message') });
        }
        setFormType(null);
        const getDto = new GetRedirectsDTO({ page: 1, channelId: channel.id });
        await dispatch(getRedirects(getDto)).unwrap();
      } catch {}
    },
    [channel?.id, dispatch, t],
  );

  const handleSubmitCreateRedirect = useCallback(
    async (values: TCreateRedirectFormValues) => {
      setSelectedRedirect(null);
      if (!channel?.id) return;
      const createDto = new CreateRedirectDTO({ ...values, channelId: channel.id });
      const getDto = new GetRedirectsDTO({ channelId: channel.id, page: '1' });
      try {
        await dispatch(createRedirect(createDto)).unwrap();
        await dispatch(getRedirects(getDto)).unwrap();
        setFormType(null);
      } catch {}
    },
    [channel?.id, dispatch],
  );

  const getModalContent = useCallback(
    (type: RedirectFormType) => {
      switch (type) {
        case RedirectFormType.UPDATE:
          return (
            <RedirectsForm
              onSubmit={handleSubmitEditRedirect}
              isLoading={isLoading}
              formType={RedirectFormType.UPDATE}
              initialValues={{
                [RedirectsFormFields.SOURCE]: selectedRedirect?.source,
                [RedirectsFormFields.TARGET]: selectedRedirect?.target,
                [RedirectsFormFields.CODE]: selectedRedirect?.code,
              }}
            />
          );
        case RedirectFormType.DELETE:
          return (
            <div>
              <StyledMessage>{t(`delete_description`)}</StyledMessage>
              <StyledButtons>
                <Button
                  type="primary"
                  danger
                  size="small"
                  onClick={handleConfirmDelete}
                  icon={<TrashIcon />}
                  loading={isLoading}
                >
                  {t('delete_redirect')}
                </Button>
              </StyledButtons>
            </div>
          );
        case RedirectFormType.CREATE:
          return (
            <RedirectsForm
              formType={RedirectFormType.CREATE}
              isLoading={isLoading}
              onSubmit={handleSubmitCreateRedirect}
            />
          );
        case RedirectFormType.IMPORT:
          return <ImportRedirectsForm onSubmit={handleSubmitRedirectsImport} isLoading={isLoading} />;
        default:
          return null;
      }
    },
    [
      handleConfirmDelete,
      handleSubmitCreateRedirect,
      handleSubmitEditRedirect,
      handleSubmitRedirectsImport,
      isLoading,
      selectedRedirect?.code,
      selectedRedirect?.source,
      selectedRedirect?.target,
      t,
    ],
  );

  const title = useMemo(() => {
    switch (formType) {
      case RedirectFormType.UPDATE:
        return t('title_edit_redirect');
      case RedirectFormType.DELETE:
        return t('delete_redirect_confirm', { source: selectedRedirect?.source });
      case RedirectFormType.CREATE:
        return t('title_create_redirect');
      case RedirectFormType.IMPORT:
        return t('title_import_redirects');
      default:
        return null;
    }
  }, [formType, selectedRedirect?.source, t]);

  return (
    <>
      <Header title={t('menu_redirects')} suptitle={t('menu_seo')}>
        <StyledButtons>
          <Button type="default" onClick={handleOpenImport}>
            {t('btn_redirects_import')}
          </Button>
          <Button icon={<PlusIcon />} type="primary" onClick={handleOpenCreate}>
            {t('btn_add_redirect')}
          </Button>
        </StyledButtons>
      </Header>
      <StyledContainer>
        <Table
          columns={redirectsColumns(t, handleSearch, handleDelete, handleOpenEdit)}
          rowKey="id"
          dataSource={redirects ?? []}
          loading={isLoading}
          pagination={false}
          size="small"
          rowHoverable
          virtual
        />
        <StyledPagination
          disabled={isLoading}
          total={pagination?.total ?? 0}
          current={pagination?.page ?? 1}
          pageSize={pagination?.pageSize ?? 10}
          onChange={handlePageChange}
          hideOnSinglePage
        />
      </StyledContainer>
      <Modal
        title={title}
        size={formType === RedirectFormType.DELETE ? ModalSize.MEDIUM : ModalSize.LARGE}
        visible={!!formType}
        onCancel={handleCloseModal}
        destroyOnClose
      >
        {!!formType && getModalContent(formType)}
      </Modal>
    </>
  );
};
