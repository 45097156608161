import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'src/shared/utils/debounce';
import { VariableInfo, VariableRef, VariableType } from '../VariableDetector.typedefs';

export const useVariableDetector = () => {
  const [variables, setVariables] = useState<VariableInfo[]>([]);
  const inputsRefs = useRef<Record<string, VariableRef | null>>({});
  const inputsVariables = useRef<Record<string, VariableInfo[]>>({});
  const [numberOfInputs, setNumberOfInputs] = useState(0);

  const detectVariables = useCallback((inputName: string, value: string) => {
    const detectedVariables: VariableInfo[] = [];
    const variableRegex = /{{\s*([^{}]+)\s*}}/g;
    let match: RegExpExecArray | null;

    while ((match = variableRegex.exec(value)) !== null) {
      detectedVariables.push({
        refToInput: inputsRefs.current[inputName],
        inputName,
        value: `{{${match[1]}}}`,
        startIndex: match.index,
        endIndex: match.index + match[0].length,
      });
    }

    // Update the state with the detected variables, replacing any existing ones for the input
    setVariables((prev) => {
      // Remove existing variables for this input
      const updatedVariables = prev.filter((varInfo) => varInfo.inputName !== inputName);
      // Add the new detected variables
      return [...updatedVariables, ...detectedVariables];
    });

    // Store the detected variables in the ref for quick access
    inputsVariables.current[inputName] = detectedVariables;
  }, []);

  const registerInputRef = useCallback((inputName: string, ref: any, type: VariableType) => {
    if (ref === null) {
      return;
    }
    inputsRefs.current[inputName] = { ...ref, type };
    setNumberOfInputs(() => Object.keys(inputsRefs.current).length);
  }, []);

  const handleInputChange = debounce((inputName: string, value: string) => {
    detectVariables(inputName, value);
  }, 500);

  const selectVariableInInput = useCallback((variable: VariableInfo) => {
    const { refToInput, startIndex, endIndex } = variable;

    if (refToInput) {
      switch (refToInput.type) {
        case VariableType.INPUT:
          refToInput.focus();
          refToInput.setSelectionRange(startIndex, endIndex);
          break;
        case VariableType.TEXT_AREA:
          refToInput.focus();
          refToInput.resizableTextArea!.textArea.setSelectionRange(startIndex, endIndex);
          break;
        case VariableType.TEXT_EDITOR:
          refToInput?.editor?.setSelection(startIndex, endIndex - startIndex);
          break;
        case VariableType.SELECT:
          refToInput.focus();
          break;
      }
    }
  }, []);

  useEffect(() => {
    Object.keys(inputsRefs.current).forEach((inputName) => {
      let value: string = '';
      switch (inputsRefs.current[inputName]!.type) {
        case VariableType.INPUT:
          value = inputsRefs.current[inputName]?.input?.value ?? '';
          break;
        case VariableType.TEXT_AREA:
          value = inputsRefs.current[inputName]?.resizableTextArea?.textArea.value ?? '';
          break;
        case VariableType.TEXT_EDITOR:
          value = inputsRefs.current[inputName]?.editor?.getText() || '';
          break;
        case VariableType.SELECT:
          value = inputsRefs.current[inputName]?.nativeElement.innerText || '';
          break;
      }
      detectVariables(inputName, value);
    });
  }, [detectVariables, inputsRefs, numberOfInputs]);

  return {
    variables,
    inputsRefs,
    registerInputRef,
    selectVariableInInput,
    handleInputChange,
  };
};
