import { createAsyncThunk } from '@reduxjs/toolkit';
import { ChannelsAPI } from '@/api';
import { formatApiError } from '@/utils';
import { IApiError } from '@/models/apiError.model';
import {
  ICreateChannelDTO,
  ICreateChannelResponse,
  IGetChannelsResponse,
  IUpdateChannelDTO,
  IUpdateChannelResponse,
} from '@/models/channel.model';
import { SLICE_NAME } from './models';
import { logOut } from '@/modules/Auth';
import { ResponseStatusCodes } from '@/constants/common';

export const getChannels = createAsyncThunk<IGetChannelsResponse, void, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/getChannels`,
  async (_, thunkAPI) => {
    try {
      const response = await ChannelsAPI.getChannels();
      return response.data;
    } catch (error) {
      const serializedError = formatApiError(error);
      const isUnauthorizedError = serializedError.status === ResponseStatusCodes.NOT_AUTHORIZED;
      if (isUnauthorizedError) {
        thunkAPI.dispatch(logOut());
      }

      throw new Error(serializedError.message);
    }
  },
  { serializeError: formatApiError },
);

export const updateChannel = createAsyncThunk<
  IUpdateChannelResponse,
  IUpdateChannelDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/updateChannel`,
  async (data) => {
    const response = await ChannelsAPI.updateChannel(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const createChannel = createAsyncThunk<
  ICreateChannelResponse,
  ICreateChannelDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/createChannel`,
  async (data) => {
    const response = await ChannelsAPI.createChannel(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
