// Global
import { useTranslation } from 'react-i18next';

// Hooks
import { usePageValidations } from '../../../hooks/usePageValidations';

// Components
import { Input, TextArea } from '@/ui';
import { VariableType } from 'src/shared/ui/VariablesDetector/VariableDetector.typedefs';
import { ContentBlockContainer, WideFormItem } from '../../../UpdatePage.styles';

interface UpdateContentDefaultBlockProps {
  fieldName: string | number;
  registerInputRef: (fieldName: string, ref: any, type: VariableType) => void;
  handleInputChange: (fieldName: string, value: string) => void;
}

export const UpdateContentDefaultBlock = ({
  fieldName,
  handleInputChange,
  registerInputRef,
}: UpdateContentDefaultBlockProps) => {
  const { getRulesFor } = usePageValidations();
  const { t } = useTranslation(['common']);

  return (
    <ContentBlockContainer>
      <WideFormItem
        label={t('update_page_form_content_title')}
        name={[fieldName, 'title']}
        rules={getRulesFor(['content', 'blocks', 'title'])}
      >
        <Input
          ref={(ref) =>
            registerInputRef(`${t('update_page_form_content_title')} [${+fieldName + 1}]`, ref, VariableType.INPUT)
          }
          placeholder={t('update_page_form_content_title_placeholder')}
          onChange={(e) =>
            handleInputChange(`${t('update_page_form_content_title')} [${+fieldName + 1}]`, e.target.value)
          }
        />
      </WideFormItem>

      <WideFormItem
        label={t('update_page_form_content_description')}
        name={[fieldName, 'description']}
        rules={getRulesFor(['content', 'blocks', 'description'])}
      >
        <TextArea
          ref={(ref) =>
            registerInputRef(
              `${t('update_page_form_content_description')} [${+fieldName + 1}]`,
              ref,
              VariableType.TEXT_AREA,
            )
          }
          onChange={(e) =>
            handleInputChange(`${t('update_page_form_content_description')} [${+fieldName + 1}]`, e.target.value)
          }
          placeholder={t('update_page_form_content_description_placeholder')}
          rows={3}
        />
      </WideFormItem>
    </ContentBlockContainer>
  );
};
