import { COLORS, pickThemeFontStyles } from '@/theme';
import { styled } from 'styled-components';

export const StyledTitle = styled.span`
  ${pickThemeFontStyles('16', '26', 'SEMI_BOLD')};
  color: ${COLORS.TEXT_LIGHT};
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .anticon {
    font-size: 1.25rem;
  }
`;

export const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 32px;
  break-inside: avoid-column;

  a {
    text-decoration: underline;
  }

  .ant-form-item {
    margin-bottom: 0.75rem;
    &:last-of-type {
      margin-bottom: 0;
    }

    .ant-form-item-label {
      padding: 0;
    }
  }
`;

export const StyledContent = styled.div`
  display: flex;
  ${pickThemeFontStyles('14', '26')};

  p {
    margin: 0;
  }
`;

export const SubRateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  min-width: 268px;
`;

export const SubRateOuter = styled.div`
  display: flex;
  flex-direction: column;
`;
