import { InputRef } from 'antd';
import { TextAreaRef } from 'antd/es/input/TextArea';
import { BaseSelectRef } from 'rc-select';
import ReactQuill from 'react-quill-new';

export enum VariableType {
  INPUT = 'input',
  TEXT_AREA = 'textArea',
  TEXT_EDITOR = 'textEditor',
  SELECT = 'select',
}

export type VariableRef =
  | (InputRef & { type: VariableType.INPUT })
  | (TextAreaRef & { type: VariableType.TEXT_AREA })
  | (ReactQuill & { type: VariableType.TEXT_EDITOR })
  | (BaseSelectRef & { type: VariableType.SELECT });

export type VariableInfo = {
  refToInput: VariableRef | null;
  inputName: string;
  value: string;
  startIndex: number;
  endIndex: number;
};
