import { ICreateVariableDTO } from '@/models/variable.model';
import { TCreateVariableFormValues, VariableFormFields } from '../helpers/types';

class CreateVariableDTO implements ICreateVariableDTO {
  value: string;

  defaultValue: string;

  code: string;

  channelId: number;

  constructor(values: TCreateVariableFormValues & { channelId: number }) {
    this.code = values[VariableFormFields.CODE];
    this.defaultValue = values[VariableFormFields.DEFAULT_VALUE];
    this.value = values[VariableFormFields.VALUE];
    this.channelId = values.channelId;
  }
}

export default CreateVariableDTO;
