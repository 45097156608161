import { Button } from '@/ui';
import { DeleteOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { DraggableItemActionsContainer, DraggableItemContainer, DraggableItemIcon } from './DraggableItem.styles';

interface DraggableItemProps {
  fieldKey: string;
  index: number;
  removeItem: (index: number) => void;
  children: React.ReactNode;
}

export const DraggableItem: FC<DraggableItemProps> = ({ fieldKey, index, removeItem, children }) => {
  return (
    <Draggable draggableId={fieldKey} index={index}>
      {(providedInner) => (
        <div
          ref={providedInner.innerRef}
          {...providedInner.draggableProps}
          {...providedInner.dragHandleProps}
          style={{ ...providedInner.draggableProps.style, marginBottom: '8px' }}
        >
          <DraggableItemContainer>
            {children}
            <DraggableItemActionsContainer>
              <Button onClick={() => removeItem(index)} icon={<DeleteOutlined />}>
                Remove
              </Button>
              <DraggableItemIcon />
            </DraggableItemActionsContainer>
          </DraggableItemContainer>
        </div>
      )}
    </Draggable>
  );
};
