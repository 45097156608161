import { IApiError } from '@/models/apiError.model';
import { IReview, ReviewStatus } from '@/models/review.model';
import { DEFAULT_PAGE_SIZE } from './constants';

export const SLICE_NAME = 'reviews';

export interface ReviewsState {
  reviews: IReview[];
  isLoading: boolean;
  error: IApiError | null;
  total: number;
  page: number;
  pageSize: number;
  statusFilter: ReviewStatus;
  selectedReviewId: string | null;
}

export const initialState: ReviewsState = {
  reviews: [],
  isLoading: false,
  error: null,
  total: 0,
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  statusFilter: ReviewStatus.PENDING,
  selectedReviewId: null,
};
