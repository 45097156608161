import { createAsyncThunk } from '@reduxjs/toolkit';
import { SettingsAPI } from '@/api';
import { formatApiError } from '@/utils';
import { IApiError } from '@/models/apiError.model';
import {
  IGetSettingsDTO,
  IUpdateUISettingsDTO,
  IGetSettingsResponse,
  IUpdateGeneralSettingsDTO,
  IUpdateGeneralSettingsResponse,
  IGetUISettingsResponse,
  IGetUISettingsDTO,
  IBulkUpdateGeneralSettingsDTO,
  IBulkUpdateGeneralSettingsResponse,
  IUpdateUISettingsResponse,
} from '@/models/settings.model';
import { SLICE_NAME } from './models';

export const getSettings = createAsyncThunk<IGetSettingsResponse, IGetSettingsDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/getSettings`,
  async (params) => {
    const response = await SettingsAPI.getSettings(params);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const getUISettings = createAsyncThunk<
  IGetUISettingsResponse,
  IGetUISettingsDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/getUISettings`,
  async (params) => {
    const response = await SettingsAPI.getUISettings(params);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateGeneralSettings = createAsyncThunk<
  IUpdateGeneralSettingsResponse,
  IUpdateGeneralSettingsDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/updateGeneralSettings`,
  async (data) => {
    const response = await SettingsAPI.updateGeneralSettings(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const bulkUpdateSettings = createAsyncThunk<
  IBulkUpdateGeneralSettingsResponse,
  IBulkUpdateGeneralSettingsDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/bulkUpdateSettings`,
  async (data) => {
    const response = await SettingsAPI.bulkUpdateSettings(data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateUISettings = createAsyncThunk<
  IUpdateUISettingsResponse,
  IUpdateUISettingsDTO,
  { serializedErrorType: IApiError }
>(
  `${SLICE_NAME}/updateUISettings`,
  async (data) => {
    const response = await SettingsAPI.updateUISettings(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
