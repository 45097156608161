import { ReactNode } from 'react';
import { StyledSidebar } from './SidebarStyles';

type ISidebarProps = {
  children?: ReactNode;
};

const Sidebar = ({ children }: ISidebarProps) => {
  return (
    <StyledSidebar width={'18rem'} collapsed={false}>
      {children}
    </StyledSidebar>
  );
};

export default Sidebar;
