// Global
import { useTranslation } from 'react-i18next';

// Components
import { Input, Select, TextArea } from '@/ui';
import { WideFormItem } from '../../UpdatePage.styles';

// Hooks
import { VariableDetector } from 'src/shared/ui/VariablesDetector';
import { useVariableDetector } from 'src/shared/ui/VariablesDetector/hooks/useVariableDetector';
import { VariableType } from 'src/shared/ui/VariablesDetector/VariableDetector.typedefs';
import { usePageValidations } from '../../hooks/usePageValidations';

export const UpdatePageSeoFormPart = () => {
  const { t } = useTranslation(['common']);
  const { getRulesFor } = usePageValidations();
  const { variables, registerInputRef, handleInputChange, selectVariableInInput } = useVariableDetector();

  return (
    <>
      <VariableDetector variables={variables} onSelectVariable={selectVariableInInput} />
      <WideFormItem
        label={t('update_page_form_seo_title')}
        name={['seo', 'title']}
        rules={getRulesFor(['seo', 'title'])}
      >
        <Input
          ref={(ref) => registerInputRef(t('update_page_form_seo_title'), ref, VariableType.INPUT)}
          onChange={(e) => handleInputChange(t('update_page_form_seo_title'), e.target.value)}
          placeholder={t('update_page_form_seo_title_placeholder')}
        />
      </WideFormItem>

      <WideFormItem
        label={t('update_page_form_seo_description')}
        name={['seo', 'description']}
        rules={getRulesFor(['seo', 'description'])}
      >
        <TextArea
          ref={(ref) => registerInputRef(t('update_page_form_seo_description'), ref, VariableType.TEXT_AREA)}
          onChange={(e) => handleInputChange(t('update_page_form_seo_description'), e.target.value)}
          autoSize={{ minRows: 4 }}
          placeholder={t('update_page_form_seo_description_placeholder')}
        />
      </WideFormItem>
      <WideFormItem
        label={t('update_page_form_seo_keywords')}
        name={['seo', 'keywords']}
        rules={getRulesFor(['seo', 'keywords'])}
      >
        <Select
          ref={(ref) => registerInputRef(t('update_page_form_seo_keywords'), ref, VariableType.SELECT)}
          onChange={(value) => handleInputChange(t('update_page_form_seo_keywords'), (value as []).join(', '))}
          dropdownStyle={{ display: 'none' }}
          mode="tags"
          suffixIcon={null}
          placeholder={t('update_page_form_seo_keywords_placeholder')}
        />
      </WideFormItem>
    </>
  );
};
