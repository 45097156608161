import React, { useRef } from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import { TextEditorContainer } from './TextEditor.styles';

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'code'],
    ['clean'],
  ],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'code',
];

interface OnChangeHandler {
  (html: string, plainText: string): void;
}

type Props = {
  value?: string;
  placeholder?: string;
  onChange?: OnChangeHandler;
};

export const TextEditor = React.forwardRef<ReactQuill | null, Props>(({ value, onChange, placeholder }, ref) => {
  const prevValue = useRef<string>('');

  const handleChange = (_: string, delta: any, __: string, editor: any) => {
    const plainText = editor.getText();
    const html = editor.getHTML();
    if (html !== prevValue.current && onChange) {
      prevValue.current = html;
      onChange(html, plainText);
    }
  };

  return (
    <TextEditorContainer>
      <ReactQuill
        ref={ref}
        theme="snow"
        value={value || ''}
        modules={modules}
        formats={formats}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </TextEditorContainer>
  );
});

TextEditor.displayName = 'CustomTextEditor';
