import { InputProps, InputRef, Select } from 'antd';
import React, { forwardRef, useEffect, useState } from 'react';
import { StyledInput } from './InputStyles';

const { Option } = Select;

type IInputLinkProps = Omit<InputProps, 'onChange'> &
  React.RefAttributes<InputRef> & {
    value?: string;
    onChange?: (valueWithPrefix: string, value: string) => void;
  };

const InputLink = forwardRef<InputRef, IInputLinkProps>(({ value = '', onChange, ...props }, ref) => {
  const [prefix, setPrefix] = useState('https://');
  const [inputValue, setInputValue] = useState('');

  // Function to parse and set prefix from the incoming value
  const getInputLinkPrefix = (incomingValue: string) => {
    if (!incomingValue) return;

    const prefixMatch = incomingValue.match(/(https?:\/\/)/);
    if (prefixMatch) {
      setPrefix(prefixMatch[0]);
      setInputValue(incomingValue.replace(prefixMatch[0], ''));
    } else {
      setInputValue(incomingValue);
    }
  };

  // Handle changes to the input value
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;
    const prefixMatch = newValue.match(/(https?:\/\/)/);
    if (prefixMatch) {
      newValue = newValue.replace(prefixMatch[0], '');
      setInputValue(newValue);
    } else {
      setInputValue(newValue);
    }
    if (onChange) {
      onChange(`${prefix}${newValue}`, newValue);
    }
  };

  // Handle changes to the prefix
  const handlePrefixChange = (newPrefix: string) => {
    setPrefix(newPrefix);
    if (onChange) {
      onChange(`${newPrefix}${inputValue}`, inputValue);
    }
  };

  // Parse incoming value and set prefix
  useEffect(() => {
    getInputLinkPrefix(value);
  }, [value]);

  const selectBefore = (
    <Select value={prefix} onChange={handlePrefixChange}>
      <Option value="https://">https://</Option>
      <Option value="http://">http://</Option>
    </Select>
  );
  return (
    <StyledInput addonBefore={selectBefore} {...props} value={inputValue} onChange={handleInputChange} ref={ref} />
  );
});

InputLink.displayName = 'CustomInputLink';

export default InputLink;
