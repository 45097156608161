import { styled } from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';

export const StyledTitle = styled.div`
  ${pickThemeFontStyles('16', '26', 'SEMI_BOLD')};
  color: ${COLORS.TEXT_LIGHT};
  display: flex;
  align-items: center;
  gap: 0.5rem;

  .anticon {
    font-size: 1.25rem;
  }
`;
export const StyledBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem 2rem;
  break-inside: avoid-column;

  a {
    color: inherit;
    text-decoration: underline;
  }

  .ant-form-item {
    margin-bottom: 0.75rem;
    &:last-of-type {
      margin-bottom: 0;
    }

    .ant-form-item-label {
      padding: 0;
    }
  }
`;

export const StyledContent = styled.div`
  ${pickThemeFontStyles('14', '26')};
`;
