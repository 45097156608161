import {
  ICreateReviewDTO,
  IGetReviewsDTO,
  IGetReviewsResponse,
  IReview,
  IUpdateReviewDTO,
  ReviewsEndpoints,
  ReviewStatus,
} from '@/models/review.model';
import { ApiResponse, httpClient } from '@/utils';

class ReviewsAPI {
  static getReviews(data: IGetReviewsDTO): ApiResponse<IGetReviewsResponse> {
    return httpClient.get<IGetReviewsResponse>(ReviewsEndpoints.ROOT, {
      params: data,
    });
  }

  static approveReview(reviewId: string): ApiResponse<IReview> {
    return httpClient.patch<IReview>(`${ReviewsEndpoints.ROOT}/${reviewId}`, {
      status: ReviewStatus.APPROVED,
    });
  }

  static deleteReview(reviewId: string): ApiResponse<string> {
    return httpClient.delete<string>(`${ReviewsEndpoints.ROOT}/${reviewId}`);
  }

  static patchReview(reviewId: string, data: Omit<IUpdateReviewDTO, 'reviewId'>): ApiResponse<IReview> {
    return httpClient.patch<IReview>(`${ReviewsEndpoints.ROOT}/${reviewId}`, data);
  }

  static createReview(data: ICreateReviewDTO): ApiResponse<IReview> {
    return httpClient.post<IReview>(ReviewsEndpoints.ROOT, data);
  }
}

export default ReviewsAPI;
