import { ReviewsAPI } from '@/api';
import { IApiError } from '@/models/apiError.model';
import {
  ICreateReviewDTO,
  IGetReviewsDTO,
  IGetReviewsResponse,
  IReview,
  IUpdateReviewDTO,
} from '@/models/review.model';
import { formatApiError } from '@/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SLICE_NAME } from './models';

export const getReviews = createAsyncThunk<IGetReviewsResponse, IGetReviewsDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/getReviews`,
  async (params) => {
    const response = await ReviewsAPI.getReviews(params);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const approveReview = createAsyncThunk<IReview, string, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/approve`,
  async (reviewId) => {
    const response = await ReviewsAPI.approveReview(reviewId);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const deleteReview = createAsyncThunk<string, string, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/delete`,
  async (reviewId) => {
    const response = await ReviewsAPI.deleteReview(reviewId);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const updateReview = createAsyncThunk<IReview, IUpdateReviewDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/update`,
  async ({ reviewId, ...data }) => {
    const response = await ReviewsAPI.patchReview(reviewId, data);
    return response.data;
  },
  { serializeError: formatApiError },
);

export const createReview = createAsyncThunk<IReview, ICreateReviewDTO, { serializedErrorType: IApiError }>(
  `${SLICE_NAME}/create`,
  async (data) => {
    const response = await ReviewsAPI.createReview(data);
    return response.data;
  },
  { serializeError: formatApiError },
);
