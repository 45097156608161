import { useAppDispatch, useAppState } from '@/store';
import { useEffect } from 'react';
import { GetMetatagsDTO } from '../dtos';
import {
  getMetatags,
  selectMetatags,
  selectMetatagsError,
  selectMetatagsIsLoading,
  selectMetatagsPagination,
} from '../feature';

const useMetatags = ({ channelId }: { channelId?: number }) => {
  const isLoading = useAppState(selectMetatagsIsLoading);
  const metatags = useAppState(selectMetatags);
  const pagination = useAppState(selectMetatagsPagination);
  const error = useAppState(selectMetatagsError);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLoading || error || !channelId || metatags) return;
    const dto = new GetMetatagsDTO({ page: '1', channelId });
    dispatch(getMetatags(dto)).unwrap();
  }, [channelId, dispatch, error, isLoading, metatags]);

  return {
    isLoading,
    metatags,
    error,
    pagination,
  };
};

export default useMetatags;
