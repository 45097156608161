import { COLORS, pickThemeFontStyles } from '@/theme';
import { Pagination } from '@/ui';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 2rem;
  max-width: 50%;
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

export const StyledMessage = styled.p``;

export const StyledPagination = styled(Pagination)`
  margin-inline: auto;
`;

export const StyledSearchWrapper = styled.div`
  max-width: 40%;
  margin-bottom: 1rem;
`;

export const StyledLabel = styled.h2`
  display: flex;
  ${pickThemeFontStyles('16', '26', 'BOLD')};
  color: ${COLORS.TEXT_HINT};
  gap: 0.5rem;

  .anticon {
    font-size: 1.25rem;
  }
`;

export const StyledText = styled.p`
  margin: 0;
  white-space: pre-wrap;
`;
