// Components
import { useTranslation } from 'react-i18next';
import { Input, PasswordInput } from '@/ui';
import { Form, FormItem } from '@/components';
import { StyledButton, StyledTitle } from '../Styled';

// Helpers
import { AuthFieldsNames, TLogInFormValues } from '../../helpers/types';
import { useSignInFormRules } from '../../hooks';

interface ILogInForm {
  handleSubmit: (values: TLogInFormValues) => void;
  isLoading: boolean;
}

const LogInForm = ({ handleSubmit, isLoading }: ILogInForm) => {
  const { t } = useTranslation(['common']);
  const rules = useSignInFormRules();

  return (
    <>
      <StyledTitle>{t('log_in')}</StyledTitle>
      <Form onFinish={handleSubmit}>
        <FormItem name={AuthFieldsNames.EMAIL} label={t('email_label')} rules={rules[AuthFieldsNames.EMAIL]}>
          <Input placeholder={t('email_label')} type="email" />
        </FormItem>
        <FormItem name={AuthFieldsNames.PASSWORD} label={t('password_label')} rules={rules[AuthFieldsNames.PASSWORD]}>
          <PasswordInput placeholder={t('password_label')} />
        </FormItem>
        <StyledButton type="primary" htmlType="submit" loading={isLoading}>
          {t('log_in')}
        </StyledButton>
      </Form>
    </>
  );
};

export default LogInForm;
