import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { AuthState, SLICE_NAME } from './models';

const authSelector = (state: RootState): AuthState => state[SLICE_NAME];

export const selectIsAuthorized = createSelector(authSelector, (state: AuthState) => state.isAuthorized);

export const selectAuthIsLoading = createSelector(authSelector, (state: AuthState) => state.isLoading);

export const selectAuthError = createSelector(authSelector, (state: AuthState) => state.error);
