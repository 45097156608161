import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { ShippingPriceState, SLICE_NAME } from './models';

const shippingPricesSelector = (state: RootState): ShippingPriceState => state[SLICE_NAME];

export const selectShippingPrices = createSelector(
  shippingPricesSelector,
  (state: ShippingPriceState) => state.shippingPrices,
);

export const selectShippingPricesIsLoading = createSelector(
  shippingPricesSelector,
  (state: ShippingPriceState) => state.isLoading,
);

export const selectShippingPricesError = createSelector(
  shippingPricesSelector,
  (state: ShippingPriceState) => state.error,
);
