export enum ExampleRoutes {
  ABOUT_US = '/about-us',
  AFFILIATES = '/affiliates',
  AUTH = '/auth/[type]',
  CAREERS = '/careers',
  CART = '/cart',
  CATEGORY = '/category/[...category]',
  CHECKOUT = '/checkout',
  CONTACT_US = '/contact-us',
  FAQ = '/faq',
  GUARANTEED_PRIVACY_SECURITY = '/guaranteed-privacy-security',
  HOME = '/',
  INVESTOR_INFO = '/investor-info',
  NOWHERE = '/nowhere_test_route',
  PRICE_PROTECTION = '/price-protection',
  PRIVACY = '/privacy',
  PRIVACY_POLICY = '/privacy-policy',
  PRODUCT = '/product/[...path]',
  PROP_65 = '/prop-65',
  SEARCH = '/search',
  SHIPPING_AND_RETURNS = '/shipping-and-returns',
  SUBCATEGORY = '/category/[category]/[subcategory]',
  TERMS_AND_CONDITIONS = '/terms-and-conditions',
  TRACKING = '/tracking',
  TRACKING_ORDER = '/tracking/[id]',
}
