import { showApiErrors } from '@/utils';
import { createSlice } from '@reduxjs/toolkit';
import { checkProductExistsById } from './actionCreators';
import { ProductsState, SLICE_NAME, initialState } from './models';

export const productSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkProductExistsById.fulfilled, (state: ProductsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.productExists = payload;
      })
      .addCase(checkProductExistsById.pending, (state: ProductsState) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(checkProductExistsById.rejected, (state: ProductsState, action) => {
        const { error } = action;
        state.isLoading = false;
        state.error = error;
        showApiErrors(error);
      });
  },
});
