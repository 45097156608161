import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { MetatagsState, SLICE_NAME } from './models';

const metatagsSelector = (state: RootState): MetatagsState => state[SLICE_NAME];

export const selectMetatags = createSelector(metatagsSelector, (state: MetatagsState) => state.metatags);

export const selectMetatagsIsLoading = createSelector(metatagsSelector, (state: MetatagsState) => state.isLoading);

export const selectMetatagsError = createSelector(metatagsSelector, (state: MetatagsState) => state.error);

export const selectMetatagsPagination = createSelector(metatagsSelector, (state: MetatagsState) => state.pagination);
