import styled from 'styled-components';
import { COLORS, pickThemeFontStyles } from '@/theme';

export const StyledPaginationInfo = styled.div`
  background-color: ${COLORS.GRAY_MAIN};
  color: ${COLORS.WHITE};
  padding: 0.625rem 2rem;
  ${pickThemeFontStyles('16', '24')};
  width: 100%;
  max-width: 27rem;

  div {
    padding-inline: 2rem;
    margin: 0 auto;
  }
`;
