import { ConfigProvider } from 'antd';
import { ReactNode, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';

// Helpers
import { baseTheme } from '../../helpers/themes';
import { useAppState } from '@/store';
import { selectChannel } from '@/modules/Channels';
import { useGetSettings } from '@/modules/Settings';

interface IThemeContextProvider {
  children: ReactNode;
}

const ThemeConfigProvider = ({ children }: IThemeContextProvider) => {
  const currentChannel = useAppState(selectChannel);
  const { settings } = useGetSettings(currentChannel?.slug);

  const theme = useMemo(
    () => ({
      ...baseTheme,
      token: {
        ...baseTheme.token,
        colorPrimary: settings?.theme.primaryColor || baseTheme.token?.colorPrimary,
        colorPrimaryHover: settings?.theme.primaryHoverColor || baseTheme.token?.colorPrimaryHover,
        colorPrimaryText: settings?.theme.colorOnPrimary || baseTheme.token?.colorPrimaryText,
        controlItemBgActiveDisabled:
          settings?.theme.disabledPrimaryColor || baseTheme.token?.controlItemBgActiveDisabled,
        colorTextSecondary: settings?.theme.colorOnSecondary || baseTheme.token?.colorTextSecondary,
      },
    }),
    [
      settings?.theme.colorOnPrimary,
      settings?.theme.colorOnSecondary,
      settings?.theme.disabledPrimaryColor,
      settings?.theme.primaryColor,
      settings?.theme.primaryHoverColor,
    ],
  );

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider theme={theme}>{children}</ConfigProvider>
    </ThemeProvider>
  );
};

export default ThemeConfigProvider;
