import { useGetRequiredRule } from '@/hooks';
import { useTranslation } from 'react-i18next';
import { UISettingsFormFields } from '../helpers/types';

const useUISettingsFormRules = () => {
  const { t } = useTranslation(['validations']);
  const requiredRule = useGetRequiredRule({ t });

  return {
    [UISettingsFormFields.EMPTY_STATE_LOGO]: [requiredRule],
    [UISettingsFormFields.HEADER_LOGO]: [requiredRule],
    [UISettingsFormFields.FOOTER_LOGO]: [requiredRule],
    [UISettingsFormFields.MOBILE_HEADER_LOGO]: [requiredRule],
    [UISettingsFormFields.COMPANY_LOGO]: [requiredRule],
    [UISettingsFormFields.FAVICON48]: [requiredRule],
    [UISettingsFormFields.FAVICON96]: [requiredRule],
    [UISettingsFormFields.FAVICON144]: [requiredRule],
    [UISettingsFormFields.PRIMARY_COLOR]: [requiredRule],
    [UISettingsFormFields.PRIMARY_HOVER_COLOR]: [requiredRule],
    [UISettingsFormFields.DISABLED_COLOR]: [requiredRule],
    [UISettingsFormFields.COLOR_ON_PRIMARY]: [requiredRule],
    [UISettingsFormFields.SECONDARY_COLOR]: [requiredRule],
    [UISettingsFormFields.COLOR_ON_SECONDARY]: [requiredRule],
  };
};

export default useUISettingsFormRules;
