export enum PaginationFields {
  PAGE_SIZE = 'pageSize',
  PAGE = 'page',
  TOTAL = 'total',
}

export interface IPagination {
  [PaginationFields.PAGE_SIZE]: number;
  [PaginationFields.PAGE]: number;
}

export interface IPaginationDTO {
  [PaginationFields.PAGE_SIZE]?: string;
  [PaginationFields.PAGE]?: string;
}

export interface IPaginationResponse {
  [PaginationFields.PAGE_SIZE]: number;
  [PaginationFields.PAGE]: number;
  [PaginationFields.TOTAL]: number;
}
