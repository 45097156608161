import { ReviewStatus } from '@/models/review.model';
import { useAppDispatch, useAppState } from '@/store';
import { useEffect } from 'react';
import { getReviews, selectReviews, selectReviewsError, selectReviewsIsLoading } from '../feature';
import { DEFAULT_PAGE_SIZE } from '../feature/constants';
import {
  selectReviewsPage,
  selectReviewsPageSize,
  selectReviewsStatusFilter,
  selectReviewsTotal,
  selectSelectedReviewId,
} from '../feature/selectors';

export const useGetReviews = () => {
  const reviews = useAppState(selectReviews);
  const isLoading = useAppState(selectReviewsIsLoading);
  const error = useAppState(selectReviewsError);
  const total = useAppState(selectReviewsTotal);
  const pageSize = useAppState(selectReviewsPageSize);
  const page = useAppState(selectReviewsPage);
  const statusFilter = useAppState(selectReviewsStatusFilter);
  const selectedReviewId = useAppState(selectSelectedReviewId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getReviews({
        page,
        pageSize: DEFAULT_PAGE_SIZE,
        status: statusFilter === ReviewStatus.ALL ? undefined : statusFilter,
      }),
    );
  }, [page, pageSize, dispatch, statusFilter]);

  return {
    statusFilter,
    reviews,
    total,
    pageSize,
    page,
    isLoading,
    error,
    selectedReviewId,
  };
};
