import { useFormInstance } from '@/components';
import { OpenVariablesModuleButton } from '@/modules/Variables/components/OpenVariablesModuleButton';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButtonsContainer, DiscardButton } from '../../UpdatePage.styles';
import { Button } from '@/ui';

interface ModalActionsButtonsProps {
  onClose: () => void;
}

export const ModalActionsButtons: FC<ModalActionsButtonsProps> = ({ onClose }) => {
  const form = useFormInstance();
  const { t } = useTranslation(['common']);

  return (
    <ActionButtonsContainer>
      <OpenVariablesModuleButton />
      <div>
        <DiscardButton type="default" onClick={onClose}>
          {t('discard')}
        </DiscardButton>
        <Button type="primary" onClick={form.submit}>
          {t('apply_changes')}
        </Button>
      </div>
    </ActionButtonsContainer>
  );
};
