import { ReviewStatus } from '@/models/review.model';
import dayjs from 'dayjs';
import { CreateReviewFormFields } from '../typedefs';

export const mapInitialFormValues = (channelId: number) => ({
  [CreateReviewFormFields.CREATED_AT]: dayjs(Date.now()),
  [CreateReviewFormFields.REVIEW_TEXT]: '',
  [CreateReviewFormFields.EASE_OF_USE_RATING]: 4,
  [CreateReviewFormFields.DURABILITY_RATING]: 4,
  [CreateReviewFormFields.VALUE_FOR_MONEY_RATING]: 4,
  [CreateReviewFormFields.USER_NAME]: '',
  [CreateReviewFormFields.PRODUCT_ID]: '',
  [CreateReviewFormFields.STATUS]: ReviewStatus.APPROVED,
  [CreateReviewFormFields.CHANNEL_ID]: channelId,
});
