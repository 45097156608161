import { RootState } from '@/store';
import { createSelector } from '@reduxjs/toolkit';
import { ProductsState, SLICE_NAME } from './models';

const productsSelector = (state: RootState): ProductsState => state[SLICE_NAME];

export const selectProductExists = createSelector(productsSelector, (state: ProductsState) => state.productExists);

export const selectProductsExistsIsLoading = createSelector(
  productsSelector,
  (state: ProductsState) => state.isLoading,
);

export const selectProductExistsError = createSelector(productsSelector, (state: ProductsState) => state.error);
