import { createSlice, isPending, isRejected } from '@reduxjs/toolkit';
import { showApiErrors } from '@/utils';
import { getSettings, updateGeneralSettings, updateUISettings, bulkUpdateSettings } from './actionCreators';
import { SLICE_NAME, SettingsState, initialState } from './models';

export const settingsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.fulfilled, (state: SettingsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        state.settings = payload;
      })
      .addCase(updateGeneralSettings.fulfilled, (state: SettingsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        if (state?.settings) state.settings = { ...state.settings, ...payload };
      })
      .addCase(updateUISettings.fulfilled, (state: SettingsState, action) => {
        const { payload } = action;
        state.isLoading = false;
        if (state?.settings) state.settings = { ...state.settings, ...payload };
      })
      .addCase(bulkUpdateSettings.fulfilled, (state: SettingsState, action) => {
        const { payload } = action;
        const newSettings = payload.find(({ id }) => id === state?.settings?.id);
        state.isLoading = false;
        if (newSettings) state.settings = newSettings;
      })
      .addMatcher(
        isPending(getSettings, updateGeneralSettings, updateUISettings, bulkUpdateSettings),
        (state: SettingsState) => {
          state.isLoading = true;
          state.error = null;
        },
      )
      .addMatcher(
        isRejected(getSettings, updateGeneralSettings, updateUISettings, bulkUpdateSettings),
        (state: SettingsState, action) => {
          const { error } = action;
          state.isLoading = false;
          state.error = error;
          showApiErrors(error);
        },
      );
  },
});
