import styled from 'styled-components';
import { DEVICES } from '@/theme';

export const StyledSettingsWrapper = styled.div`
  display: grid;
  column-gap: 1rem;
  min-height: 20vh;

  @media screen and ${DEVICES.LAPTOP_S} {
    display: block;
    column-count: 2;
    column-gap: 2rem;
  }
`;

export const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;
