import { Select } from '@/ui';
import { styled } from 'styled-components';

export const StyledSearchWrapper = styled.div`
  display: flex;
  gap: 20%;
  align-items: center;
  flex-wrap: wrap;

  input {
    flex-grow: 1;
    width: auto !important;
  }
`;

export const StyledSelectFilter = styled(Select)`
  width: 120px;
`;

export const StyledFilterWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const StyledButtons = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`;
