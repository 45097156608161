// Global
import Form, { FormListProps } from 'antd/es/form';
import { FC } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

// Hooks
import { useFormInstance } from '@/components';

// Components
import { PlusIcon } from '@/icons';
import { Button } from '@/ui';
import { DraggableItem } from 'src/shared/ui/DraggableItem';
import { StrictModeDroppable } from 'src/shared/ui/DraggableItem/StrictModeDroppable';
import { VariableDetector } from 'src/shared/ui/VariablesDetector';
import { useVariableDetector } from 'src/shared/ui/VariablesDetector/hooks/useVariableDetector';
import { reorderList } from '../../../utils/reorderList';
import { UpdateGalleryFieldsBlock } from '../../formBlocks/UpdateGalleryFieldsBlock';

interface UpdateGalleryBlocksFormListProps {
  fieldName: FormListProps['name'];
}

export const UpdateGalleryBlocksFormList: FC<UpdateGalleryBlocksFormListProps> = ({ fieldName }) => {
  const form = useFormInstance();
  const { variables, registerInputRef, handleInputChange, selectVariableInInput } = useVariableDetector();

  const onDragEnd = (result: any) => {
    const galleryItems = form.getFieldValue(fieldName);
    if (!result.destination) {
      return;
    }
    const { source, destination } = result;
    const reorderedItems = reorderList(galleryItems, source.index, destination.index);
    form.setFieldValue(fieldName, reorderedItems);
  };

  return (
    <>
      <VariableDetector variables={variables} onSelectVariable={selectVariableInInput} />
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="droppable">
          {(provided) => (
            <Form.List name={fieldName}>
              {(fields, { add, remove }) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {fields.map((field, index) => (
                    <DraggableItem key={field.key} fieldKey={field.key.toString()} removeItem={remove} index={index}>
                      <UpdateGalleryFieldsBlock
                        fieldName={field.name}
                        handleInputChange={handleInputChange}
                        registerInputRef={registerInputRef}
                      />
                    </DraggableItem>
                  ))}
                  {provided.placeholder}
                  {fields.length < 5 && (
                    <Button
                      onClick={() => add({ order: fields.length + 1, id: uuidv4() })}
                      style={{ width: '100%', marginBottom: '8px' }}
                      icon={<PlusIcon />}
                    >
                      Add gallery block
                    </Button>
                  )}
                </div>
              )}
            </Form.List>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </>
  );
};
