import dayjs from 'dayjs';

export const capitalizeString = (row: string | undefined = '') => {
  return row?.charAt(0).toUpperCase() + row?.slice(1);
};

export const renderDate = (date: string, format = "DD MMM 'YY") => {
  return date ? dayjs(date).format(format) : '';
};

export const renderPrice = (price?: number | string | null) => {
  if (price == null) return;
  const value = typeof price === 'number' ? price : parseFloat(price);
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);
};
